import React from 'react';
import { helpers } from "@cargo/common";

class ColorFilter extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="colorfilter-color" />
                <div className="colorfilter-base" />
            </>
        )
    }

    componentDidMount(){

        // hack for safari animation
        if( helpers.isSafari() ){
            document.querySelector('#editor-overlay')?.classList.add('safari');            
            document.querySelector('#cargo-dotsite')?.classList.add('safari');
            document.querySelector('.colorfilter-base')?.classList.add('safari');
            document.querySelector('.colorfilter-color')?.classList.add('safari');
        }
    }

    componentWillUnmount(){
        document.querySelector('#editor-overlay')?.classList.remove('safari');            
        document.querySelector('#cargo-dotsite')?.classList.remove('safari');      
    }
}

export default ColorFilter;