import { h, Fragment, Component, createRef } from "preact";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../actions';
import _ from "lodash";

import { Alert, AlertContext } from "@cargo/ui-kit/alert/alert";

const FrontendAlertProxyComponent = ({alertModal, adminMode, ...props}) => { 

    return ( 
            <Alert>
                <AlertContext.Consumer>
                    {(Alert)=>{
                        if( alertModal && !adminMode ){
                            Alert.openModal({
                                ...alertModal,
                                manuallyBindHotkeys: true,
                                onConfirm: (options) => {
                                    if( props.onConfirm ){
                                        props.onConfirm();
                                    }
                                    props.updateFrontendState({ alertModal: false });
                                },
                                onClose: (options) => {
                                    if( props.onClose ){
                                        props.onclose();
                                    }
                                    props.updateFrontendState({ alertModal: false });
                                },
                                onCancel: (options) => {
                                    if( props.onCancel ){
                                        props.onCancel();
                                    }
                                    props.updateFrontendState({ alertModal: false });
                                }
                            });
                        }
                    }}
                </AlertContext.Consumer>
        </Alert> 
    )

}

function mapReduxStateToProps(state, ownProps) {
    return {
        alertModal: state.frontendState.alertModal,
        adminMode: state.frontendState.adminMode
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateFrontendState: actions.updateFrontendState
    }, dispatch);
}


const FrontendAlertProxy = connect(
    mapReduxStateToProps,
    mapDispatchToProps
)(
    FrontendAlertProxyComponent
)

export default FrontendAlertProxy
