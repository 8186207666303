import { Component } from "preact";
import { useEffect, useState, useRef, useLayoutEffect } from "preact/hooks";

import { ADMIN_FRAME, FRONTEND_DATA } from "../../globals";
import { editorOverlayAPI } from "./editor-overlay-controller"
import { withScroll } from "../page/scroll-element";


import * as helpers from "@cargo/common/helpers";


import _ from 'lodash';

class InsertContentButton extends Component {

	constructor(props){
		super(props);

		this.state= {
			rect: {top: null, left: null},
			initialRect: {top: null, left: null},
			initialScrollY: 0,
			isClickable: true,
			visible: false,
			parentFontInfo: {
				fontSize: null, 
				lineHeight: null
			}
		}

		this.maxButtonHeight = 20;
		this.minButtonHeight = 10;
		this.clonedRange = null;
		this.ticking = false;

		this.isDebug = false;

		if( FRONTEND_DATA.contentWindow?.CargoEditor && Object.keys(FRONTEND_DATA.contentWindow?.CargoEditor || {}).length > 0 ){
			this.bindEditorEvents();
		} else {
			window.addEventListener('CargoEditor-load', this.bindEditorEvents);
		}

	}

	componentWillUnmcount(){

		if( CargoEditor){
			CargoEditor.events.off('cursor-activity', this.onSelectionChange );
			CargoEditor.events.off('editor-keydown', this.onEditorKeydown );
		}

		window.removeEventListener('insert-context-menu-closed', this.restoreTextCursorPosition );
		window.removeEventListener('mouseup', this.onMouseUp );
		window.removeEventListener('mousedown', this.onMouseDown );
		document.removeEventListener('selectionchange', this.onSelectionChange);
		window.removeEventListener('blur', this.onEditorBlur );

		this.props?.scrollContext.scrollingElement.removeEventListener('scroll', this.onScroll)
	}

	bindEditorEvents = () => {

		window.removeEventListener('CargoEditor-load', this.bindEditorEvents);

		window.addEventListener('insert-context-menu-closed', this.restoreTextCursorPosition );
		window.addEventListener('mouseup', this.onMouseUp );
		window.addEventListener('mousedown', this.onMouseDown );
		document.addEventListener('selectionchange', this.onSelectionChange);
		window.addEventListener('blur', this.onEditorBlur );
		CargoEditor.events.on('cursor-activity', this.onSelectionChange);
		// bind mouseup / mousedown to window
		CargoEditor.events.on('editor-keydown', this.onEditorKeydown );

		this.props?.scrollContext.scrollingElement.addEventListener('scroll', this.onScroll)

	}

	componentDidUpdate(prevProps, prevState){

		if( this.props.scrollContext.scrollingElement !== prevProps.scrollContext.scrollingElement ) {

			if( this.props.scrollContext.scrollingElement ){

				this.props.scrollContext.scrollingElement.addEventListener('scroll', this.onScroll)

			} else if(prevProps.scrollContext.scrollingElement){

				prevProps.scrollContext.scrollingElement.removeEventListener('scroll', this.onScroll);	
			}

			this.onScroll();

		}

	}

	onScroll = e => {

		if( !this.state.visible || this.ticking ){ return }

		let currentPosition = window.pageYOffset;
		let newOffset = window.pageYOffset - this.state.initialScrollY;
		let roundedRect = Math.round( this.state.initialRect.top - newOffset );

		this.setState({rect: {top: roundedRect, left: this.state.initialRect.left} })
	}

	onEditorBlur = () => {
		this.setState({ visible: false })
	}

	onEditorKeydown = () => { 
		this.setState({ visible: false })
	}

	onMouseUp = (e) => {
		// Allow the button to be clicked.
		this.setState({ clickable: true })

		if( !e.target.closest('[editing]') ){
			return
		}
		// Check if it should be visible.
		this.checkAndSetVisiblity();
		// onSelectionChange()
	}

	onMouseDown = () => {
		this.setState({
			visible: false,
			clickable: true,
		})
	}

	onSelectionChange = _.debounce((e) => {

		if( this.ticking ){ 
			return
		}

		this.ticking = true;
		// Hide button
		this.setState({visible: false})

		let range = CargoEditor.getActiveRange();

		if( !range ){ return null }

		let parentContainer = document.querySelector('[editing="true"] bodycopy');

		if( !parentContainer ){ return null }

		let parentFontSize 
		let parentStyles = window.getComputedStyle(parentContainer);
		parentFontSize = parentStyles.getPropertyValue('font-size');
		parentFontSize = Number( parentFontSize.match(/[\d\.]+/) )

		let parentLineHeight = parentStyles.getPropertyValue('line-height');
		parentLineHeight = Number( parentLineHeight.match(/[\d\.]+/) );

		this.setState({
			parentFontInfo: {
				fontSize: parentFontSize, 
				lineHeight: parentLineHeight
			}
		}, () => {
    		// Set position
    		this.setButtonPosition();
		});

	
	}, 50)

	checkAndSetVisiblity = _.debounce(( range, activeEditor ) => {

		range           = CargoEditor.getActiveRange();
		activeEditor    = CargoEditor?.getActiveEditor();
		let isEmptyPage = activeEditor?.checkIfEmpty();
		let isEmptyLine = this.isEligibleEmptyLine(range);

		let isVisible   = isEmptyPage || isEmptyLine ? true : false; 

		if( this.isDebug ){
			console.log("checkAndSetVisible", isVisible )
		}

		this.setVisible( isVisible );

	}, 400)

	setVisible = ( bool ) => {

		if( this.isDebug ){
			console.log("set visible", bool)
		}

		this.setState({
			initialScrollY: window.pageYOffset,
			visible: bool
		}, ()=>{
			this.ticking = false;
		})

	}

	isEligibleEmptyLine = ( range ) => {

		if ( !range ){
		    return false
		}

		let nodeBeforeCaret = range.endContainer.childNodes[range.endOffset-1];
		let nodeAfterCaret = range.endContainer.childNodes[range.endOffset];
		let twoNodesBeforeCaret = range.endContainer.childNodes[range.endOffset-2];

		while(nodeBeforeCaret && CargoEditor.helpers.isWhitespaceNode(nodeBeforeCaret) ){
			nodeBeforeCaret = nodeBeforeCaret.previousSibling;
		}
		while(nodeAfterCaret && CargoEditor.helpers.isWhitespaceNode(nodeAfterCaret) ){
			nodeAfterCaret = nodeAfterCaret.nextSibling;
		}
		while(twoNodesBeforeCaret && CargoEditor.helpers.isWhitespaceNode(twoNodesBeforeCaret) ){
			twoNodesBeforeCaret = twoNodesBeforeCaret.previousSibling;
		}	

		if(
			range.collapsed &&
			range.endContainer &&		
			range.endContainer.nodeType == Node.ELEMENT_NODE &&
			(range.endContainer.nodeName ==='BODYCOPY' || range.endContainer.nodeName ==='COLUMN-UNIT' ) &&
			
			(
			
				( // if the range is at the end of the container and it's empty
					range.endOffset === 0 &&
					range.endContainer.childNodes.length == 0
				) ||

				( //Make sure Previous Element is clear
					this.checkNodeContent( nodeBeforeCaret ) 
					&&
					this.checkNodeContent( twoNodesBeforeCaret )
				) ||
				( //Make sure Next Element is clear
				  this.checkNodeContent( nodeAfterCaret ) &&
	              // but if there is no previous element, it means we're at the start of the container and a new line
	              !nodeBeforeCaret
	            )
			)            
			
		) {

			return true
		} else {
			return false
		}
	}

	restoreTextCursorPosition = (e) => {
		if( e.detail.cachedRange ){
			window.requestAnimationFrame(()=>{
				CargoEditor.helpers.setActiveRange( e.detail.cachedRange );
				CargoEditor?.getActiveEditor().getElement().focus();
			})
		}
	}

	checkNodeContent = ( testNode ) => {
		if(
			// check the previous element			
			testNode &&
			// if it was a break, it's the start of a new line
			testNode.nodeName ==='BR' ||
			(
				testNode?.nodeType === Node.ELEMENT_NODE &&
				window.getComputedStyle(testNode)?.getPropertyValue('display') === 'block'
			)
		){
			return true
		} else {
			return false
		}
	}

	getTextCursorRect = (range, editorRectBeforeNodeAdded, fontInfo) => {

		if( !range ){
			return false
		}

		if (this.clonedRange === range) return null;

		this.clonedRange = range.cloneRange();

		const isSafari = helpers.isSafari();

		let chromeMargin = ( fontInfo.fontSize * 0.15 ) * -1;
		let safariMargin = ( fontInfo.lineHeight * 0.15 ) * -1;

		// let lineRatio = 1 - (fontInfo.fontSize / fontInfo.lineHeight)
		// let lineHeight = fontInfo.lineHeight;
		// let adjustedLineHeight = lineRatio * lineHeight
		
		const testEl = document.createElement('input');
			// testEl.style.position = "absolute";
			testEl.style.display = "inline-block";
			testEl.style.fontSize = fontInfo.fontSize+'px';
			testEl.style.border = "0";
			testEl.style.background = "blue";
			testEl.style.outline = 'none';
			testEl.style.width = "2px";
			

			if( isSafari ){

				testEl.style.height = fontInfo.lineHeight+'px';
				testEl.style.lineHeight = fontInfo.lineHeight+'px';
				// testEl.style.margin = "0" ;
				testEl.style.margin = `${safariMargin}px 0 0 0`;
				testEl.style.padding = "0";
				testEl.style.verticalAlign = 'text-bottom';

			} else {

				testEl.style.height = fontInfo.fontSize+'px';
				testEl.style.verticalAlign = 'middle';

				testEl.style.margin = `${chromeMargin}px 0 0 0`;

				testEl.style.padding = "0";

			}
			

			testEl.setAttribute('data-ignore-changes', '');
			testEl.setAttribute('data-prevent-copy', '');

			testEl.setAttribute('disabled', '');
			testEl.setAttribute('readonly', '');

			testEl.setAttribute('test-delete-visible', '')
			

		testEl.setSaveable(false);
		
		range.insertNode(testEl);

		const editorRectAfterNodeAdded = CargoEditor?.getActiveEditor()?.getElement?.()?.getBoundingClientRect();

		// window.requestAnimationFrame(()=>{
		const rect = testEl.getBoundingClientRect();
		// the insertNode method can split a text node in half, 
		// so if there's a text node after the span, set it to 
		// saveable so we don't accidentally truncate some text
		if( testEl.nextSibling && testEl.nextSibling.nodeType === Node.TEXT_NODE){
			testEl.nextSibling.setSaveable(true)
		}

		// Remove test span ASAP after getting position data
		testEl.remove();

		// Account for change in document height if node added to DOM creates a break.
		const heightChange = editorRectAfterNodeAdded.height - editorRectBeforeNodeAdded.height;

		if (this.clonedRange){ 
			CargoEditor.helpers.setActiveRange(this.clonedRange) 
		};

		let returnedMeasurement = {
			bottom: rect.bottom - heightChange,
			height: rect.height,
			center: ( rect.bottom - heightChange ) - ( rect.top - heightChange ),
			left: rect.left,
			right: rect.right,
			top: rect.top - heightChange,
			width: rect.width,
			x: rect.x - heightChange,
			y: rect.y,
		}

		// return requested data
		return returnedMeasurement
		
	}

	setButtonPosition = () => {

		if( this.isDebug ){
			console.log("set button position")
		}
		// Get the current range...
		const range = CargoEditor.getActiveRange();

		let isEmptyPage = false;
		let isEmptyLine = false;
		let activeEditor = null;
		let editorEl = null;
		let caretPos = null;
		let top = null;
		let left = null;
		let editorRect = null;
		let offsetY = 0;
		let offsetX = 0;

		let buttonHeight = this.state.parentFontInfo?.fontSize;

		if( !this.state.parentFontInfo ){ 
			this.ticking = false
			return 
		}

		if( buttonHeight > 20 ){
			buttonHeight = 20
		}
		if( buttonHeight < 10 ){
			buttonHeight = 10
		}

		// Check if the editor exists
		const editorExists = !_.isEmpty(CargoEditor);

		if( editorExists ){
			// get active editor
			activeEditor = CargoEditor?.getActiveEditor();

			if( activeEditor ){
				// Get editor element
				editorEl = activeEditor.getElement();
			}

			// Check for empty line and empty page
			isEmptyPage = activeEditor?.checkIfEmpty();
			isEmptyLine = this.isEligibleEmptyLine(range);

		}

		// Get the positioning of the editor.
		editorRect = editorEl ? editorEl.getBoundingClientRect() : null;

		if ( isEmptyLine || isEmptyPage ) {

			let measure = this.getTextCursorRect( range, editorRect, this.state.parentFontInfo );
			let measureTop = 0;
			let center = 0;

			if( !measure ){ 
				this.ticking = false;
				return
			}

			let fontInfo = this.state.parentFontInfo

			let lineRatio = 1 - (fontInfo.fontSize / fontInfo.lineHeight)
			let lineHeight = fontInfo.lineHeight;
			let fontSize = fontInfo.fontSize;

			// console.log(fontSize, lineHeight, lineRatio, (lineHeight * (1-lineRatio) / 2 ))

			if( helpers.isSafari() ){
				// measureTop = measure.bottom - ( (lineHeight * .4) + (lineHeight * (lineRatio * .6) ) )
				measureTop = measure.bottom - ( fontInfo.lineHeight * .5 );
			} else {
				measureTop = measure.bottom - ( measure.height * .5 );
			}

			top = measureTop

			left = Math.ceil( measure.left );

		}

		// const activePID = parent.window.store.getState().frontendState.PIDBeingEdited;
		// let hardBottom = document.querySelector(`#${activePID} bodycopy`).getBoundingClientRect().bottom;

		offsetX = left ? 4 : 0;

		// top = top;
		left = left + offsetX;

		if( this.isDebug ){
			console.log("coordinate state set...")
		}
		
		this.setState({
			initialRect: {top: top, left: left},
			rect: {top: top, left: left}
		}, ()=> {
			// Determine if we should show.
			this.checkAndSetVisiblity();
		})
	}

	callContextMenu = (e) => {

		e.preventDefault();
		e.stopPropagation();

		this.setState({ visible: false })

		const lastKnownEditorRange = CargoEditor.getActiveRange().nativeRange;

		document.activeElement.blur()

		ADMIN_FRAME.adminWindow.ContextMenuOpener.openContextMenu(e, 'insert-content', { cachedRange : lastKnownEditorRange });	
	}

	render(){
		// Scale SVG size with font size
		let scaleSize = this.state.parentFontInfo.fontSize ? this.state.parentFontInfo.fontSize : 20;
		let scaledSVGSize = scaleSize > 20 ? 20 : scaleSize;
		scaledSVGSize = scaledSVGSize < 10 ? 10 : scaledSVGSize;
		const halfSVGSize = scaledSVGSize / 2;

		const styles = {
			frame: {
				transform: `translate3d(${this.state.rect.left}px, ${this.state.rect.top}px, 0px)`,
				zIndex: 999,
				display: 'flex',
				position: 'fixed',
				top: 0,
				left: 0,
				pointerEvents: 'none',
				height: 0,
				width: 0
			},
			button: {
				display: 'flex',
				visibility: this.state.visible && ( this.state.rect.top !== 0 && this.state.rect.left !== 0 ) ? 'visible' : 'hidden',
				position: 'relative',
				top: 'unset',
				left: 'unset',
				height: scaledSVGSize+'px',
				width: scaledSVGSize+'px',
				pointerEvents: this.state.isClickable ? 'all' : 'none',
				transform: `translate(0, -${halfSVGSize}px)`
			},
			svg: {
				pointerEvents: 'none',
				height: scaledSVGSize+'px',
				width: scaledSVGSize+'px',
				maxHeight: this.maxButtonHeight+'px',
				maxWidth: this.maxButtonHeight+'px',
				minHeight: this.minButtonHeight+'px',
				minWidth: this.minButtonHeight+'px',
			}
		}

		return ( 
			<div
				style={styles.frame}
			>
				<button 
					className="insert-content-button" 
					style={styles.button}
					onContextMenu={(e)=> { 
						e.preventDefault();
						this.callContextMenu(e)
					}}
					onMouseDown={(e)=> {
						this.callContextMenu(e) 
					}}
				>
					<svg 
						style={styles.svg} 
						id="insert-ineditor" 
						className="plus-icon" 
						width={ scaledSVGSize+'px' } 
						height={ scaledSVGSize+'px' } 
						viewBox="0 0 20 20" 
						fill="none"
					>
						<circle cx="10" cy="10" r="8" />
						<g>
							<line x1="10" y1="5" x2="10" y2="15"></line>
							<line x1="5" y1="10" x2="15" y2="10"></line>
						</g>
					</svg>
					{/* <div style={{height:'1px',width:'100px',background:'blue',position:'absolute'}}></div> */}
				</button>
			</div> 
		)
	}


}

export default withScroll(InsertContentButton)
