import React from 'react';
import { connect } from 'formik';
import omitBy from 'lodash/omitBy';
import _ from 'lodash';

class ChangeListener extends React.Component {

	render() {
		return null;
	}

	componentDidUpdate(old) {

		if ( !_.isEqual(old.formik.initialValues, this.props.formik.initialValues) ) {
		
			/* Component is updating because Formik's defaults have changed (this happens when
			 * enableReinitialize is set to true and two forms share the same data set).
			 * 
			 * We can ignore this change becuase the other form will already have reported the appropriate
			 * change.
			 */
		
			return;

		}

		const changes = omitBy(this.props.formik.values, function(v, k) {
			return old.formik.values[k] === v;
		});

		if(Object.keys(changes).length > 0) {
			this.props.onChange(changes, this.props.formik);
		}

	}


};


export default connect(ChangeListener);