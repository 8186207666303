import { Component, createRef } from "preact";


class TextAlignDetector extends Component {

	constructor(props){
		super(props);

		this.caveRef = createRef();
		this.shadowRef = createRef();
	}

	render(){

		return (
			<div
				aria-hidden="true"
				ref={this.caveRef}
				tabIndex="-1"
				data-nosnippet
				style={{
				    position: 'absolute',
				    top: '0',
				    left: '50px',
				    right: '50px',
				    margin: '0 auto 0 auto',
				    height: '10px',
				    lineHeight: '0',
				    fontSize: 0,
				    pointerEvents:'none',
				    zIndex: -99,
				}}
			>
				<div
					ref={this.shadowRef}
					tabIndex="-1"
					data-nosnippet
					style={{
					    display: 'inline-block',
					    margin: '0 -20px 0 -50px',
					    width: '40px',
					    height: '10px',
					}}
				/>
			</div>
		)
	}

	componentDidMount(prevProps, prevState){
		this.observer = new IntersectionObserver((entries)=>{
		  entries.forEach((entry) => {

		  	let alignment = 'right';
		  	switch(entry.intersectionRatio){
		  		case 0:
			  		alignment = 'left';
			  		break;

			  	case 1:
			  		alignment = 'center';
			  		break;
		  	}

			this.props.onTextAlignmentChange?.(alignment)

		  })
		  
		}, {
		  root: this.caveRef.current,
		  rootMargin: '0px',
		  threshold: [0, .6]			
		});
		this.observer.observe(this.shadowRef.current)
	}

	componentWillUnmount(){
		this.observer.disconnect();	
	}
}


export default TextAlignDetector
