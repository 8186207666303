import { Component } from 'preact';
import _ from 'lodash';

class Background extends Component {
	constructor(props){
		super(props);
	}

	render(props, state){

		return (
			<>
                {props.baseNode.tagName==='MEDIA-ITEM' 
                    ? <style>{`
                        img {
                            background-color: ${this.props['background-color']};
                        }
                    `}</style> 
                    : <style>{`
                        :host {
                            background-color: ${this.props['background-color']};
                        }
                    `}</style>
                }
				{props.children}
			</>
		)

	}
}


Background.defaultProps = {
    'background-color': 'rgba(0,0,0,0)'
};

export default Background