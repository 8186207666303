import React from 'react';
import './processing.scss';

export const ProcessingAnimation = ({ customStyle, className }) => {

   return (
        <div style={customStyle} className={`caution-processing${className ? ' '+className : ''}`}>
        </div>
    )
} 


