import { actionTypes } from "../actions";
import { combineReducers } from 'redux';
import { CRDTState } from "../globals";
import _ from "lodash";

export default function(state = {}, action) {

	if(!action.payload) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_SITE_CSS_FULFILLED: 
		case actionTypes.FETCH_SITE_PACKAGE_FULFILLED: 

			let data = action.payload.data;

			if(action.type === actionTypes.FETCH_SITE_PACKAGE_FULFILLED) {
				data = data.css;
			}

			if(data) {

				return {
					...state,
					...data,
					// assume css coming from the API is published
					crdt_state: CRDTState.Published
				};

			}

		break;
	}

	return state;
};