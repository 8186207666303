import { Component} from "preact";
import _ from 'lodash';
import { ADMIN_FRAME } from "../../globals";
import { editorOverlayAPI } from "./editor-overlay-controller"

import GalleryEditor from "./gallery-editor"

class SlideshowEditor extends Component {
	constructor(props){
		super(props);
		this.state = {
			isDragging: false,
			thumbnailsVisible: false,
			thumbnailsVisibleWhileDragging: false,
			hasUIWindow: false,
			pointerAttention: false,
		}

		this.debouncedCheckRange = _.debounce(this.checkRange, 120);
	}

	render(props, state){

		const {
			isDragging,
			thumbnailsVisible
		} = state;

		const {
			galleryInstance,
			dragContext,
		} = props;

		return <>
		<style>{`
			media-item figcaption.caption {
				${this.state.thumbnailsVisible? 'display: block': ''}
			}	
		`}</style>
		<GalleryEditor
			{...props}		
			mediaItemEditorInfo={{
				disableResize: true,
				disableButton: false,
			}}
			gallerySpecificAttributes={this.props.gallerySpecificAttributes||[]}			
			suppressMutation={this.props.suppressMutation}			
			galleryInstance={galleryInstance}
			disableDragOver={!thumbnailsVisible}
			disableDrop={!thumbnailsVisible}

			allowedElements={(el)=> el.part == 'previous-button' || el.part == 'next-button'}			
			onUIWindowRelease={this.onUIWindowRelease}
			onPointerIn={this.onPointerIn}
			onPointerOut={this.onPointerOut}
		>
		</GalleryEditor>
		</>
		
	}

	onCleanUp=(addedMediaItems, removedMediaItems, changedMediaItems, changedCharacterData, attributeMap, attributeMapString)=>{
		this.props.galleryInstance.updateNavigation();
		if( this.props.onCleanUp){
			this.props.onCleanUp(addedMediaItems, removedMediaItems, changedMediaItems, changedCharacterData, attributeMap, attributeMapString);
		}
	}


	onPointerIn = ()=>{
		// this.props.galleryInstance.pause();
		this.setState({
			pointerAttention: true,
		})
	}

	onPointerOut =()=>{
		// this.props.galleryInstance.resume();
		this.setState({
			pointerAttention: false,
		})
	}

	onDragOver = (editor, e, data) =>{

		if(
			this.props.galleryInstance.contains(data.dropTarget) &&
			!this.state.thumbnailsVisible
		){
			this.setState({
				thumbnailsVisibleWhileDragging: true,
			})
			this.props.galleryInstance.showThumbnails();
		}	
	}


	onDragDrop = (editor, e, data) =>{

		if(
			this.props.galleryInstance.contains(data.dropTarget) && this.state.thumbnailsVisible
		){


			setTimeout(()=>{
				if( this.state.thumbnailsVisibleWhileDragging ){
					this.setState({
						thumbnailsVisibleWhileDragging: false,
					})				
					this.debouncedCheckRange();		
				}
			}, 500)
	
		} else {

			if( this.state.thumbnailsVisibleWhileDragging ){
				this.setState({
					thumbnailsVisibleWhileDragging: false,
				})				
				this.props.galleryInstance?.hideThumbnails?.();				
			}

		}

	
	}

	updateThumbStatus = (e)=>{
		const thumbnailsVisible = this.props.galleryInstance.thumbnailsVisible;
		this.setState({
			thumbnailsVisible: thumbnailsVisible
		});

		if( thumbnailsVisible){
			// select first item inside slideshow to keep the thumbs available after closing ui window
			requestAnimationFrame(()=>{
				const range = CargoEditor?.rangy?.createRange();
				const sizingFrameToSelect = this.props.galleryInstance.querySelector('.sizing-frame');
				if( sizingFrameToSelect && range){
					range.setStartBefore(sizingFrameToSelect);
					range.setEndAfter(sizingFrameToSelect);
					CargoEditor.helpers.setActiveRange(range);
					CargoEditor.events.trigger('cursor-activity', CargoEditor.getActiveEditor() );
				}
			})			
		}
	}


	onUIWindowRelease = ()=>{
		if( this.props.galleryInstance.thumbnailsVisible){
			this.debouncedCheckRange();
		}
		this.setState({
			hasUIWindow: false,
		})		
	}

	onUIWindowSet = (hasUIWindow)=>{
		this.setState({
			hasUIWindow
		})
	}

	checkRange = () =>{

		const activeRange = CargoEditor.getActiveRange();

		CargoEditor.events.off('cursor-activity', this.debouncedCheckRange)

		// if we have a selection active in the window and it's part of the slideshow,
		// or if there is no active range,
		// hold off on switching thumbs off until the selection leaves the slideshow	
		if (
			this.state.hasUIWindow ||
			this.props.childArray.some(el=>el._editorInterface?.state?.UIWindow) ||
			(activeRange && this.props.galleryInstance.contains(activeRange.commonAncestorContainer)) ||
			!activeRange
		){
			CargoEditor.events.on('cursor-activity', this.debouncedCheckRange)
		} else {
			this.props.galleryInstance?.hideThumbnails?.();				
		}	
		
	}

	onClick = (e)=>{
		
		if(this.props.galleryInstance.thumbnailsVisible){
			return;
		}	
		
	}

	bindEditorEvents =()=>{
		window.removeEventListener('CargoEditor-load', this.bindEditorEvents);
		CargoEditor.events.on('cursor-activity', this.captionCursorActivity);		
	}

	captionCursorActivity = _.throttle(()=>{

		var range = CargoEditor.getActiveRange();

		if(range && this.props.galleryInstance.contains(range.commonAncestorContainer) ){

			const mediaItems = Array.from(this.props.galleryInstance.children).filter((node)=>node.tagName==='MEDIA-ITEM')
			mediaItems.forEach((item, index)=>{
				if(item.contains(range.commonAncestorContainer)){
					this.props.onItemResize(null, item, index);	
				}
			})			
			
		}		
	}, 120);

	componentDidUpdate(prevProps){
		if(prevProps.inTransition !== this.props.inTransition){

			const mediaItems = Array.from(this.props.galleryInstance.children).filter((node)=>node.tagName==='MEDIA-ITEM')

			if( this.props.inTransition){
				
				mediaItems.forEach((el)=>{
					editorOverlayAPI.deactivateHoverElement(el);
					editorOverlayAPI.lockItem(el);
				})
			} else if( this.state.pointerAttention){
				mediaItems.forEach((el)=>{
					editorOverlayAPI.deactivateHoverElement(el);
					editorOverlayAPI.unlockItem(el);
				})
				const activeItem = Array.from(this.props.galleryInstance.children).find((node)=> node.nodeName === "MEDIA-ITEM" && node.assignedSlot?.parentElement?.hasAttribute('data-active-slide'));
				if( activeItem){
					editorOverlayAPI.activateHoverElement(activeItem);
				}
			}

		}
	}

	componentDidMount(){

		if( CargoEditor && Object.keys(CargoEditor || {}).length > 0 ){
			this.bindEditorEvents();
		} else {
			window.addEventListener('CargoEditor-load', this.bindEditorEvents);
		}		

		ADMIN_FRAME.globalDragEventController.on('dragover', this.onDragOver)
		ADMIN_FRAME.globalDragEventController.on('drop', this.onDragDrop)		
		this.props.galleryInstance.addEventListener('click', this.onClick);
		this.props.galleryInstance.addEventListener('show-thumbnails', this.updateThumbStatus);
		this.props.galleryInstance.addEventListener('hide-thumbnails', this.updateThumbStatus);

		
		this.updateThumbStatus();

	}

	componentWillUnmount(){
		window.removeEventListener('CargoEditor-load', this.bindEditorEvents);
		this.captionCursorActivity.cancel();

		if( CargoEditor && Object.keys(CargoEditor || {}).length > 0 ){
			CargoEditor.events.off('cursor-activity', this.captionCursorActivity);			
		}

		this.props.galleryInstance.removeEventListener('click', this.onClick);
		this.props.galleryInstance.removeEventListener('show-thumbnails', this.updateThumbStatus);
		this.props.galleryInstance.removeEventListener('hide-thumbnails', this.updateThumbStatus);		
		ADMIN_FRAME.globalDragEventController.off('drop', this.onDragDrop)
		ADMIN_FRAME.globalDragEventController.off('dragover', this.onDragOver)		
	}


}

export default SlideshowEditor;