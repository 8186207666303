export const
	ADMIN_FRAME = {
		adminWindow: null,
	},
	FRONTEND_DATA = {

	},
	CRDTState = Object.freeze({
		New: 0,
		Published: 1,
		Draft: 2,
		Deleted: 3,
	});