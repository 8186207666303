import { Component } from 'preact';
import _ from 'lodash';
import { helpers } from "@cargo/common";
import FontFaceObserver from 'fontfaceobserver';

class FitText extends Component {
	constructor(props){
		super(props);
		this.state = {
            parentWidth: {},
			width: {},
            scale: 1,
            fontFamily: null,
		}
        this.observer = null;
        this.timeout = null;
	}

	render(props, state){
        const hasInlineLineHeight = props.baseNode?.getAttribute('style')?.includes('line-height') ? true : false;
        const baseNodeComputedStyle = window.getComputedStyle(props.baseNode);
        const letterSpacing = baseNodeComputedStyle.letterSpacing;
        const direction = baseNodeComputedStyle.direction;
        // Get inverted letter spacing to add to padding-right
        let padding = '0px';
        if (letterSpacing.includes('-')) {
            padding = letterSpacing.replace('-', '');
        }
		return (
			<>
            <span class="fit-text">
			    {props.children}
            </span>
			<style>
				{`
					:host { 
                        white-space: nowrap!important;
                        display: inline-block!important;
                        --font-scale: ${this.state.scale}!important;
                        ${hasInlineLineHeight ? '' : 'line-height: 1!important;'}
                        animation: none!important;
                        will-change: transform;
                    --rotate-transform: 0deg!important;
                    }
                    .fit-text {
                        padding${direction === 'ltr' ? '-right' : '-left'}: ${padding}!important;
                    }
				`}
			</style>
			</>
		)
	}

	locateFitText = () =>{
        if (!this.props.baseNode) {
            return;
        }
        if (!this.props.baseNode.parentElement) {
            return;
        }
        const parentWidth = this.props.baseNode.parentElement.getBoundingClientRect().width;
        const width = this.props.baseNode.getBoundingClientRect().width;
		this.setState({
            parentWidth,
            width,
		});
	}

    handleResize = () => {
        this.locateFitText();
    }

    handleFontLoad = () => {
        this.timeout = setTimeout(() => {
            this.locateFitText();
        }, 200);
    }

    refreshFontFamily = () => {
        let fontFamilyStr = window.getComputedStyle(this.props.baseNode).fontFamily;
        // Split the font family string into an array
        let fontFamily = fontFamilyStr.split(',');
        // Trim whitespace from the font family
        fontFamily = fontFamily.map((font) => {
            return font.trim();
        });
        // Remove any quotes from the font family
        fontFamily = fontFamily.map((font) => {
            return font.replace(/['"]+/g, '');
        });
        if (fontFamily[0] === this.state.fontFamily){
            return;
        }
        // Set the font family in the state
        this.setState({
            fontFamily: fontFamily[0]
        });
    }


	componentDidMount(){

        // If the font family is not set, then we need to set it
        if (!this.state.fontFamily){
            this.refreshFontFamily();
        }

        window.addEventListener('resize', this.handleResize);

        // Setup a mutation observer to watch for any change to the entire document
        this.observer = new MutationObserver((mutations) => {
            // If the base node is a child of the mutation, then we need to re-calculate the width
            if (_.find(mutations, (mutation) => {
                if (
                    mutation.target.contains(this.props.baseNode ||
                    mutation.target === this.props.baseNode)
                ){
                    return true;
                }
                if (
                    mutation.type === "attributes" && 
                    mutation.attributeName === "uses" &&
                    mutation.target?.getAttribute('uses')?.includes('fit-text') === true
                ) {
                    return true;
                }
                if (mutation.target.tagName === 'STYLE'){
                    // If the font family has changed, then we need to update it in the state
                    if (mutation.target.textContent.includes('font-family')){
                        this.refreshFontFamily();
                    }
                    return true;
                }
            })) {
                this.locateFitText();
            }
        });

        this.observer.observe(document, {
            attributes: true,
            childList: true,
            subtree: true
        });

        // Add a keyup listener to the window to detect when the user types
        window.addEventListener('keyup', this.handleKeyup);

        this.locateFitText();
    
	}

    handleKeyup = (e) => {
        // Check if we are in the editor
        if (!window.CargoEditor?.getActiveEditor?.()){
            return;
        }
        const currentRange = window.CargoEditor.getActiveRange();
        // If the currentRange start container is within the base node, or it is the base node, then we need to re-calculate the width
        if (currentRange.startContainer === this.props.baseNode || currentRange.startContainer.parentElement === this.props.baseNode){
            this.locateFitText();
        }
    }

	componentWillUnmount(){

        // Remove the resize listener
        window.removeEventListener('resize', this.handleResize);

        // Stop observing the document
        this.observer.disconnect();

        // Remove the keyup listener
        window.removeEventListener('keyup', this.handleKeyup);

        // Clear the timeout
        clearTimeout(this.timeout);
	}

    componentDidUpdate(prevProps, prevState){

        if (prevState.fontFamily !== this.state.fontFamily){
            const font = new FontFaceObserver(this.state.fontFamily);
            font.load().then(() => {
                this.handleFontLoad()
            }).catch(() => {
                this.handleFontLoad();
            });
        }

        if (prevState.scale !== this.state.scale){
            const diff = this.state.scale - prevState.scale;
            if (diff > 0.1 || diff < -0.1){
                this.locateFitText();
                return;
            }
        }

        if (prevState.parentWidth !== this.state.parentWidth || prevState.width !== this.state.width){
            const scale = this.state.parentWidth / (this.state.width / this.state.scale);
            this.setState({
                scale: scale
            })
            return;
        }

        if(prevProps.baseNode !== this.props.baseNode){
            this.locateFitText();
            return;
        }

    }
}

export default FitText;