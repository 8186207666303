import { actionTypes } from "../actions";
import { combineReducers } from 'redux';
import { CRDTState } from "../globals";
import _ from "lodash";
import * as helpers from "@cargo/common/helpers";

function byId(state = {}, action) {

	if(!action.payload) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_SCAFFOLDING_FULFILLED: 
		case actionTypes.FETCH_ADMIN_LIST_FULFILLED: 
		case actionTypes.FETCH_CONTENT_FULFILLED:
		case actionTypes.FETCH_SITE_PACKAGE_FULFILLED: 

			let data = action.payload.data;

			if(action.type === actionTypes.FETCH_SITE_PACKAGE_FULFILLED) {
				data = data.scaffolding;
			}

			if(data) {

				if(!_.isArray(data)) {
					data = [data];
				}

				return {
					...state,
					...(data).reduce((map, item) => {

						if(item.page_type !== "page") {
							return map;
						}
						
						// clone the item
						item = {...item};

						// delete forbidden fields from clone
						delete item.index
						delete item.sort
						delete item.set_id
						delete item.is_homepage

						if(item.purl) {
							item.purl = item.purl.toLowerCase();
						}
						
						// assume every page coming from the API is published
						item.crdt_state = CRDTState.Published;

						map[item.id] = item

						return map;

					}, {})
				};

			}

		break;
		case actionTypes.DELETE_CONTENT:

			if(state[action.payload.id]) {
				
				const result = {...state}
				
				delete result[action.payload.id];

				return result

			}
		break;
		case actionTypes.UPDATE_PAGE:
				
			// const existingItem = state[action.meta.id];

			return {
				...state,
				[action.meta.id]: _.merge({}, _.cloneDeep(state[action.meta.id]), action.payload)
			};

		break;
	}

	return state;
};

export const rawReducers = {
	byId
}

export default combineReducers(rawReducers);