import _ from 'lodash';

export function removeDiacritics (str) {
	// https://github.com/backbone-paginator/backbone.paginator
	// Go here ^ for a complete list of diacritics that could be stripped.
	if( !str ){
		return null;
	}
	// We only care about e b/c Sélavy
	var defaultDiacriticsRemovalMap = [
		{'base':'e', 'letters':/[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g},
	];

	for(var i=0; i<defaultDiacriticsRemovalMap.length; i++) {
		str = str.replace(defaultDiacriticsRemovalMap[i].letters, defaultDiacriticsRemovalMap[i].base);
	}

	return str;
}

export function deriveFontModelFromCSS ( collection, fontFamily ) {

	// Remove quotes
	fontFamily = fontFamily?.replace(/^"|"$/g, '');

	let family   = _.find(collection, {family: fontFamily});

	if( !family ){
		// Try looking at the match name, this works for specific
		// system font stacks e.g. in CSS we write "sans-serif system default" as "-apple-system"
		family = _.find(collection, {match: fontFamily?.trim()})
	}

	if( !family ){
		// Check normalized name
		let normalizedName = removeDiacritics( fontFamily );
		family   = _.find(collection, {family: normalizedName });
	}

	if( !family ){
		// Check normalized name
		family   = _.find(collection, {cssName: fontFamily });
	}

	if( !family ){
		// Check normalized name
		family   = _.find(collection, {family: fontFamily?.trim()+' Variable' });
	}

	if ( !family ) {
		for (const font of collection) {
			if (font.variants.some(variant => variant.cssName === fontFamily)) {
				family = font;
				break;
			}
		}
	}

	return family
}

// font-family: "Helvetica Neue"
// font-style: "normal"
// font-weight: "400" 
// --> should return full variant model
export function deriveVariantFromCSS ( collection, fontFamily, style, weight, fvs, familyInCSS ) {

	if( !fontFamily ){
		return null;
	}

	// Remove quotes
	fontFamily = fontFamily?.replace(/^"|"$/g, '');
 	// Find the family in the collection
	let family   = _.find(collection, {family: fontFamily});
	let variant  = null;

	if( !family ){
		// Try looking at the match name, this works for specific
		// system font stacks e.g. in CSS we write "sans-serif system default" as "-apple-system"
		family = _.find(collection, {match: fontFamily?.trim()})
	}

	if( !family ){
		// Check normalized name
		let normalizedName = removeDiacritics( fontFamily );
		family   = _.find(collection, {family: normalizedName });
	}

	if( !family ){
		// Check normalized name
		family   = _.find(collection, {cssName: fontFamily });
	}

	if ( !family ) {
		for (const font of collection) {
			if (font.variants.some(variant => variant.cssName === fontFamily)) {
				family = font;
				break;
			}
		}
	}

	if( family ){
		variant = deriveVariantFromModel( collection, family, style, weight, fvs, familyInCSS );
	}

	return variant

}

export function deriveVariantFromModel ( collection, family, style, weight, fvs, familyInCSS ) {

		let variants = _.get(family, 'variants');
		let variant = null; 

		if( family.axes && fvs ){
			variant = getMatchingFVSVariant(variants, fvs, weight, style);
			// variant = _.find(variants, {css_font_style: style, css_font_fvs: fvsString });
		}
		
		// second condition ensures we find families by weight that use FVS settings
		if( !variant && !family.axes ){
			variant = _.filter(variants, {css_font_style: style, weight: weight });

			if( variant && variant?.length !== undefined && variant?.length > 1 && familyInCSS ){
				variant = _.find(variant, { cssName: familyInCSS })
			} else if( variant ) {
				variant = variant[0];
			}
		}

		// second condition ensures we find families by weight that use FVS settings
		if( !variant && !family.axes ){
			variant = _.filter(variants, {css_font_style: style, weight: parseInt( weight ) });

			if( variant && variant?.length !== undefined && variant?.length > 1 && familyInCSS ){
				variant = _.find(variant, { cssName: familyInCSS })
			} else if( variant ) {
				variant = variant[0];
			}
		}

		if( !variant ){
			let modWeight = weight;

			if( weight == 'normal' || weight == 'regular' ){
				modWeight = '400'
			} else if ( weight == 'bold' ){
				modWeight = '700'
			} else if( weight == '400' || weight == 400 ){
				modWeight = 'normal'
			} else if( weight == '700' || weight == 700 ){
				modWeight = 'bold'
			}

			variant = _.find(variants, {css_font_style: style, weight: modWeight});
		}

		return variant;
}

export function getMatchingFVSVariant( variants, fvsInCSS, weightInCSS, styleInCSS = null ){

	let variant = null;
	// Create obj map of all CSS variables
	let fvsCSSObj = createFVSPropertyMap( fvsInCSS );
	// Check if we have a font weight derived from CSS
	let fontWeight = weightInCSS ? weightInCSS : null;

	// HACK FOR DIATYPE NOT HAVING WDTH PROPERTY IN OLDER VERSIONS
	// if( variants?.[0].family.includes('Diatype') ){
	// 	if( !fvsCSSObj.hasOwnProperty('wdth') ){
	// 		fvsCSSObj['wdth'] = 100;
	// 	}
	// }

	// Check if font weight is present in the fvs settings from the DB
	let fvsCSSObjWeighted = { ...fvsCSSObj };
	fvsCSSObjWeighted['wght'] = parseFloat(fontWeight);

	_.each(variants, (familyVariant, index)=>{

		let fvsInDB  = familyVariant['css_font_fvs'];
		let fvsDBObj = createFVSPropertyMap( fvsInDB );
		if (_.isEmpty(fvsDBObj)) {
			console.warn("No FVS string for variant: ", familyVariant);
		}

		let isMatch = _.isMatch(fvsCSSObj, fvsDBObj);

		if( isMatch && !variant ){
			variant = familyVariant;
		} 
		
		if( !isMatch && !variant && fontWeight && fvsDBObj['wght']){

			// If we don't have a direct match,
		    // but we have a weight in CSS and the DB fvs contains a weight property,
			// check to see if the weight is abstracted.
			let isWeightedMatch = _.isMatch(fvsCSSObjWeighted, fvsDBObj);

			// console.log("fvsDBObj", fvsDBObj )
			if( isWeightedMatch && !variant ){
				if (styleInCSS && familyVariant.css_font_style === styleInCSS) {
					variant = familyVariant;
				}
				if (!styleInCSS) {
					variant = familyVariant;
				}
			}

		}

	});

	return variant

}

export function getSuperFamilyVariant( collection, superFamilyName, parentSuperFamily, fvsInCss, weight ){


	let superFamilyVariantFound = false;
	let superFamilyData;
	let superFamilyVariant = null;

	
	let superFamilyModels = [];

	_.each(parentSuperFamily, (superFamilySuffix)=> {
		// find the cargo font in the webfont collection. 
		collection.find(function(familyModel){
			// Build super family name
			let superFamilyMatchName = superFamilyName+' '+superFamilySuffix
			// Look for it in the collection.
			if( familyModel.family !== undefined && familyModel.family.toLowerCase().trim() == superFamilyMatchName.toLowerCase().trim() ){
				// Return it if found
				superFamilyModels.push(familyModel);
			} else {
				// Otherwise append "Variable to the end in addition to our family suffix"
				superFamilyMatchName = superFamilyName + (superFamilySuffix ? ' ' + superFamilySuffix : '') + ' Variable';
				// Search again
				if( familyModel.family !== undefined && familyModel.family.toLowerCase().trim() == superFamilyMatchName.toLowerCase().trim() ){
					superFamilyModels.push( familyModel );
				}
				
			}

		});
	})

	if( superFamilyModels.length > 0 ){
		// If we have super family models, then iterate through them all to find the variant.
		_.each( superFamilyModels, function(familyModel){
			let superFamilyVariants = familyModel.variants;
			let checkVariant = getMatchingFVSVariant(superFamilyVariants, fvsInCss, weight );
			if( checkVariant ){
				superFamilyVariant = { model: familyModel, variant: checkVariant };
			}
		})
	}

	return superFamilyVariant

}

export function generateFontChangeObj(variantArray, index, selector){
	// Update Redux store
	// let dataToStore = fontModelVariants.find(obj => { return obj.name === fontName });
	let dataToStore = variantArray[index]

	let dataFamily 	 = _.get(dataToStore, 'cssName'); // font-family: "Helvetica Neue"
	let dataWeight 	 = _.get(dataToStore, 'weight'); // font-weight: "400"
	let dataStyle    = _.get(dataToStore, 'css_font_style');  // font-style: "normal"
	let dataFVS      = _.get(dataToStore, 'writeable_fvs'); // font-variation-settings: 'ital' 0, 'SRFF' 100;
	let dataRawFVS   = _.get(dataToStore, 'css_font_fvs');

	if( dataRawFVS ){
		let fvsMap = createFVSPropertyMap( dataRawFVS );

		if( fvsMap['wght'] ){
			dataWeight = fvsMap['wght'];
		}
	}

	if( !dataWeight && dataWeight !== 0 ){
		dataWeight = null;
	}

	// ffs = 'font-feature-settings' css property
	let ffs    = null;

	if( dataFamily === '"Chaumont Script"' ){
		ffs = '"kern", "liga", "dlig", "calt"';
	}

	if( dataFamily === '"Infini GF Ligatures"' ){
		ffs = '"liga", "dlig"' ;
	}

	if( dataFamily.includes("Gravity") ){
		ffs = '"ss03", "ss05", "ss06", "ss07", "ss09"';
	}



	let changeObj = {
		'font-family'             : dataFamily, // font-family: "Helvetica Neue"
		'font-weight'             : dataWeight,
		'font-style'              : dataStyle,
		'font-feature-settings'   : ffs,
		'font-variation-settings' : dataFVS,
	}

	if(selector){
		changeObj = {
			[selector]: changeObj
		}
	}

	return changeObj;
}

// Process raw font list data for render -->
export function processFontCollection ( fontCollection ) {
	
	let familyArray = [];

	_.each(fontCollection, (font) => { 

		let isActive = _.get(font, 'is_active');

		if( !isActive ){ return }

		switch (font['provider']) {
			case 'cargo': {
				const variantArray = []

				_.each(font['variants'], function(variant){

					let selectorName = font['family_name'] + ' ' + variant.css_font_fvd,
					variantStyle = variant.name,
					splitStr = variantStyle.split(' ');

					for (let i = 0; i < splitStr.length; i++) {
						splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
					}

					variantStyle = splitStr.join(' ');

					let fontVariantName = font['family_name'] + ' ' + variantStyle;
					fontVariantName = fontVariantName.trim();

					let weight

					if( variant.css_font_weight == 'normal' ){
						weight = '400'
					} else if ( variant.css_font_weight == 'bold' ) {
						weight = '700'
					} else {
						weight = variant.css_font_weight
					}

					let writeable_fvs = variant.css_font_fvs ? variant.css_font_fvs : null;

					if( writeable_fvs ){
						let fvsArray = variant.css_font_fvs.split(",");

						weight = _.find(fvsArray, (attr)=>{ 
							return attr.includes('wght')
						});

						writeable_fvs = writeable_fvs.replace(/'wght' \d+(?:\.\d+)?,?/g, "").trim();
						writeable_fvs = writeable_fvs === "" ? null : writeable_fvs;

						weight = weight ? weight.replace(/[^0-9&&^.]/g, "") : weight;

						if( //HACK FOR GRAVITY VARIABLE NOT PROPERLY DISPLAYING AT MAX WDTH FVS VALUE
							font['family_name'] === 'Gravity Variable' && writeable_fvs === "'wdth' 150" ){
							writeable_fvs = "'wdth' 149.99";
							variant.css_font_fvs = "'wdth' 149.99";
						}
						// weight = parseInt(weight);
					}

					let match = font['family_name']

					match = match.replace(/[\"\']/g, '').replace(', Icons', '').trim();

					let cssName = null;
					cssName = font['family_name']; //write to C3 DOM
					if( font['family_name'].indexOf(' ') >= 0 ){
						cssName = '"'+font['family_name']+'"';
					}


					// let styleString = 'font-family: '+ font['family_name']+'; font-style: '+ variant.css_font_style +'; font-weight: '+weight+';';
					let reactString = variant.css_font_style.replaceAll(";", "");
					let reactName = font['family_name'].replaceAll(";", "");
					let reactStyle = { 
						'fontFamily' : reactName,
						'fontStyle'	 : reactString,
						'fontWeight' : weight,
						'fontVariationSettings' : null
						}

					if( writeable_fvs ){
						reactStyle = { 
							'fontFamily' : reactName,
							'fontStyle'	 : reactString,
							'fontWeight' : weight,
							'fontVariationSettings' : writeable_fvs
						}
					}

					let fontVariant = {
						name 		  	: fontVariantName, //data-name
						family 		  	: font['family_name'], //data-family
						staff_pick 	  	: variant.staff_pick, //staff pick class
						foundry 	  	: font['foundry'], // 
						serif	 	  	: font['serif'],
						source 		  	: 'cargo', // data-source
						style 		  	: variantStyle,
						css_font_style	: variant.css_font_style,
						css_font_fvs  	: variant.css_font_fvs,
						writeable_fvs 	: writeable_fvs,
						weight 		  	: weight,
						fvd 		  	: variant.css_font_fvd,
						sort 		  	: variant.sort,
						selectorName  	: selectorName,
						// styleString   	: styleString,
						cssName		  	: cssName,
						reactStyle	  	: reactStyle,
						match		  	: match,
						vid				: variant.vid,
					};
					
					variantArray.push( fontVariant )

				})

				if( //HACK FOR GRAVITY VARIABLE NOT PROPERLY DISPLAYING AT MAX WDTH FVS VALUE
					font['family_name'] === 'Gravity Variable' ){
					font['axes'][0].max = 149.99;
				}

				var font = {
						family 		 		: font['family_name'], //data-family
						cssName: removeDiacritics(font['family_name']), //data-family
						foundry 	 		: font['foundry'], // 
						google_family_name	: font['google_family_name'], //
						is_active			: font['is_active'],
						provider 		 	: 'cargo', // data-source,
						serif				: font['serif'],
						sort 		 		: font['sort'],
						variants			: variantArray,
						css_font_fvs  		: null,
						writeable_fvs 		: null,
						variable			: font['variable'],
						axes	 			: font['axes'],
						// Changed via state
						isOpen				: false,
						isClosing			: false,
						isAnimating			: false,
						containerStyles 	: null,
						headerStyles		: null
				}

				familyArray.push(font);
				break;
			}
			case 'custom': {

				const variantArray = font['variants'].map((variant) => {


					let weight

					if( variant.css_font_weight == 'normal' || variant.css_font_weight == 'regular' ){
						weight = '400'
					} else if ( variant.css_font_weight == 'bold' ) {
						weight = '700'
					} else {
						weight = variant.css_font_weight
					}

					let writeable_fvs = variant.css_font_fvs ? variant.css_font_fvs : null;

					if( writeable_fvs ){
						let fvsArray = variant.css_font_fvs.split(",");

						weight = _.find(fvsArray, (attr)=>{ 
							return attr.includes('wght')
						});

						writeable_fvs = writeable_fvs.replace(/'wght' \d+(?:\.\d+)?,?/g, "").trim();
						writeable_fvs = writeable_fvs === "" ? null : writeable_fvs;

						weight = weight ? weight.replace(/[^0-9&&^.]/g, "") : weight;
						// weight = parseInt(weight);
					}

					let match = font['family_name']
					match = match.replace(/[\"\']/g, '').replace(', Icons', '').trim();

					let cssName = variant['css_font_family'];


					// let styleString = 'font-family: '+ font['family_name']+'; font-style: '+ variant.css_font_style +'; font-weight: '+weight+';';
					let reactString = variant.css_font_style.replaceAll(";", "");
					let reactName = font['family_name'].replaceAll(";", "");
					let reactStyle = { 
						'fontFamily' : reactName,
						'fontStyle'	 : reactString,
						'fontWeight' : weight,
						'fontVariationSettings' : null
						}

					if( writeable_fvs ){
						reactStyle = { 
							'fontFamily' : reactName,
							'fontStyle'	 : reactString,
							'fontWeight' : weight,
							'fontVariationSettings' : writeable_fvs
						}
					}
		
					let fontVariant = {
						family_name   			: font['family_name'],						
						embedCode				: font.embedCode,
						embed			: variant.embed,
						name 		  	: variant.name, //data-name
						family 		  	: font['family_name'], //data-family
						staff_pick 	  	: variant.staff_pick, //staff pick class
						foundry 	  	: font['foundry'], // 
						serif	 	  	: font['serif'],
						source 		  	: 'custom', // data-source
						style 		  	: variant.css_font_style,
						css_font_family: variant.css_font_family,
						css_font_style: variant.css_font_style,
						css_font_weight: variant.css_font_weight,
						css_font_fvs  : variant.css_font_fvs,
						writeable_fvs : writeable_fvs,
						weight 		  : variant.css_font_weight,
						fvd 		  : variant.css_font_fvd,
						sort 		  : variant.sort,
						variable			: font['variable'],
						selectorName  : variant.name, //data-name
						// styleString   : styleString,
						parsedFamilyName		: font.parsed_family_name,						
						cssName		  : cssName,
						reactStyle	  : reactStyle,
						match		  : match,
						vid				: variant.vid,
					};


					return fontVariant;

				})

				var font = {
						parsedFamilyName		: font.parsed_family_name,
						embeddedFontReference	: font.embeddedFontReference,
						embedCode				: font.embedCode,
						embed: font.embed,
						family_name   			: font['family_name'],						
						family 		 			: font['family_name'], //data-family
						cssName					: font['websafe_name'], //data-family
						foundry 	 			: font['foundry'], // 
						google_family_name		: font['google_family_name'], //
						style					: font['style'],
						is_active				: font['is_active'],
						provider 		 		: 'custom', // data-source,
						serif					: font['serif'],
						sort 		 			: font['sort'],
						variants				: variantArray,
						css_font_fvs  			: null,
						writeable_fvs 			: null,
						variable				: font['variable'],
						axes	 				: font['axes'],
						// Changed via state
						isOpen					: false,
						isClosing				: false,
						isAnimating				: false,
						containerStyles 		: null,
						headerStyles			: null
				}

				familyArray.push(font);
				break;
			}
			case 'system': {

				const variantArray = []

				_.each(font['variants'], function(variant){

					let selectorName = font['family_name'] + ' ' + variant.css_font_fvd
					let match = variant.css_font_family
					match = match.replace(/[\"\']/g, '').replace(', Icons', '').trim();

					// let styleString = 'font-family: '+ variant.css_font_family +'; font-style: '+ variant.css_font_style +'; font-weight: '+ variant.css_font_weight+';';
					let reactString = variant.css_font_style.replaceAll(";", "");
					let reactName = variant.css_font_family.replaceAll(";", "");
					let reactStyle = { 
						'fontFamily' : reactName,
						'fontStyle'	 : reactString,
						'fontWeight' : variant.css_font_weight
						}

					let cssName = variant.css_font_family;

					let variantName = null;

					if( variant.name ){
						variantName = font['family_name'] + ' ' + variant.name;
						variantName = variantName.trim();
					} else {
						variantName = font['family_name'];
					}

					let fontVariant = {
						name 		 	: variantName,
						family 		 	: font['family_name'],
						staff_pick 	 	: variant.staff_pick,
						foundry 	 	: font['foundry'],
						serif	 		: font['serif'],
						source 		 	: 'system',
						style 		 	: variant.name,
						css_font_style	: variant.css_font_style,
						weight 		 	: variant.css_font_weight,
						fvd 		 	: variant.css_font_fvd,
						sort 		 	: variant.sort,
						selectorName 	: selectorName,
						// styleString  	: styleString,
						cssName			: cssName,
						reactStyle		: reactStyle,
						match			: match,
						css_font_fvs    : null,
						writeable_fvs   : null,
						vid				: variant.vid,
					};

					variantArray.push( fontVariant )

				});

				let familyMatchName = _.first(font.variants[0].css_font_family.split(','))?.trim();
				let familyCSSName = font.variants[0].css_font_family;

				var font = {
						family 		 		: font['family_name'], //data-family
						foundry 	 		: font['foundry'], // 
						google_family_name	: font['google_family_name'], //
						is_active			: font['is_active'],
						provider 		 	: 'system', // data-source,
						serif				: font['serif'],
						sort 		 		: font['sort'],
						variants			: variantArray,
						match				: familyMatchName,
						cssName			    : familyCSSName,
						variable			: font['variable'],
						// Changed via state
						isOpen				: false,
						isClosing			: false,
						isAnimating			: false,
						containerStyles 	: null,
						headerStyles		: null
				}

				familyArray.push(font);

				break;
			}
			case 'google': {

				const variantArray = []

				_.each(font['variants'], function(variant){

					let style
					let weight
					let fvd 

					if( variant.name.includes('Italic') ){
						style = 'italic'
						weight = variant.google_variant_name.split('italic')
						weight = weight[0]
						fvd = 'i'+variant.google_variant_name.charAt(0);
					} else {
						style = 'normal'
						weight = variant.google_variant_name
						fvd = 'n'+weight.charAt(0)
					}

					let match = font['family_name']
					match = match.replace(/[\"\']/g, '').replace(', Icons', '').trim();

					// let styleString = 'font-family: '+ font['family_name']+'; font-style: '+ style +'; font-weight: '+ weight +';';
					let cssName = null;
					cssName = font['family_name']; //write to C3 DOM
					if( font['family_name'].indexOf(' ') >= 0 ){
						cssName = '"'+font['family_name']+'"';
					}

					let selectorName = font['family_name'] + ' ' + fvd

					let reactString = style.replaceAll(";", "");
					let reactName = font['family_name'].replaceAll(";", "");
					let reactStyle = { 
						'fontFamily' : reactName,
						'fontStyle'	 : reactString,
						'fontWeight' : weight
						}

					let fontVariant = {
						name 		 	: font['family_name'] + ' ' + variant.name,
						family 		 	: font['google_family_name'],
						staff_pick 	 	: variant.staff_pick,
						foundry 	 	: font['foundry'],
						serif	 		: font['serif'],
						source 		 	: 'google',
						style 		 	: variant.name,
						css_font_style	: style,
						weight 		 	: weight,
						fvd 		 	: fvd,
						sort 		 	: variant.sort,
						selectorName 	: selectorName,
						// styleString  	: styleString,
						cssName			: cssName,
						reactStyle		: reactStyle,
						match			: match,
						google_variant  : variant.google_variant_name,
						css_font_fvs    : null,
						writeable_fvs 	: null,

					};

					variantArray.push( fontVariant )

				})

				var font = {
						family 		 		: font['family_name'], //data-family
						foundry 	 		: font['foundry'], // 
						google_family_name	: font['google_family_name'], //
						is_active			: font['is_active'],
						provider 		 	: 'google', // data-source,
						serif				: font['serif'],
						sort 		 		: font['sort'],
						variants			: variantArray,
						variable		    : font['variable'],
						// Changed via state
						isOpen				: false,
						isClosing			: false,
						isAnimating			: false,
						containerStyles 	: null,
						headerStyles		: null
				}

				familyArray.push(font);

				break;

			}

		}

	});

	return familyArray;
}

export function createFVSPropertyMap (fvsString, fvsSeparatedWght) {

	let stringArr = fvsString?.split(',')
	let fvsObj = {};

	// if the weight comes in separately, add it to the list
	if (fvsSeparatedWght) {
		stringArr.push(` 'wght' ${fvsSeparatedWght}`)
	}

	_.each(stringArr, function(FVS, index){
		let property = FVS.trim().split("' ")[0].trim().replace(/\'/g, '');
		let val = parseFloat(FVS.trim().split("' ")[1])

		fvsObj[property] = val;
	})

	return fvsObj
}

// Handle combo families in processed font data -->
export function handleComboFamilies (fontCollection) {
	// duplicate array
	let newCollection = JSON.parse(JSON.stringify(fontCollection));

	let comboFamilyObj = {
		// "Bureau Grot Ultra": "Bureau Grot",
		// "Fort Extrabold": "Fort", 
		"Infini GF Ligatures": "Infini GF"
	}
	
	// Remove combo families from new array and add them to their parent
	_.each( comboFamilyObj, (comboFamily, name ) => {
			// FIND index of parent family and combo family in font array.
			let comboFamilyIndex = newCollection.findIndex( font => font.family == name );
			let parentFamilyIndex = newCollection.findIndex( font => font.family == comboFamily );

			// Add combo family to parent family object.
			if( newCollection[parentFamilyIndex].comboFamily == undefined ){
				newCollection[parentFamilyIndex].comboFamily = []
			}
			newCollection[parentFamilyIndex].comboFamily.push( newCollection[comboFamilyIndex] );
			// Remove combo family from font collection.
			newCollection.splice(comboFamilyIndex, 1);
	})

	return newCollection
}

// Organize processed font data into sections staff picks/serif/sansserif/monospace for render -->
export function organizeFontCollection (fontCollection) {
		
		let staffpicks = [];
		let serifs = [];
		let sansserifs = [];
		let monospaced = [];
		let custom = [];
		let allFontsForRender = [];

		// Filter for only "active" fonts
		_.each( fontCollection, (family) => {
			if(family.is_active) {
				// Strip out Italics
				_.each(family.variants, function(variant, index){

					if( !variant ){ return }

					if( 
						variant?.['google_variant_name']?.includes('italic') ||
						( 
							variant?.['css_font_style']?.includes('italic') &&
							variant?.['source'] !== 'custom'
						)
					) {
							family['variants'].splice(index, 1);
					}

				})

				// Dig into combo families and strip out italics there as well...
				_.each(family?.comboFamily, function(combo, index){
					if( !combo ){
						return
					}
					_.each(combo.variants, function(variant, index){
						if( !variant ){ return }
						if( variant?.['google_variant_name']?.includes('italic') ||
							variant?.['css_font_style']?.includes('italic') ) {
								combo['variants'].splice(index, 1);
						}
					})

				})

				// Return the de-italicized collection
			 	allFontsForRender.push(family)
			}
		})

		// Break up collection into serif/ sans serif / monospace
		_.each(allFontsForRender, (family) => {
			if(family.serif == 'serif') {
				serifs.push(family);
			}
			if (family.serif == 'sans-serif') {
				family.serif = 'sansserif';
				sansserifs.push(family);
			}
			if (family.serif == 'monospace') {
				family.serif = 'monospace';
				monospaced.push(family);
			}
			if (family.serif == null && family.provider == 'custom') {
				custom.push(family);
			}
		});

		_.each(allFontsForRender, (family) => {
			// copy the family...
			let familyCopy = JSON.parse( JSON.stringify(family) )
			let isStaffPick = false;
			// Identify staff picks
			_.each( family.variants, (variant) => {
				if ( variant.staff_pick == true ) {
					isStaffPick = true
				}
			})
			// Push family copy into array if it's a staff pick...
			if ( isStaffPick == true ) {
				staffpicks.push( familyCopy );
			}
		})

		return {
			staffpicks 	: staffpicks,
			sansserifs 	: sansserifs,
			serifs 		: serifs,
			monospaced 	: monospaced,
			custom 		: custom
		}
}

export const deriveFontDisplayName = ( fontCollection, fontPreviewStyles, type ) => {

	if (fontPreviewStyles?.variant?.provider === 'custom') {
		return fontPreviewStyles?.variant?.name;
	}

	let variant = null;
	let familyModel = null;
	let superFamilyName = null;
	type = type ? type : 'full';

	if( !fontCollection ){
		return
	}
	
	const axisMap = fontPreviewStyles.fvs ? createFVSPropertyMap( fontPreviewStyles.fvs ) : null;

	let normalizedFamilyName = removeDiacritics( fontPreviewStyles?.family );

	familyModel = deriveFontModelFromCSS(
		fontCollection.collection,
		normalizedFamilyName
	);

	// Get variant from collection using family name, style and weight
	variant = deriveVariantFromCSS( 
		fontCollection.collection, 
		fontPreviewStyles?.family, 
		fontPreviewStyles?.style, 
		fontPreviewStyles?.weight, 
		fontPreviewStyles?.fvs,
		fontPreviewStyles?.familyInCSS

	);

	// Determine if font name is from variable or family. 
	// Sometimes FVS fonts don't have an exact variable set, so we use the family name.
	let previewName  = variant?.name || familyModel?.family || "";

	if( type === 'family' || type === 'select' ){
		previewName = familyModel?.family;

	}

	// If we don't have a variant name, check if our family is a super family.
	// If it is, we use a base name to represent all non-exact variant matches.
	// i.e. "Arizona Sans Variable" should display as "Arizona Variable"
	if( !variant?.name || type === 'family' ){
		const superFamiliesObj = fontCollection.variableSuperFamilies;

		_.find(superFamiliesObj, function(family, familyName){
			let modelName = familyModel?.family;
			if( modelName && modelName.match(familyName) ){
				superFamilyName = familyName;
			}
		});
	}

	// Strip "Variable" from font name in preview
	previewName = previewName?.match(' Variable') ? previewName.replace(' Variable', '') : previewName;

	let axesArr = familyModel?.axes ? familyModel?.axes : [];

	// separate out the axis values from the 'font-variation-settings' string in the CSS
	let axisValues = {};
	_.each(axesArr, (axis, i) => {
		let isReverseRange = axis.min < 0;
		axisValues[`${"axis-" + axis.css_fvs + (isReverseRange ? '-reversed' : '')}`] = 
			isReverseRange ? Math.abs(axisMap?.[axis.css_fvs]) : 
			axisMap?.[axis.css_fvs];
	})

	let isVariable = axesArr.length > 0;

	// If we can't find an exact FVS match and we're looking at a variable font, append "Variable" to the end of the previewed font name
	if( !variant?.name && isVariable && previewName.length > 0 && type !== 'family' ){
		previewName = previewName+" Variable";
		// Use base superfamily name if we found one above.
		if( superFamilyName ){
			previewName = superFamilyName+" Variable";
		}
	}

	if ( type === 'family' && superFamilyName ) {
		let unsanitizedName = familyModel?.family;
		let sanitizedName   = unsanitizedName.replace(' Variable', '');
		return sanitizedName;
	}

	if ( type === 'select' && superFamilyName ) {
		let sanitizedName   = previewName.replace(' Variable', '');
		return sanitizedName;
	}


	return previewName

}
