import { Component , cloneElement, toChildArray, createRef} from "preact";
import { createPortal } from 'preact/compat'
import { ADMIN_FRAME } from "../../globals";
import EditorOverlay from "./editor-overlay";
import { editorOverlayAPI } from "./editor-overlay-controller"
import SelectionStatus from "./selection-status";
import * as helpers from "@cargo/common/helpers";

import _ from 'lodash';

const presetNames = [
'{day}, {month-name} {day-of-month}, {year} {hour}:{minute} {period}',
'{hour}:{minute}:{second} {period}',
'{hour}:{minute}:{second}',
'{day-of-month}-{month}-{year}',
'{day-of-month} {month-name} {year}',
'{month-name} {day-of-month}, {year}',
'{month}/{day-of-month}/{year}',
'{day-of-month}/{month}/{year}',
'{time-of-day} of the {ordinal}',
]
const timeValues = {
	'day': 'Day',
	'day-of-month': 'Day of Month',
	'ordinal': 'Ordinal Date',
	'month': 'Month',
	'month-name': 'Name of Month',
	'period': 'AM/PM',
	'year': 'Year',
	'hour': 'Hour',
	'minute': 'Minute',
	'second': 'Second',
	'centisecond': 'Centisecond',
	'roman-year': 'Year in Roman Numerals',
	'time-of-day': 'Time of Day',
}
let clockEditors = new Set();
let clockMultiSelect = _.debounce(()=>{

	let editorArray = Array.from(clockEditors);
	let sortedArray = [];
	const multiSelect = editorArray.filter(component=>component.state.selected).length > 1;

	let multiSelectHeight = 0;


	if( multiSelect){

		const rectArray = editorArray.map((component)=>{

			let rect
			if( component.state.selected){
				rect = component.props.baseNode.getBoundingClientRect();	
			} else{
				rect = {
					top: -9e9,
				}
			}
			
			return {
				component,
				rect
			};
		});

		sortedArray = _.sortBy(rectArray, (obj, index)=>{
			return obj.rect.top
		})

		const selectedSorted = sortedArray.filter(obj=>{
			return obj.component.state.selected
		})

		multiSelectHeight = selectedSorted[selectedSorted.length-1].rect.bottom + -selectedSorted[0].rect.top;

		// return it back into an array of components
		sortedArray = sortedArray.map(obj=>{
			return obj.component
		})
	} else {
		sortedArray = editorArray;
	}

	let selectionLeaderFound = false;
	sortedArray.forEach(component=>{

		if( !multiSelect || !component.state.selected ){
			component.setState({
				pointerProxyAttention: false,
				partOfMultipleSelection: false,
				showMultipleSelectionButton: false,
				multiSelectHeight: null
			})
		} else {

			if( !selectionLeaderFound ){
				component.setState({
					pointerProxyAttention: true,
					partOfMultipleSelection: true,
					showMultipleSelectionButton: true,
					multiSelectHeight
				})
				selectionLeaderFound = true;
			} else {
				component.setState({
					partOfMultipleSelection: true,
					showMultipleSelectionButton: false,
					multiSelectHeight: null
				})					
			}

		}

	})

		
}, 100);

if(!helpers.isServer) {

	if( CargoEditor && Object.keys(CargoEditor || {}).length > 0 ){
		CargoEditor.events.on('cursor-activity', clockMultiSelect)
	} else {
		window.addEventListener('CargoEditor-load', function(){
			CargoEditor.events.on('cursor-activity', clockMultiSelect)
		});
	}	

	document.addEventListener('selectionchange', clockMultiSelect);
}

class DigitalClockEditor extends Component {
	constructor(props){
		super(props);

		this.state= {
			UIWindow: false,
			showMultipleSelectionButton: false,
			partOfMultipleSelection: false,
			fontSizePx: 16,
			lineHeightPx: 24,
			selected: false,
		}

	}

	render(props,state){

		const {

		} = props;

		const {
			UIWindow,
			showMultipleSelectionButton,
			partOfMultipleSelection,
			pointerProxyAttention,
			fontSizePx,
			lineHeightPx,
		} = state;


		let optionButton;
		const buttonHeight = 0;
		let useMultiSelectHeight = this.state.showMultipleSelectionButton && this.state.multiSelectHeight;


		return (
			<>
				<style id="digital-clock-editor">
					{`::selection{
						background-color:transparent;
					}
					:host(::selection) {
						background-color:transparent;
					}`}
				</style>
				<SelectionStatus baseNode={this.props.clockInstance}  overlayMode={false} onSelectionChange={this.onSelectionChange}/>

				<EditorOverlay
					hasFocus={!!UIWindow}
					trackResize={true}
					buttonMode="inside"
					baseNode={this.props.clockInstance}
					buttonHeight={0}
					onPointerIn={this.pointerIn}
					onPointerOut={this.pointerOut}
					render={(overlayProps)=>{

						const {
							pointerAttention,
							overlayPosition
						} = overlayProps;


						let dip = 0;
						if( overlayPosition.y <= 1 ){
							dip = Math.min(-overlayPosition.y +1, overlayPosition.height + -buttonHeight +1);
						}


						if( showMultipleSelectionButton && partOfMultipleSelection){
						
							optionButton = <button
								style={{transform: `translateY(${dip}px)`}}
								data-editor-overlay="true"				
								class={`${overlayPosition.height < 30 ? 'small': ''} clock-button multiple`}
								onMouseDown={this.onOptionsButtonClick}
								onContextMenu={this.onOptionsButtonContextMenu}
								onMouseUp={this.onOptionsButtonContextMenu}
							>Edit Multiple</button>

						} else if ( !showMultipleSelectionButton && !partOfMultipleSelection){

							optionButton = <button
								style={{transform: `translateY(${dip}px)`}}
								data-editor-overlay="true"				
								class={`${overlayPosition.height < 30 ? 'small': ''} clock-button`} 
								onMouseDown={this.onOptionsButtonClick}
								onContextMenu={this.onOptionsButtonContextMenu}

							>
								<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle className="inner-circle" cx="8.5" cy="8.65747" r="7.5" />
									<circle className="outer-circle" cx="8.5" cy="8.65747" r="8" />
									<circle className="dot" cx="8.49977" cy="8.65747" r="1" />
									<circle className="dot" cx="5.07" cy="8.65747" r="1" />
									<circle className="dot" cx="11.9295" cy="8.65747" r="1" />
								</svg>
							</button> 
						}

			
						return <>
						
						<div
							className={`editor-overlay ${showMultipleSelectionButton ? 'edit-multiple': ''} digital-clock editor-outline${(pointerAttention || !!UIWindow) ? ' hover': ''}${(pointerAttention || !!UIWindow) ? ' button-hover': ''}${!!UIWindow ? ' focus': ''}`}
							style={{
								transform: `translate3d(${overlayPosition.x}px, ${overlayPosition.y}px, 0)`,
								width: overlayPosition.width +'px',
								height:  useMultiSelectHeight ? this.state.multiSelectHeight : overlayPosition.height+'px',

							}}
						>

							{optionButton}
							{props.children}

						</div>
						</>
				}}/>

			</>)
	}

	onOptionsButtonContextMenu=(e)=>{
		if( e.metaKey){
			return;
		}
		e.preventDefault();
	}

	onMouseDown = (e)=>{
		if( e.button == 2 && !e.metaKey){
			e.preventDefault();
			this.launchUIWindow(e);
		}
	}


	onOptionsButtonClick=(e)=>{	


		if (e) {
			if (e.metaKey || e.button == 2){
				return;
			}

			e.preventDefault();
			e.stopPropagation();
			this.launchUIWindow();
		}
	

	}

	launchUIWindow = (e)=>{

		const clockRect = this.props.clockInstance.getBoundingClientRect();
		const windowHeight = document.documentElement.clientHeight;
		const windowWidth = document.documentElement.clientWidth;

		const currentValue = this.props.clockInstance.getAttribute('value');
		const isPreset = presetNames.includes(currentValue) || currentValue?.indexOf?.('{') === -1;
		const roughUIWindowHeight = isPreset ? 270 : 360;
		const roughUIWindowWidth = 220;
		const extraMargin = 15;

		let x = clockRect.left;
		let y = clockRect.top;

		let svgPosition = {
			x: clockRect.left,
			y: clockRect.top,
			left: clockRect.left,
			right: clockRect.left,
			top: clockRect.top,
			bottom: clockRect.top + 27,
			width: 0,
			height: 10
		}			

		// if there's plenty of room below, place it below
		if( windowHeight - clockRect.bottom > roughUIWindowHeight + extraMargin){
			y = clockRect.top + clockRect.height + extraMargin;

		// if there's some room but not a lot, let it overflow the clock instance a bit
		} else if ( windowHeight - (clockRect.bottom  + -clockRect.height *.5)  > roughUIWindowHeight + extraMargin) {
			y = clockRect.top + clockRect.height*.5 + extraMargin;

		// if there's very little room at the bottom but there's some room at the top, place it above						
		} else if ( clockRect.top > roughUIWindowHeight+-extraMargin ) {
			y = clockRect.top + -roughUIWindowHeight+-extraMargin
		}

		// put it to the left if there isn't room on the right
		if( windowWidth - clockRect.left < roughUIWindowWidth ){
			x = clockRect.left - roughUIWindowWidth
		}

		svgPosition.x = x;
		svgPosition.left = x;
		svgPosition.right = x;
		svgPosition.width = 0;
		svgPosition.height = 0;
		svgPosition.y = y;
		svgPosition.top = y;
		svgPosition.bottom = y;



		ADMIN_FRAME.adminWindow.UIWindowOpener.openUIWindow({
			windowName: 'digital-clock',
			positionRect: svgPosition,
			closeButton: false,
			closeOnSingleClickout: true,
			ignoreEditorWindowClickout: false,
			supportsMobile: false,
			props: {
				clickedElement : this.props.clockInstance,
				elements: Array.from(clockEditors).filter(component=>component.state.selected || component.props.clockInstance===this.props.clockInstance).map(component=>component.props.clockInstance)
			}
		});	
	}


	pointerIn = ()=>{

		if( this.state.selected){
			clockEditors.forEach(component=>{

				if( component.state.showMultipleSelectionButton ){
					component.setState({
						pointerProxyAttention: true,
					})
				}
			});			
		}

		const clockStyle = window.getComputedStyle(this.props.clockInstance);

		this.setState({
			fontSizePx: clockStyle.getPropertyValue('font-size'),
			lineHeightPx: clockStyle.getPropertyValue('line-height'),
			pointerAttention: true
		})

	}	

	pointerOut = ()=>{

		if(this.state.selected ){
			clockEditors.forEach(component=>{
				if( component.state.pointerProxyAttention ){
					component.setState({
						pointerProxyAttention: false,
					})
				}
			});	
		}

		this.setState({
			pointerAttention: false
		})		
	}



	onSelectionChange = (selected)=>{

		if( !selected && this.state.showMultipleSelectionButton ){
			this.setState({
				pointerProxyAttention: false,
				showMultipleSelectionButton: false,
			})
		}

		this.setState({
			selected,
		})
	}	


	setUIWindow = (UIWindow)=>{
		this.setState({
			UIWindow,
		})

	}

	updateUIWindowPosition = (scrollDelta)=>{

		if(!this.state.UIWindow){
			return;
		}

		this.state.UIWindow.props.superBadScrollHack.scroll(scrollDelta);

	}

	componentDidMount(){

		clockEditors.add(this);
		this.props.clockInstance._editorInterface = this;
		if(this.props.clockInstance._newClock){
			delete this.props.clockInstance._newClock;
			// do a layout first
			setTimeout(()=>{
				this.launchUIWindow()				
			}, 10)

		}

		this.props.clockInstance.addEventListener('contextmenu', this.onMouseDown);

	}

	componentWillUnmount(){
		this.props.clockInstance.removeEventListener('contextmenu', this.onMouseDown);
		clockEditors.delete(this);
		this.state.UIWindow?.closeWindow?.();

		delete this.props.clockInstance._editorInterface
	}

	componentDidUpdate(prevProps, prevState){

		if( this.props.value !== prevProps.value){
			const actualValue = this.props.clockInstance.getAttribute('value')
			if( actualValue ){
				this.state.UIWindow?.setState?.({
					customVal: timeValues.hasOwnProperty(this.props.value) ? '{' + this.props.value +'}' : this.props.value
				})
			}
		}

	}

}


export default DigitalClockEditor
