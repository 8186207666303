import { Component, createRef } from "preact";
import { createPortal } from 'preact/compat';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../actions';
import { store } from '../index';
import * as helpers from "@cargo/common/helpers";
import axios from 'axios';
import _ from "lodash";

let darkModeQuery, mobileMediaQuery, cartInstance;

if(!helpers.isServer) {
	
	darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');
	mobileMediaQuery = window.matchMedia('(max-aspect-ratio: 1/1)');

	mobileMediaQuery.addEventListener('change', () => {
		cartInstance?.setState({ isMobile: mobileMediaQuery.matches })
	});

}

export const validateShopModel = async () => {

	const state = store.getState();

	if(state.site.shop_id === null) {
		throw 'site does not have shop setup';
	}

	const shopModel = await store.dispatch(actions.fetchShopModel());

	if(_.isEmpty(shopModel)) {
		throw 'no model data'
	}

	if(
		//Store is missing config
		(
			helpers.isAdminEdit === false
			&& shopModel.has_valid_config === false
		) 
		// or site is a template
		&& !store.getState().site?.is_template
	) {

		store.dispatch({
			type: 'UPDATE_FRONTEND_STATE', 
			payload: {
				alertModal: { header: 'Coming Soon', type: 'notice' }
			}
		});

		throw 'shop modal contains invalid config';
	}

	return shopModel

}

class Cart extends Component {

	constructor(props){

		super(props);

		validateShopModel().then(shopModel => {

			this.setState({
				shopModel
			}, () => {
				this.openOverlay();
			});

		}).catch(msg => {
			console.warn(msg);
			this.closeOverlay();
		});

		this.state = {
			shopModel: null,
			loaded: false,
			animate: false,
			isMobile: mobileMediaQuery.matches,
			shadowRoot: null,
			theme: this.getTheme()
		};

		this.cartElementRef = createRef();
		this.iframeRef = createRef();

		this.startTime = null;
		this.inputRef = createRef();

		this.throttledResize = _.throttle(this.resizeHandler, 200);
	}

	closeOverlay = () =>{

		if(store.getState().frontendState.cartOpen !== true) {
			// close instantly and do not add a history item
			this.props.closeOverlay({replaceHistory: true});
		} else {
			// close with animation
			window.requestAnimationFrame( () => { 
				this.setState({ closing: true }, ()=> {
					setTimeout(this.props.closeOverlay, 280);
				});
				document.querySelector('[name="viewport"]')?.setAttribute('content', 'width=device-width, initial-scale=1, shrink-to-fit=no');
			});
		}

	}

	onKeyDown = (e) => {
	    if ( e.key === 'Escape' || e.keyCode === 9 ){
	    	let state = window.store?.getState();
	    	let hasAlert = state?.frontendState.alertModal !== false;
	    	let alertInDom = document.querySelector('.alert-window') !== null;

	    	if( hasAlert || alertInDom ){
	    		return
	    	}
	    	
	        this.closeOverlay( e );
	    }
	}

	openOverlay() {

		if( this.cartElementRef.current && !this.cartElementRef.current.shadowRoot ){
			this.cartElementRef.current.attachShadow({mode: 'open'})
		}

		this.setState({
			shadowRoot:this.cartElementRef.current?.shadowRoot
		})

		this.props.updateFrontendState({
			cartOpen: true
		});

		window.addEventListener("keydown", this.onKeyDown, true );

		// for some reason the CSS ref only gets populated after one frame. Guessing 
		// this is to do with the shadow dom stuff
		requestAnimationFrame(() => {

			if(this.iframeRef.current) {

				document.querySelector('[name="viewport"]')?.setAttribute('content', 'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1');

				this.iframeRef.current.src = `/following_frame.html`;

			}

		});

	}

	onBeforeCartInit = () => {

		// pass some methods into the frame so it can fetch products and navigate C3 frontend
		this.iframeRef.current.contentWindow.__fetchCommerceProducts__ = this.props.fetchCommerceProducts;
		this.iframeRef.current.contentWindow.__fetchContent__ = this.props.fetchContent;
		this.iframeRef.current.contentWindow.__frontend_history__ = this.props.history;
		this.iframeRef.current.contentWindow.__add_to_cart__ = this.props.addCommerceProductToCart;
		this.iframeRef.current.contentWindow.__reset_cart__ = this.props.resetCommerceCart;
		this.iframeRef.current.contentWindow.__close_overlay__ = () => {
			this.closeOverlay();
		}

	}

	onCartReady = () => {

		// sync initial route
		this.proxyRoute();

		// sync color scheme for initial cart color
		this.proxyTheme();

	}

	onCSSLoad = () => {

		// animate form
		this.setState({
			loaded: true,
			isMobile: mobileMediaQuery.matches
		}, ()=> {

			// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
			let vh = ( window.innerHeight - 30 ) * 0.01;
			let vw = ( window.innerWidth - 30 ) * 0.01;
			// Then we set the value in the --cart-vh custom property to the root of the document
			document.documentElement.style.setProperty('--cart-vh', `${vh}px`);
			document.documentElement.style.setProperty('--cart-vw', `${vw}px`);

			window.addEventListener('resize', this.throttledResize);

			document.body.style.overflow = 'hidden';
			document.scrollingElement.style.overflow = 'hidden';

			// wait 5 frames to start animating. Otherwise Safari might flicker
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					requestAnimationFrame(() => {
						requestAnimationFrame(() => {
							requestAnimationFrame(() => {
								this.setState({ 
									animate: true 
								})
							});
						});
					});
				});
			});

		})

	}

	resizeHandler = () => {
		// Set VW / VH - 30px manually for mobile form 
		let vh = ( window.innerHeight - 30 ) * 0.01;
		let vw = ( window.innerWidth - 30 ) * 0.01;

		document.documentElement.style.setProperty('--cart-vh', `${vh}px`);
		document.documentElement.style.setProperty('--cart-vw', `${vw}px`);
	}

	componentWillUnmount = () => {
		
		this.props.updateFrontendState({
			cartOpen: false
		});

		cartInstance = null;
		darkModeQuery.removeEventListener('change', this.onThemeChange);

		window.removeEventListener('cart-before-init', this.onBeforeCartInit);
		window.removeEventListener('cart-ready', this.onCartReady);

		document.body.style.overflow = '';
		document.scrollingElement.style.overflow = '';
		window.removeEventListener('resize', this.throttledResize);
		window.removeEventListener("keydown", this.onKeyDown, true );

		document.documentElement.style.removeProperty('--cart-vh');
		document.documentElement.style.removeProperty('--cart-vw');

		document.querySelector('[name="viewport"]')?.setAttribute('content', 'width=device-width, initial-scale=1, shrink-to-fit=no');

	}

	componentDidMount() {

		cartInstance = this;

		// listen for theme changes
		darkModeQuery.addEventListener('change', this.onThemeChange);

		window.addEventListener('cart-before-init', this.onBeforeCartInit, false);
		window.addEventListener('cart-ready', this.onCartReady, false);

	}

	componentDidUpdate(prevProps, prevState) {

		if(this.props.requestedTheme !== prevProps.requestedTheme) {
			// update theme if user preference is changed
			this.onThemeChange();
		}

		if(this.state.theme !== prevState.theme) {
			// notify the cart frame of theme changes
			this.proxyTheme();
		}

		this.proxyRoute();

	}

	onThemeChange = () => {

		this.setState({
			theme: this.getTheme()
		});

	}

	getTheme = () => {

		if(this.props.requestedTheme === 'auto') {
			return darkModeQuery.matches ? 'dark' : 'light';
		}

		return this.props.requestedTheme;

	}

	proxyRoute() {

		if(!this.iframeRef || !this.iframeRef.current) {
			return;
		}

		if(!_.isEqual(this.props.match, this.currentMatch)) {

			if(this.iframeRef.current.contentWindow.renderRoute) {
				this.currentMatch = this.props.match;
				this.iframeRef.current.contentWindow.renderRoute?.(this.currentMatch)
			}

		}

	}

	proxyTheme() {
		try {
			this.iframeRef.current.contentWindow.dispatchEvent(new CustomEvent('colorScheme', {
				detail: this.state.theme
			}))
		} catch(e) {}
	}

	render() {

		if(!this.state.shopModel) {
			return null;
		}

		return (
			<div className="cart" ref={this.cartElementRef}> {/* The shadow root will be attached to this DIV */}
				{this.state.shadowRoot && createPortal(<>
					<link rel="stylesheet" onLoad={this.onCSSLoad} type="text/css" href={`${PUBLIC_URL}/css/front-end/cart.css`} />
					{/* Background */}
					<div 
						className={`background${this.state.animate ? " active" : ""}${this.state.closing ? " closing " : " "}${this.state.theme}`}
						onMouseDown={()=>{
							if (this.currentMatch && !this.currentMatch.url.match('checkout')) {
								this.closeOverlay()
							}
							
						}}
						// don't push content down while css is still loading 
						style={{height: this.state.loaded ? "" : "0"}}
					>
						{/* MODAL */}
						<div 
							className={`modal${this.state.animate ? " active" : ""}${this.state.closing ? " closing" : ""}${this.state.isMobile ? " mobile" : ""}`}
							style={{visibility: this.state.loaded ? "" : "hidden"}}
						> 
							<div className="modal-inner">
								<iframe 
									ref={this.iframeRef}
									src={'about:blank'}
								></iframe>
							</div>
						</div>
					</div>
				</>, this.state.shadowRoot)}
			</div>
		);

	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateFrontendState: actions.updateFrontendState,
		fetchCommerceProducts: actions.fetchCommerceProducts,
		fetchContent: actions.fetchContent,
		fetchShopModel: actions.fetchShopModel,
		addCommerceProductToCart: actions.addCommerceProductToCart,
		resetCommerceCart: actions.resetCommerceCart
	}, dispatch);
}


const ContactForm = withRouter(
	connect(
		state => ({
			requestedTheme: state.siteDesign.cart?.theme || 'light'
		}),
		mapDispatchToProps
	)(
		Cart
	)
);

export default ContactForm