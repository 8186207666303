export const effectsBorderDefaults = { 
	'border-width': '0rem',
	'border-color': 'rgba(0,0,0,.85)',
	'border-radius': '0rem',
	'border-padding': '0rem',
	'border-style': 'solid'
}

export const localBorderDefaults = { 
	'border-width': '0rem',
	'border-color': 'rgba(0,0,0,.85)',
	'border-style': 'solid',
	'border-radius': '0rem',
}

export const globalBorderDefaults = { 
	'border-width': '0rem',
	'border-color': 'rgba(0,0,0,.85)',
	'border-style': 'solid',
	'border-radius': '0rem',
}
