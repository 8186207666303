import { actionTypes } from "../actions";
import { combineReducers } from 'redux';
import { mergeWith, isArray, uniq, each } from "lodash";
import { CRDTState } from "../globals";

const defaultState = {
	byParent: {
		// this default ensures the first page has a parent to associate with
		root: []
	},
	bySort: {},
	indexById: {},
	liveIndexes: {},
	crdt_state: CRDTState.Published
}

export default function structure(state = defaultState, action) {

	if(!action.payload) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_SCAFFOLDING_FULFILLED:
		case actionTypes.FETCH_ADMIN_LIST_FULFILLED: 
		case actionTypes.FETCH_CONTENT_FULFILLED:
		case actionTypes.FETCH_SITE_PACKAGE_FULFILLED: 

			let data = action.payload.data;

			if(action.type === actionTypes.FETCH_SITE_PACKAGE_FULFILLED) {
				data = data.scaffolding;
			}


			if(data) {

				const newState = {...state};

				// ------- structure.byParent -------
				const byParentItemMap = {};

				each(data, function(item){

					// Ensure every set has an entry, even if there's no children
					if(item.page_type === "set") {
						if(!byParentItemMap.hasOwnProperty(item.id)) {
							byParentItemMap[item.id] = [];
						}
					}

					// Inject items into their parent's array of children
					if(item.id !== "root" && item.hasOwnProperty('set_id')) {

						if(!byParentItemMap.hasOwnProperty(item.set_id)) {
							byParentItemMap[item.set_id] = [];
						}

						byParentItemMap[item.set_id].push(item.id);
						
					}

				});

				newState.byParent = mergeWith({}, state.byParent, byParentItemMap, (objValue, srcValue) => {
					if (isArray(objValue)) {
						return uniq(objValue.concat(srcValue));
					}
				});

				// ------- structure.bySort -------
				newState.bySort = {
					...state.bySort,
					...(data).reduce((map, item) => {

						if(item.id !== "root" && item.hasOwnProperty('sort')) {
							map[item.id] = item.sort;
						}

						return map;

					}, {})
				}

				// ------- structure.indexById -------
				newState.indexById  = {
					...state.indexById,
					...(data).reduce((map, item) => {

						if(item.id !== "root" && item.hasOwnProperty('index')) {
							map[item.id] = item.index;
						}

						return map;

					}, {})
				};

				return newState

			}

		break;
	}

	return state;
};