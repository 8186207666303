import { actionTypes } from "../actions";
import { combineReducers } from 'redux';
import _ from "lodash";
import { CRDTState } from "../globals";

const defaultState = {
	images: {},
	quick_view: {},
	site: {
		enableColorFilter: false,
		enableHomeLinkActiveStates: false
	},
	cart: {
		theme: 'light'
	},
	contact_form: {
		theme: 'light'
	},
	crdt_state: CRDTState.Published
}

function reducer(state = defaultState, action) {

	if(!action.payload) {
		return state;
	}
	
	switch(action.type) {

		case actionTypes.FETCH_SITEDESIGN_MODEL_FULFILLED:
		case actionTypes.FETCH_SITE_PACKAGE_FULFILLED: 

			let data = action.payload.data;

			if(action.type === actionTypes.FETCH_SITE_PACKAGE_FULFILLED) {
				data = data.design;
			}

			if(data) {

				return _.merge(_.cloneDeep(state), data.site_design);

			}

		break;
	}

	return state;
};

export default reducer