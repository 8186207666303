import { collapseOptions } from "../../helpers";

const settings = {
	name: 'wallpaper',
	excludeFromShuffle: true,
	options: [
		{
			labelName: "Image",
			name: "activeImage",
			type: "image",
			value: 'default'
		},
		{
			type: 'spacer'
		},
		{
			name: 'pageSplit',
			type: 'custom',
			value: null,
		},
		{
			labelName: "Clip when scrolling",
			name: "clipScroll",
			type: "check-box",
			value: false,
		},
		{
			type: 'spacer'
		},
		{
			labelName: undefined,
			name: "image-fit",
			type: "radio",
			value: "fill",
			values: [
				{
					labelName: 'Fill Window',
					value: 'fill'
				},
				{
					labelName: 'Fit Window',
					value: 'fit'
				},
			],
		},
		{
			// labelName: "Cycle Images",
			labelName: "Display as Slideshow",
			name: 'cycle-group',
			type: 'details-menu',
			toggle: {
				name: "cycle-images",
				type: "check-box",
				value: false,
			},
			children: [

				{
					// labelName: "Transition Type",
					labelName: undefined,
					name: "transition-type",
					type: "radio",
					value: "slide",
					values: [
						{
							labelName: 'Fade',
							value: 'fade'
						},
						{
							labelName: 'Slide',
							value: 'slide'
						}				
					],
				},
				{
					labelName: "Autoplay",
					name: "autoplay",
					type: "check-box",
					value: true,
				},				
				{
					labelName: "Autoplay Delay",
					name: "autoplay-delay",
					type: "range-input",
					value: 3,
					min: 0,
					max: 20,
					step: .1,
				},
				{
					labelName: "Transition Speed",
					name: "transition-speed",
					type: "range-input",
					value: 0.8,
					min: 0,
					max: 10,
					step: 0.1,
				},
				{
					// labelName: "Shuffle",
					labelName: "Randomize Order",
					name: "shuffle",
					type: "check-box",
					value: false,
				},			
				{
					labelName: "Navigation",
					name: "navigation",
					type: "check-box",
					value: false,
				}				
			]
		},
		{
			labelName: "Opacity",
			name: "opacity",
			type: "range-input",
			value: 1,
			min: 0,
			max: 1,
			step: .01,
			excludeFromShuffle: true,
		},
		{
			type: 'color',
			labelName: 'Background Color',
			name: 'bg-color',
			value: 'transparent',
			showField: true
		},
		{
			labelName: "Margin",
			name: "margin",
			type: "scrubber",
			value: '0',
			addDefaultUnitToUnitlessNumber: true,		 	
			defaultUnit: 'rem',
			allowedUnits: ['px', '%','em','rem', 'vmin', 'vmax', 'vw', 'vh'],
			excludeFromShuffle: true,
			min: 0,
			max: {
				'%': 50,
				'vh': 50,
				'vw': 50,
				'vmin': 100,
				'vmax': 50,
			},
		},
		{
			// custom option to be filled in by wallpaperUI
			name: 'alignments',
			type: 'custom',
			value: null,
		},

	]
}

settings.defaults = collapseOptions(settings.options);

export default settings;
