import { collapseOptions } from "../../../helpers";
import _ from 'lodash';

const onChangeCustom = (prevSettings, newSettings, backdropView)=>{

	const changes = {};
	for (const [key, setting] of Object.entries(newSettings) ) {
		if(prevSettings[key] != setting){
			changes[key] = setting
		}
	}

	const filters = JSON.parse(newSettings.filters);

	for (const [name, value] of Object.entries(changes) ) {
		switch(name){
			case 'filters':
				backdropView.UpdateFilters(filters)
				break;

			case 'rgb':
			case 'film':
			case 'badtv':
				backdropView.ToggleComponent(filters);
				break;

			case 'image':
				backdropView.UpdateImage(value)
				break;

			default:
				backdropView.UpdateSettings(newSettings);
		}
		
	}
}

// specific for badtv, unused in UI
// noisePosn
// randMin
// randMax
// automate
// interactive

const settings = {
	name: 'badtv',
	options: [
		{
			labelName: "Image",
			name: "image",
			type: "image",
			value: '7e333180710e85119b159e6bf7711aed653bf5ee8339ed26c74cae1f031548bc'
		},
		{
			labelName: "Clip when scrolling",
			name: "clipScroll",
			type: "check-box",
			value: false,
		},
		{
			type: 'spacer'
		},			
		{
			labelName: "Bad TV",
			name: 'badtv-group',
			type: 'details-menu',
			toggle: {
				name: "badtv",
				type: "check-box",
				value: true,
			},
			children: [
				{
					name: 'distortion',
					labelName:"Thick Distortion",
					type: 'range-input',
					value: 3.0,
					min: 0.1,
					max: 10,
					step: 0.1,

					"interactive": true,
					"noisePosn": 1,
					"randMin": 2.8,
					"randMax": 3.2,
					"randRange":"low"
				},

				{
					name: 'distortion2',
					labelName:"Fine Distortion",
					type: 'range-input',
					value: 1.0,
					min: 0.1,
					max: 10,
					step: 0.1,
					
					"noisePosn": 20,
					"randMin": 1.0,
					"randMax": 5.0,
					"automate": true
				},

				{
					name: 'speed',
					labelName:"Distortion Speed",
					type: 'range-input',
					value: 0.1,
					min: 0.0,
					max: 0.3,
					step: 0.01,
					
					"noisePosn": 40,
					"randMin": 0.0,
					"randMax": 0.1,
					"randRange":"low"
				},
				{
					name: 'rollSpeed',
					labelName:"Roll Speed",
					type: 'range-input',
					value: 0.05,
					min: 0.0,
					max:0.2,
					step:0.01,
					
					"noisePosn": 50,
					"randMin": 0.0,
					"randMax": 0.6,
					"randRange":"low"
				}			
			]
		},

		{
			labelName: "RGB Shift",
			name: 'rgb-group',
			type: 'details-menu',
			toggle: {
				name: "rgb",
				type: "check-box",
				value: true,
			},
			children: [
				{
					name: 'amount',
					labelName: "Amount",
					type: 'range-input',

					"value": 0.005,
					"min": 0,
					"max": 0.1,
					"step": 0.001,
					
					"interactive":  true,
					"noisePosn": 60,
					"randMin": 0.0,
					"randMax": 0.025,
					"randRange":"low"
				},

				{
					name: 'angle',
					labelName: "Angle",
					type: 'range-input',

					"value": 0,
					"min":0,
					"max": 6.28,
					"step": 0.01,
					
					"noisePosn": 80,
					"randMin": 0.0,
					"randMax": 6.28,
					"automate": true
				}
			]
		},

		{
			labelName: "Scanlines",
			name: 'film-group',
			type: 'details-menu',
			toggle: {
				name: "film",
				type: "check-box",
				value: true,
			},
			children: [
				{
					name: 'sCount',
					labelName: "Amount",
					value: 800,
					type: 'range-input',

					"min":400,
					"max":1000,
					"step":1,
					"noisePosn": 90,
					"randMin": 650,
					"randMax": 1000,
					"randRange":"high"
				},
				{
					name: 'sIntensity',
					labelName: "Strength",
					value: 0.95,
					type: 'range-input',

					"min":0.0,
					"max":2,
					"step":0.01
				},
				{
					name: 'nIntensity',
					labelName: "Noise",
					value: 0.28,
					type: 'range-input',

					"min":0.0,
					"max":2,
					"step":0.01
				}
			]
		},
		{type: 'spacer'},

		{
			labelName: 'Automate',
			name: "automate",
			type: "check-box",
			value: false,
		},
		{
			labelName: 'Interactive',
			name: "interactive",
			type: "check-box",
			value: false,
		},

	]
}

settings.defaults = collapseOptions(settings.options);
export default settings;
export { onChangeCustom}

