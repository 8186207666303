import { Component, createRef } from 'preact';
import { PureComponent } from 'preact/compat';
import _ from 'lodash';
import { subscribe, unsubscribe, dispatch } from '../../../customEvents';

class ScrollTransition extends PureComponent {
	constructor(props){
		super(props);

		this.uid = _.uniqueId();

		this.state = {
			inited: false,
			viewportIntersection: 'neutral',
			isVisible: false,
			hasLayout: false,

			inTransition: {}, // replaced with props inScrollTransition if customElementMode
			suppressTransition: true,
		}

	}

	render(props, state){
		// by default this sets variables for translation
		// but we can extend this behavior to other variables as well, like opacity/ border
		// set <div uses="scroll-transition" scroll-variables="border opacity">
		// 'border' would print out a style for border: var(--border-above) (-inside / -below), allowing one to access it through css variables

		let viewportIntersection = this.state.viewportIntersection;

		let scrollVariables = this.props['scroll-variables'].split(' ').filter(name=>name!=='').map(variable=> '--scroll-'+variable );

		scrollVariables.push('--scroll-translate', '--scroll-transition', '--scroll-opacity');
		scrollVariables = scrollVariables.map(variableName=> variableName +': var('+(variableName.startsWith('--') ? '': '--' )+variableName+'-'+viewportIntersection + '); ').join(' ')
		let scrollDuration = this.props['scroll-animation-duration'] || '1000';
		const transitionsInProgress = Object.values(this.state.inTransition).some((val)=>val==true);

		const parentIsAnimatingOverlay = this.props.scrollContext.scrollingElement?.classList?.contains('overlay-animating') ? true : false;


		return <>
			{props.children}		
			<style id="scroll-transition">{`
				:host {
					--scroll-opacity-above: 1;
				    --scroll-opacity-inside: 1;
				    --scroll-opacity-below: 0;
					opacity: var(--scroll-opacity, 1);
					${ transitionsInProgress || this.state.waitingForTransition ? 'will-change: opacity, transform;': ''}

					--scroll-transition-above: 0s transform ease-in-out, 0s opacity ease-in-out;
				    --scroll-transition-inside: ${scrollDuration}ms transform ease-in-out, ${scrollDuration}ms opacity ease-in-out;
				    --scroll-transition-below: 0s transform ease-in-out, 0s opacity ease-in-out;
					${(this.state.suppressTransition || parentIsAnimatingOverlay) ? '': 'transition: var(--scroll-transition);'}

					--scroll-translate-below: translateY(40px);
					--scroll-translate-inside: translateY(0px);
					--scroll-translate-above: translateY(0px);

					${scrollVariables}
				}

			`}</style>		
		</>		

	}

	onViewportIntersectionChange = data => {
		this.setState({
			isVisible: data.visible,
			viewportIntersection: data.position,
			hasLayout: data.hasLayout
		})
	}



	onTransitionStart = (e)=>{
		if( e.target !== this.props.baseNode){
			return;
		}		
		this.setState(prevState=>{
			return {
				...prevState,
				waitingForTransition: false,
				inTransition: {
					...prevState.inTransition,
					[e.propertyName]: true,
				}
			}
		})	
	}	

	onTransitionEnd = (e)=>{
		if( e.target !== this.props.baseNode){
			return;
		}
		this.setState(prevState=>{

			const inTransition = {
				...prevState.inTransition,
				[e.propertyName]: false,
			};

			const transitionsCompleted = Object.values(inTransition).every((val)=>val==false);

			return {
				...prevState,
				suppressTransition: transitionsCompleted,
				inTransition: inTransition
			}
		})	
	}

	componentDidMount(){

		this.props.baseNode.addEventListener('transitionstart', this.onTransitionStart);
		this.props.baseNode.addEventListener('transitionend', this.onTransitionEnd);
		this.props.baseNode.addEventListener('transitioncancel', this.onTransitionEnd);

		if( this.props.customElementMode ){

			// this.props.visibility == the whole 'data' obj from the observer, passed down via the <UsesHost inside media-item.js
			const isVisible = this.props.visibility?.visible ?? false;
			const position  = this.props.visibility?.position ?? 'below';
			const hasLayout = this.props.visibility?.hasLayout ?? false;

			this.setState({
				isVisible: isVisible,
				viewportIntersection: position,
				hasLayout: hasLayout
			})

		} else {
			subscribe(this.props.baseNode, 'viewportIntersectionChange', this.onViewportIntersectionChange);
		}

	}

	componentDidUpdate(prevProps, prevState){
		if(
			(this.state.viewportIntersection !== prevState.viewportIntersection) 
		){

			if( this.state.isVisible){
				this.setState({
					waitingForTransition: true,					
					suppressTransition: false,
				})

			} else if( !this.state.waitingForTransition) {

				// if it's out of viewport, stop the animation and reset its state
				this.setState({
					suppressTransition: true,
				})
			}

		}
		

		if(	this.props.customElementMode 
			&& this.props.visibility?.position !== prevProps.visibility?.position 
		){
			const isVisible = this.props.visibility?.visible ? this.props.visibility.visible : false;
			const position  = this.props.visibility?.position || 'below';
			const hasLayout = this.props.visibility?.hasLayout || false;

			this.setState({
				isVisible: isVisible,
				viewportIntersection: position,
				hasLayout: hasLayout
			})
		}


	}


	componentWillUnmount(){
		clearTimeout(this.transitionCheckTimeout);
		cancelAnimationFrame(this.viewportAnimFrame);
		cancelAnimationFrame(this.lazyloadFrame);

		clearTimeout(this.initTimeout)
		this.props.baseNode.removeEventListener('transitionstart', this.onTransitionStart);		
		this.props.baseNode.removeEventListener('transitionend', this.onTransitionEnd);
		this.props.baseNode.removeEventListener('transitioncancel', this.onTransitionEnd);

		unsubscribe(this.props.baseNode, 'viewportIntersectionChange', this.onViewportIntersectionChange)

	}
}

ScrollTransition.defaultProps = {
	'scroll-variables': '',
}

ScrollTransition.transformSlot = '--scroll-translate'

export default ScrollTransition;

