import { Component, createElement, toChildArray, createRef } from "preact";
import { connect } from 'react-redux';

import { getImageWithOptions} from "../../helpers";

import { forwardRef } from 'preact/compat';
import _ from 'lodash';

class Iframe extends Component {

	constructor(props) {
		super(props);
	}

	render(props){
		const {
			model,
			src,
			isLazyLoadable,
		} = props;

		let usableSrc = (model?.is_url ? model.url : src) || '';
		const containsMusicEmbed =  ['soundcloud.', 'mixcloud.', 'bandcamp.'].some(name=> usableSrc.includes(name));
		if( !isLazyLoadable && !containsMusicEmbed ){
			usableSrc = '';
		}

		return <iframe part='media iframe' allowfullscreen mozallowfullscreen webkitallowfullscreen frameborder="0" className={this.props.loaded ? 'loaded': ''} onload={this.onLoad} ref={this.props.mediaRef} src={usableSrc} />	
	}

	onLoad=()=>{
		if( this.props.onLoad){
			this.props.onLoad();
		}
	}

	componentDidMount(){

		if( this.props.isLazyLoadable){
			this.startGDocsLoadCheck();
		}

	}

	componentDidUpdate(prevProps, prevState){
		if( this.props.loaded !== prevProps.loaded){

			clearInterval(this.gDocsTimeoutCheck);

			if( !this.props.loaded){
				this.startGDocsLoadCheck();
			}
		}

		if( this.props.isLazyLoadable !== prevProps.isLazyLoadable){

			clearInterval(this.gDocsTimeoutCheck);

			if( this.props.isLazyLoadable){				
				this.startGDocsLoadCheck();
			}
		}


	}

	componentWillUnmount(){
		clearInterval(this.gDocsTimeoutCheck);
	}

	startGDocsLoadCheck  = ()=>{

		// google docs viewer sometimes returns a 204 error, apparently at random.
		// so if the url does not load, 

		const usableSrc = this.props.model?.is_url ? this.props.model.url : this.props.src;
		if( usableSrc.indexOf('https://docs.google.com/viewer') == 0){

			let intervalTime = 2500;

			this.gDocsTimeoutCheck = setInterval(()=>{


				// document has not loaded, about:blank is same-origin so contentDocument is available
				if(this.props.mediaRef.current?.contentDocument){
					this.props.mediaRef.current.setAttribute('src', '');
					this.props.mediaRef.current.setAttribute('src', usableSrc);
				} else {
					clearInterval(this.gDocsTimeoutCheck);
				}

				intervalTime= intervalTime+500;

				if( intervalTime > 4000) {
					console.warn('Google Docs Viewer failed to load');
					clearInterval(this.gDocsTimeoutCheck);
				}

			}, intervalTime);
		}

	}
}

export default 	forwardRef((props, ref) => {
	return <Iframe {...props} mediaRef={ref} />;
})

