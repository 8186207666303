import { h } from "preact";

// turn an element into a preact-ready vdom
export const toVdom = function(element, nodeName, props={}) {

	if (element.nodeType === 3) return element.data;
	if (element.nodeType !== 1) return null;
	let attributeProps ={}
	let children = [],
		i = 0,
		a = element.attributes,
		cn = element.childNodes;
	for (i = a.length; i--; ) {
		attributeProps[a[i].name] = a[i].value;
	}
	props = {
		...attributeProps,
		...props
	}

	for (i = cn.length; i--; ) {
		const vnode = toVdom(cn[i], null);
		children[i] = vnode;
	}

	return h(nodeName || element.nodeName , props, children);
}


export const vNodeToString =(vNode, str='')=>{

	if(!vNode){
		return '';
	}

	if (typeof vNode == 'string'){
		str+= vNode
	} else if (vNode.props?.children) {

		vNode.props.children.forEach((vNode)=>{
			str+= vNodeToString(vNode);
		})

		if( vNode.type == 'div'){
			str = ' '+str+' '
		}		

	} else if ( vNode.type =='br'){
		str+=' '
	}

	return str
}

