import React, {Component} from 'react';
import _ from 'lodash';
import { LoadingAnimation } from '@cargo/ui-kit/loader/loader';
import { ProcessingAnimation } from '@cargo/ui-kit/processing/processing';


import './slide-confirm.scss';

export class SlideConfirm extends Component {

	constructor(props){
		super(props);

		this.ref = React.createRef();

		this.maxValue = 100; // the higher the smoother when dragging
		this.speed = 9; // thanks to @pixelass for this
		this.currValue = null; 
		this.rafID = null;

		this.state = {
			value: 0,
			complete: false,
			successLoading: false
		}
	}

	componentDidMount = () => {
		this.ref.current.addEventListener('mousestart', this.unlockStartHandler, false);
	}

	unlockStartHandler = () => {

	    // clear raf if trying again
	    window.cancelAnimationFrame(this.rafID);
	    
	    // set to desired value
	    this.currValue = this.state.value;

	}

	unlockEndHandler = () => {
	    
	    // store current value
	    this.currValue = +this.state.value;
	    
	    // determine if we have reached success or not
	    if(this.currValue >= this.maxValue) {
	        this.successHandler();
	    }
	    else {
	        this.rafID = window.requestAnimationFrame(this.animateHandler);
	        this.setState({complete: false});
	    }

	}

	animateHandler =()=> {

	    // update input range
	    this.setState({value: this.currValue});
	    
	    // determine if we need to continue
	    if(this.currValue > -1) {
	    	window.requestAnimationFrame(this.animateHandler);   
	    }
	    
	    // decrement value
	    this.currValue = this.currValue - this.speed;

	    setTimeout(_.bind(function(){
	    	this.setState({value: 0});
	    },this),150)
	}

	// handle successful unlock
	successHandler = () => {
		this.setState({
			successLoading: true,
			complete: false
		});

		setTimeout(()=>{
			this.props.onConfirm();
		}, 800)
	};

	onRangeChange(e) {
		e.persist();
		this.setState({value: e.currentTarget.value})

		this.setState({complete: e.currentTarget.value >= 100});
	}

	componentWillUnmount = () => {
		this.ref?.current?.removeEventListener('mousestart', this.unlockStartHandler, false);
	}

	render(){

		return (
			<div 
				className={`slide-confirm-slider${this.state.successLoading ? ' success-loading' : ''}`} 
				ref={this.ref}
				onMouseDown={(e)=> {
					this.unlockStartHandler(e);
				}}
				onMouseUp={(e)=> {
					this.unlockEndHandler(e);
				}}
				onTouchEnd={(e)=> {
					this.unlockEndHandler(e);
				}}
			>
				<span className="slide-message">{this.props.slideMessage}</span>
				<div className="slide-progress" style={{left: 'calc('+this.state.value+'% - calc('+this.state.value/100+' * 40px))'}}></div>
			    <input 
			    	type="range" 
			    	min="0" 
			    	value={this.state.value} 
			    	className={`pullee${this.state.complete ? ' complete' : ''}`} 
			    	onChange={(e)=>{
			    		this.onRangeChange(e)
			    	}}  
			    />

			    {this.state.successLoading ? (
			  		// <LoadingAnimation className='linear' />
					  <ProcessingAnimation className={`accent`} />
					  ) : ( null )}

			</div>
		)
	}

}

export default SlideConfirm;