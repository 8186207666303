import { Component , cloneElement, toChildArray, createRef} from "preact";
import { createPortal } from 'preact/compat'
import { ADMIN_FRAME } from "../../globals";
import EditorOverlay from "./editor-overlay";
import { editorOverlayAPI } from "./editor-overlay-controller"
import SelectionStatus from "./selection-status";
import { connect } from 'react-redux';
import _ from 'lodash';
import selectors from "./../../selectors";



class GalleryEditor extends Component {
	constructor(props){
		super(props);

		this.state= {
			buttonHovered: false,
			UIWindow: false,
			isDraggingInEditor: false,
			initialShowOutlines: false,
			showTransition: false,
			draggingOver: false,
			galleryIsEmpty: false,
			showInsertionIndicator: false,
			adjacentIndexes: {
				above: -1,
				below: -1,
				left: -1,
				right: -1,
			}
		}

		this.dragMap = new Map();

		this.onDragOver = _.throttle(this.onDragOver, 80);
	}

	mutationObserverOptions = {attributes: true, childList: true, subtree: true, characterData: true}

	render(props,state){

		const {
			galleryInstance,
		} = props;

		const {
			initialShowOutlines,
			showTransition,
			draggingOver,
			adjacentIndexes,
			showInsertionIndicator,
			galleryIsEmpty,
			buttonHovered,
			UIWindow
		} = state;

		let isThumbnailIndex = this.props['thumbnail-index'] !== undefined;

		const buttonHeight = 23;

		return (
			<>
			<SelectionStatus onlyAllowCollapsedRange={true} baseNode={galleryInstance}/>
			<style>{
				showInsertionIndicator && `
					::slotted(media-item) {
						--base-translate: translate(0, 0);						
						transition: .08s transform cubic-bezier(0, 0, 0.01, 1);
					}

					::slotted(media-item:nth-child(${adjacentIndexes.left+1})) {
						z-index: 1001;
						--base-translate: translateX(-15px);
					}

					::slotted(media-item:nth-child(${adjacentIndexes.right+1})) {
						z-index: 1001;
						--base-translate: translateX(15px);
					}

					::slotted(media-item:nth-child(${adjacentIndexes.above+1})) {
						z-index: 1001;
						--base-translate: translateY(-15px);
					}

					::slotted(media-item:nth-child(${adjacentIndexes.below+1})) {
						z-index: 1001;
						--base-translate: translateY(15px);
					}
				`}</style>
				<EditorOverlay
					{...this.props}
					hasFocus={!!UIWindow}
					onScrollWhileHovered={this.onScrollWhileHovered}				
					trackResize={true}
					buttonHeight={buttonHeight}
					buttonMode="inside"
					baseNode={this.props.galleryInstance}
					render={(overlayProps)=>{
						const {
							pointerAttention,
							overlayPosition
						} = overlayProps;

						let dip = 0;
						if( overlayPosition.y <= 1 ){
							dip = Math.min(-overlayPosition.y +1, overlayPosition.height + -buttonHeight +1);
						}

						if( props?.['blog-gallery'] ) {
							return 
						}

						return <><div
							className={`editor-overlay editor-outline${(pointerAttention || !!UIWindow) ? ' hover': ''}${(pointerAttention || !!UIWindow) ? ' button-hover': ''}${!!UIWindow ? ' focus': ''}`}
							style={{
								transform: `translate3d(${overlayPosition.x}px, ${overlayPosition.y}px, 0)`,
								width: overlayPosition.width +'px',
								height: overlayPosition.height+'px',
								display: (pointerAttention || !!UIWindow) ? 'block': 'none',
								minHeight: '27px',
								zIndex: 10,
							}}
						>
							<div
								className="in-editor-buttons gallery"
								style={{
									transform: `translateY(${dip}px)`
								}}
								onPointerEnter={()=>{this.setState({buttonHovered: true})}}
								onPointerLeave={()=>{this.setState({buttonHovered: false})}}
							>
								<button
									onMouseDown={(e)=>{this.launchUIWindow(e)}}
									ref={this.galleryButtonRef}
									className="text"
								>
									{isThumbnailIndex ? `Thumbnail Index` : `Gallery`}
								</button>
							</div>
							{props.otherUI}
						</div>
						</>
				}}/>

			</>)
	}

	launchUIWindow = (e, galleryRect)=>{

		if (e) {
			if (e.metaKey || e.button == 2){
				return;
			}

			e.preventDefault();
			e.stopPropagation();
		}

		const galRect = this.props.galleryInstance.getBoundingClientRect();
		const svgPosition = {
			x: galRect.x + -10,
			y: galRect.y,
			left: galRect.right + -10,
			right: galRect.right + -10,
			top: galRect.top + 0,
			bottom: galRect.top + 27,
			width: galRect.width,
			height: 10
		}

		ADMIN_FRAME.adminWindow.UIWindowOpener.openUIWindow({
			windowName: 'imagegallery-ineditor',
			windowAlias: 'imagegallery',
			positionRect: svgPosition,
			closeButton: false,
			closeOnAllClickout: true,
			supportsMobile: true,
			props: {
				activeGallery: this.props.galleryInstance
			}
		});		
	}

	onMutate = (mutationsList) =>{

		if(this.props.suppressMutation){
			return;
		}

		this.observer.disconnect();

		let changedMediaItems = [];
		let addedMediaItems = [];
		let removedMediaItems = [];
		let elementsWereAdded = false;
		let allowedElementsWereAddedOrRemoved = false;
		let isThumbnailIndex = this.props['thumbnail-index']

		let changedCharacterData = false;

		mutationsList.forEach((mutation)=>{

			if( mutation.target.tagName == 'MEDIA-ITEM'){
				
				if(
					changedMediaItems.indexOf(mutation.target) > -1 ||
					mutation.type !== 'attributes' ||
					mutation.attributeName === 'slot'  ||
					this.props.disabledMediaItemOptions.indexOf(mutation.attributeName) > -1
				){
					return
				}

				if( isThumbnailIndex){
					changedMediaItems.push(mutation.target);
				} if (
					!(mutation.attributeName === 'style' ||	mutation.attributeName === 'class' ) &&
					// all attributes are saved 
					this.props.gallerySpecificAttributes &&
					this.props.gallerySpecificAttributes.includes(mutation.attributeName)

				){
					changedMediaItems.push(mutation.target);
				}
			
			} else if(
				mutation.target == this.props.galleryInstance &&
				(mutation.removedNodes.length > 0 || mutation.addedNodes.length > 0)
			){

				if(this.props.allowedElements){
					allowedElementsWereAddedOrRemoved = [...Array.from(mutation.addedNodes), ...Array.from(mutation.removedNodes)].some(this.props.allowedElements)
				}

				elementsWereAdded = mutation.addedNodes.length > 0 || elementsWereAdded
				addedMediaItems = [...addedMediaItems, ...Array.from(mutation.addedNodes).filter((node)=>node.tagName==='MEDIA-ITEM')];
				removedMediaItems = [...removedMediaItems, ...Array.from(mutation.removedNodes).filter((node)=>node.tagName==='MEDIA-ITEM')];



				
			} else if ( mutation.type ==='characterData'){
				changedCharacterData = mutation.target;
			}


		})

		addedMediaItems = _.uniq(addedMediaItems);
		removedMediaItems = _.uniq(removedMediaItems);
		changedMediaItems = _.uniq(changedMediaItems);

		removedMediaItems.forEach(mediaItem=>{

			mediaItem.itemResize = null;
			mediaItem.itemDragStart = null;		

		})		



		// do a sweep-up
		let toRemove = Array.from(this.props.galleryInstance.childNodes).filter(node=>node?.nodeName !== 'MEDIA-ITEM' );

		// some galleries (specifically slideshow) allow placing light dom elements like navigation
		// so we make sure to filter those out
		if(this.props.allowedElements){
			_.remove(toRemove, this.props.allowedElements)
		}

		toRemove.forEach(el=>{ el.remove(); })

		if(addedMediaItems.length > 0 || removedMediaItems.length > 0){			

			if(
				!Array.from(this.props.galleryInstance.children).some(el=>el.tagName ==='MEDIA-ITEM')
			){

				this.removeEmptyGallery();
			}
		}




		if(addedMediaItems.length > 0 || removedMediaItems.length > 0 || changedMediaItems.length > 0 || allowedElementsWereAddedOrRemoved || (
			this.props['thumbnail-index'] && changedCharacterData	
		) ){

			if(this.props.onCleanUp){
				this.props.onCleanUp(addedMediaItems, removedMediaItems, changedMediaItems, changedCharacterData);
			}			

		}
		this.observer.observe(this.props.galleryInstance, this.mutationObserverOptions);				

	}


	removeEmptyGallery = _.debounce(()=>{
		if(
			!Array.from(this.props.galleryInstance.children).some(el=>el.tagName ==='MEDIA-ITEM')
		){

			if( this.state.isDraggingInEditor ){

				this.setState({
					galleryIsEmpty: true
				})
			} else if ( this.props['thumbnail-index'] === undefined) {
				this.props.galleryInstance.remove();
				return;
			}
		}
	}, 0)

	scrollIntoView =()=>{

		const galleryRect = this.props.galleryInstance.getBoundingClientRect();		
		this.props.galleryInstance.dispatchEvent(new CustomEvent('request-scroll', {
			bubbles: true,
			cancelable: true,
			composed: true,
			detail: {
				transitionTime: 120,
				lazyScroll: true,
			}
		}));			

	}

	onDragOver = (editor, e, dragData)=>{

		// items can shift around 15px in any direction while indicating so we take them into account while measuring
		const dragMargins = 15;

		if(this.props.disableDragOver ){
			return;
		}

		if(
			( !dragData.customDropTarget || dragData.customDropTarget === this.props.galleryInstance) &&
			( dragData.draggedMediaItems.length > 0 || dragData.fromOutside ) &&
			!dragData.inAdmin &&
			this.props.galleryInstance.contains(dragData.dropTarget)
		) {

			if(!this.state.showInsertionIndicator){
				this.dragMap.clear();
			}

			const galleryRect = this.props.galleryInstance.getBoundingClientRect();

			// adjacent media figure search algorithm, could use some more sophistication
			// .closest() and elementFromPoint are much faster than doing getElementsFromPoint and filtering.			

			const searchPoints = 60;
			let searchIncrement = 1.033;
			let sweepAngle = 2.399827721492203;

			const galleryChildren = Array.from(this.props.galleryInstance.children);

			// start by getting position of cursor relative to gallery position
			const elements = [];
			let x = e.clientX + -galleryRect.left;
			let y = e.clientY + -galleryRect.top;

			let dist = 15;
			let i, angle =0, posX, posY = 0;
			let el = null;

			// then, make a sweeping circle around that point, collecting elements that intersect that point
			for(i = 0; i < searchPoints; i++){
				el = null;

				angle = angle + sweepAngle				
				dist = dist*searchIncrement+2

				posX = e.clientX+Math.cos(angle)*(dist);
				posY = e.clientY+Math.sin(angle)*(dist);

				el = document.elementFromPoint(posX, posY);

				if(el){
					el = el.closest('MEDIA-ITEM') || null;
				}

				if( !this.props.galleryInstance.contains(el) ){
					el = null;
				}


				if(
					el &&
					elements.indexOf(el) === -1 
				){

					// instead of checking against a live boundingclientrect,
					// check against the initial positions we collected for that element in case things
					// are sliding around while we drag
					if( this.dragMap.has(el) ){

						let rect = this.dragMap.get(el);

						if(
							posX <= rect.x2 + galleryRect.left &&
							posX >= rect.x1 + galleryRect.left &&
							posY <= rect.y2 + galleryRect.top &&
							posY >= rect.y1 + galleryRect.top 
						) {
							elements.push(el);
						}

					} else {
						elements.push(el);						
					}

				}

			 // debug display
 // 				if( !this.disp){
 // 					this.disp = [];
 // 				}
 // 
 // 				if( !this.disp[i] )  {
 // 
 // 					this.disp[i] = document.createElement('div');
 // 					this.disp[i].style.pointerEvents ='none';
 // 					this.disp[i].style.position ='fixed';
 // 					this.disp[i].style.top =0
 // 					this.disp[i].style.left =0
 // 					this.disp[i].style.zIndex = 9999;
 // 					this.disp[i].style.width = '10px';
 // 					this.disp[i].style.borderRadius='10px';
 // 					this.disp[i].style.height = '10px';
 // 					this.disp[i].style.marginLeft = '-5px';
 // 					this.disp[i].style.marginTop = '-5px';
 // 					document.body.appendChild(this.disp[i]);
 // 					this.disp[i].style.backgroundColor = 'red';					
 // 
 // 				}
 // 
 // 				this.disp[i].style.transform = `translate3d( ${posX}px, ${posY}px, 0px)`;	
				
			}

			const positions = elements.map(el=>{

				let rect;
 				if( this.dragMap.has(el)  ){
 					rect =this.dragMap.get(el);
 				} else {

 					// dragMargins add a bit of dead space around each element to make allowances for drag animation
 					// without it, elements can slide back and forth each frame as it goes in and out of hit-range
 					let clientRect = el.getBoundingClientRect();

 					rect = {
 						w: clientRect.width+-(dragMargins*2),
 						h: clientRect.height+-(dragMargins*2),
 						x1: clientRect.left + -galleryRect.left + dragMargins,
 						x2: clientRect.left + clientRect.width +-galleryRect.left + -dragMargins,
 						y1: clientRect.top +-galleryRect.top + dragMargins,
 						y2: clientRect.top + clientRect.height +-galleryRect.top + -dragMargins
 					}
 
 					this.dragMap.set(el, rect); 					
 				}

				let inY = false,
					inX = false,
					above = false,
					below = false,
					toLeft = false,
					toRight = false,
					distance = 0,
					rise = 0,
					run = 0;

				if ( x >= rect.x1 && x <= rect.x2  ){
					inX = true;

					if ( x < rect.x1+rect.w*.5){
						toRight = true;
					} else {
						toLeft = true;
					}

					run = 0;

				} else if ( rect.x1 > x ){

					toRight = true;
					run = rect.x1 - x;

				} else if ( x > rect.x2 ){

					run = rect.x2 - x;		
					toLeft = true;
				}

				if( y >= rect.y1 && y <= rect.y2 ){
					inY = true;

					if ( y < rect.y1 + rect.h*.5 ){
						below = true;
					} else {
						above = true;
					}

					rise = 0;

				} else if ( rect.y1 > y ){
					below = true;
					rise = rect.y1 - y;

				} else if ( y > rect.y2 ){
					above = true;
					rise = rect.y2 - y;					
				}


				distance = Math.sqrt( (rise*rise)+(run*run) );	
				
				const index = galleryChildren.indexOf(el);
				const slotName = el.getAttribute('slot');
				let slotIndex = 0;
				let colIndex = 0;
				//column-1-slot-1
				if( this.props.dragIndicationType === 'columns'){
					slotIndex = Array.from(el.assignedSlot.parentElement.children).indexOf(el.assignedSlot);
				}
				return {
					inX,
					inY,
					distance,
					rise,
					run,
					above,
					below,
					toRight,
					toLeft,
					index,
					slotIndex,
					el,
					rect
				}				
			});

			const defaultPos = {el: null, index: -1};
			let toLeftPos = defaultPos,
				toRightPos = defaultPos,
				abovePos = defaultPos,
				belowPos = defaultPos,
				toLeftPositions = [],
				toRightPositions = [],
				abovePositions = [],
				belowPositions = [];

			positions.forEach(pos=>{

				if( pos.above){
					abovePositions.push(pos)		
				}

				if( pos.below){
					belowPositions.push(pos)		
				}

				if( pos.toRight ){	
					toRightPositions.push(pos);
				}

				if( pos.toLeft ){
					toLeftPositions.push(pos);
				}

			});

			let insertBeforeTarget = null;

			// drag indication has two modes, column and grid
			// grid only operates on left/right adjacent
			// columns can be left/right adjacent but prefer top/bottom
			let useColumnIndications = false;

			if( this.props.dragIndicationType === 'columns' ){

				let closestAbove = _.minBy(abovePositions, (pos)=> Math.abs(pos.distance) )
				let closestBelow = _.minBy(belowPositions, (pos)=> Math.abs(pos.distance) )

				let closestLeftInRow = _.minBy(toLeftPositions.filter(pos=>pos.inY), (pos)=> Math.abs(pos.distance));
				let closestRightInRow = _.minBy(toRightPositions?.filter(pos=>pos.inY), (pos)=> Math.abs(pos.distance));

				if( closestAbove && closestBelow){

					if( Math.abs(closestBelow.run) < Math.abs(closestAbove.run)){
						belowPos = closestBelow;
				 		abovePos = abovePositions.find(pos=>pos.rect.x1 < belowPos.rect.x2 && pos.rect.x2 > belowPos.rect.x1) || defaultPos
					} else {
						abovePos = closestAbove
						belowPos = belowPositions.find(pos=>pos.rect.x1 < abovePos.rect.x2 && pos.rect.x2 > abovePos.rect.x1) || defaultPos
				 	}

				} else if ( closestAbove){
				 	abovePos = closestAbove
				} else if ( closestBelow ){
				 	belowPos = closestBelow
				}

				if( closestLeftInRow && closestRightInRow ){
					useColumnIndications = closestRightInRow.rect.y1 !== closestLeftInRow.rect.y1
				} else if ( closestLeftInRow?.rect?.y1 > dragMargins || closestRightInRow?.rect?.y1 > dragMargins ){
					useColumnIndications = true;
				} else if ( abovePos?.index > -1 ){
					useColumnIndications = true;
				}  

			}

			if( useColumnIndications ){
				// using columns method, so null out left/right positions
				toLeftPos = defaultPos;
				toRightPos = defaultPos;
				abovePos = abovePos
				belowPos = belowPos 

				if( belowPos?.el){
					insertBeforeTarget = belowPos.el	
				} else {
					insertBeforeTarget = abovePos.el?.nextElementSibling?.assignedElements?.()[0] || null;
					while(insertBeforeTarget && insertBeforeTarget.tagName !== 'MEDIA-ITEM'){
						insertBeforeTarget = insertBeforeTarget?.nextElementSibling || null
					}
				}

				// this fails in scenarios where the next item isn't the next-lowest one, like when one item stretches two-columns across
				if( dragData.draggedMediaItems.length ===1 && galleryChildren.indexOf(dragData.draggedMediaItems[0]) > -1 ){
					if( galleryChildren.indexOf(insertBeforeTarget) > galleryChildren.indexOf(dragData.draggedMediaItems[0] ) ){
						insertBeforeTarget = insertBeforeTarget.nextElementSibling;	
					}	
				}			

			} else {

				let closestOnLeft = _.minBy(toLeftPositions, (pos)=> Math.abs(pos.distance) )
				let closestOnRight = _.minBy(toRightPositions, (pos)=> Math.abs(pos.distance) )

				if( closestOnLeft && closestOnRight){
					if( Math.abs(closestOnRight.rise) < Math.abs(closestOnLeft.rise)){
						toRightPos = closestOnRight;
						toLeftPos = toLeftPositions.find(pos=>pos.index == toRightPos.index +- 1)
					} else {
						toLeftPos = closestOnLeft
						toRightPos = toRightPositions.find(pos=>pos.index == toLeftPos.index + 1)

					}
				} else if ( closestOnLeft){
					toLeftPos = closestOnLeft
				} else if ( closestOnRight ){
					toRightPos = closestOnRight
				}

				// using row method, so null out above/below positions
				abovePos = defaultPos
				belowPos = defaultPos
				toRightPos = toRightPos || defaultPos
				toLeftPos = toLeftPos || defaultPos

				if( toRightPos?.el){
					insertBeforeTarget = toRightPos.el	
				} else {
					insertBeforeTarget = toLeftPos.el?.nextElementSibling;
					while(insertBeforeTarget && insertBeforeTarget.tagName !== 'MEDIA-ITEM'){
						insertBeforeTarget = insertBeforeTarget.nextElementSibling
					}			
				}
				
			}

			this.setState({
				adjacentIndexes: {
					left: toLeftPos.index,
					right: toRightPos.index,
					above: abovePos.index,
					below: belowPos.index,
				},
				insertBeforeTarget,
				showInsertionIndicator: true,
			})

			ADMIN_FRAME.globalDragEventController.setCustomDropTarget(this.props.galleryInstance);

		} else {

			if( this.state.showInsertionIndicator && this.props.onDragOut ){
				this.props.onDragOut();
			}
			this.setState({
				showInsertionIndicator: false,
			})

			ADMIN_FRAME.globalDragEventController.releaseCustomDropTarget(this.props.galleryInstance);

		}
	}

	onDragStart = (editor, e, dragData)=>{

		if(
			this.props['thumbnail-index'] &&
			( !dragData.draggedMediaItems?.[0] || !this.props.galleryInstance.contains(dragData.draggedMediaItems[0]) )
		){
			return
		}
		
		editorOverlayAPI.activateHoverElement(this.props.galleryInstance);

		if(this.props.disableDragStart){
			e.preventDefault();
			return;
		}

	}

	onAfterDragStart = (editor, e, dragData)=>{

		if(this.props.disableDragStart){
			return;
		}

		this.setState({isDraggingInEditor: true});
		
	}

	onDragEnd = (editor, e, dragData)=>{
		this.onDragOver.cancel();
		if( this.props.onDragEnd ){
			this.props.onDragEnd();
		}
		this.setState({
			showInsertionIndicator: false,
			isDraggingInEditor: false,
		}, ()=>{
			this.onDragFinish();
		})
	}

	onDragDrop = (editor, e, dragData)=>{
		this.onDragOver.cancel();

		if( this.props.onDragDrop ){
			this.props.onDragDrop();
		}	

		if(this.props.disableDrop){
			return;
		}

		if ( dragData.inAdmin ){
			return
		}

		if(
			dragData.customDropTarget === this.props.galleryInstance
		){

			CargoEditor.mutationManager.execute(()=>{

				dragData.draggedMediaItems.forEach((node, i)=>{
					if( this.props['thumbnail-index']){
						node.setSaveable(false);
					}
					this.props.galleryInstance.insertBefore(node, this.state.insertBeforeTarget)
				})

				if(this.props.onAfterDrop){
					this.props.onAfterDrop(dragData.draggedMediaItems, this.state.insertBeforeTarget, this.state.adjacentIndexes)
				} 

			});


			let draggedItem = dragData.draggedMediaItems[0];
			let draggedHref = dragData.draggedMediaItems[0]?.getAttribute('href');

			clearTimeout(this.dragTimeout);
			this.dragTimeout = setTimeout(()=>{
				const range = CargoEditor.rangy.createRange();

				if(this.props['thumbnail-index']){
					draggedItem = Array.from(this.props.galleryInstance.children).find(el=>el.getAttribute('href') == draggedHref);
				}

				if( draggedItem ){
					range.selectNode(draggedItem)
					
					CargoEditor.helpers.setActiveRange(range);				
				}

			}, 120)	
	
		}

		this.setState({
			showInsertionIndicator: false,
			isDraggingInEditor: false,
			insertBeforeTarget: null,
		}, ()=>{
			this.onDragFinish();			
		})
	}

	onBeforeDrop = (editor, e, dragData)=>{

		if(this.props.disableBeforeDrop){
			return;
		}

	}

	onDragFinish =()=>{
	
		editorOverlayAPI.testHover();

		// check to see if gallery was zero-ed out while dragging
		if( this.state.galleryIsEmpty) {

			const validFigures = Array.from(this.props.galleryInstance.children).filter((node)=>{
				return (
					node?.tagName =='MEDIA-ITEM'
				)
			})

			if(validFigures.length == 0 && this.props['thumbnail-index'] === undefined ){
				CargoEditor.mutationManager.execute(()=>{
					this.props.galleryInstance.remove();
				});
			} else {
				this.setState({
					galleryIsEmpty: false
				})
			}
		}		
	}

	setUIWindow = (UIWindow)=>{

		this.setState({
			UIWindow,
		})

		if( !!UIWindow ){
			if( typeof this.props.onUIWindowSet ==='function' ){
				this.props.onUIWindowSet(UIWindow)
			}
		}else {
			if( typeof this.props.onUIWindowRelease ==='function' ){
				this.props.onUIWindowRelease(UIWindow)
			}
		}
	}
	onScrollWhileHovered = (scrollDelta)=>{

		if( this.props.onScrollWhileHovered ){
			this.props.onScrollWhileHovered(scrollDelta)
		}

		if(!this.state.UIWindow){
			return;
		}

		this.state.UIWindow.props.superBadScrollHack.scroll(scrollDelta)

	}

	componentDidUpdate(prevProps){

		if( prevProps.suppressMutation !== this.props.suppressMutation){
			this.observer.disconnect();
			
			if( !this.props.suppressMutation){
				this.observer.observe(this.props.galleryInstance, this.mutationObserverOptions);
			}
		}

		if(!_.isEqual(prevProps.mediaItemEditorInfo, this.props.mediaItemEditorInfo )){
			this.setMediaItemEditorInfo();	
		}
	}

	setMediaItemEditorInfo = ()=>{

		this.props.galleryInstance._mediaItemEditorInfo = this.props.mediaItemEditorInfo || {
			disableResize: false,
			disableButton: false,
		}

		const mediaItems = Array.from(this.props.galleryInstance.children).filter((node)=>node.tagName==='MEDIA-ITEM')
		mediaItems.forEach((el)=>{
			el._editorInterface?.setState?.({
				mediaItemEditorInfo: {...this.props.mediaItemEditorInfo}
			})
		})


		if(this.props.mediaItemEditorInfo?.afterScaleChange){
			this.props.galleryInstance._mediaItemEditorInfo.afterScaleChange = this.props.mediaItemEditorInfo.afterScaleChange;
		}		
	}

	flushMutationQueue = ()=>{
		let mutations = this.observer.takeRecords();
		if (mutations.length > 0) {
			this.onMutate(mutations);
		}		
	}

	componentDidMount(){
		if( this.props.galleryInstance.children.length === 0 && this.props['thumbnail-index'] === undefined){
			this.props.galleryInstance.remove();
			return;
		}

		this.setMediaItemEditorInfo();
		
		this.observer = new MutationObserver(this.onMutate);		
		this.observer.observe(this.props.galleryInstance, this.mutationObserverOptions);

		this.props.galleryInstance._editorInterface = this;

		ADMIN_FRAME.globalDragEventController.on('dragstart', this.onDragStart)
		ADMIN_FRAME.globalDragEventController.on('dragover', this.onDragOver)

		ADMIN_FRAME.globalDragEventController.on('dragend', this.onDragEnd);

		ADMIN_FRAME.globalDragEventController.on('before-drop', this.onBeforeDrop)
		ADMIN_FRAME.globalDragEventController.on('drop', this.onDragDrop)
		ADMIN_FRAME.globalDragEventController.on('after-dragstart', this.onAfterDragStart)

	}

	componentWillUnmount(){


		if( this.state.UIWindow && !this.state.UIWindow.changingLayout ){
			// console.log('closing window from gallery editor', this.props.baseNode.tagName);
			// this.state.UIWindow?.closeWindow?.();
		}

		this.removeEmptyGallery.cancel();
		this.onDragOver.cancel();
		this.observer?.disconnect();
		this.observer = null;

		clearTimeout(this.showOutlinesTimeout)
		clearTimeout(this.bindTimeout);

		ADMIN_FRAME.globalDragEventController.off('dragstart', this.onDragStart)
		ADMIN_FRAME.globalDragEventController.off('dragover', this.onDragOver)
		ADMIN_FRAME.globalDragEventController.off('dragend', this.onDragEnd);

		ADMIN_FRAME.globalDragEventController.off('before-drop', this.onBeforeDrop)
		ADMIN_FRAME.globalDragEventController.off('drop', this.onDragDrop)
		ADMIN_FRAME.globalDragEventController.off('after-dragstart', this.onAfterDragStart)

		if( this.props.galleryInstance._editorInterface === this){
			this.props.galleryInstance._mediaItemEditorInfo = null;
			delete this.props.galleryInstance._editorInterface			
		}

	}

}

GalleryEditor.defaultProps = {
	disabledMediaItemOptions: [],
	dragIndicationType: 'grid', // 'columns' / 'grid'
}

export default connect((state, ownProps) => ({
		Editor_PageEditorOutlines: state.adminState?.localStorage?.Editor_PageEditorOutlines,
	})
)(GalleryEditor)