import { collapseOptions } from "../../../helpers";
import _ from 'lodash';

const videoPresets = {

	checkerboard: {
		"preset_image": "https://static.cargo.site/assets/backdrop/video/checkerboard_64.png",
		"preset_width": "16px",
		"preset_height": "16px"
	},
	
	hexed: {
		"preset_image": "https://static.cargo.site/assets/backdrop/video/hexed_16.png",
		"preset_width": "8px",
		"preset_height": "8px"
	},
	
	noise: {
		"preset_image": "https://static.cargo.site/assets/backdrop/video/noise.png",
		"preset_width": "512px",
		"preset_height": "512px"
	},
	
	
	interlace: {
		"preset_image": "https://static.cargo.site/assets/backdrop/video/interlace.png",
		"preset_width": "1px",
		"preset_height": "3px"
	}

}

const onChangeCustom = (prevSettings, newSettings, backdropView)=>{

	const changes = {};
	for (const [key, setting] of Object.entries(newSettings) ) {
		if(prevSettings[key] != setting){
			changes[key] = setting
		}
	}

	if( changes.hasOwnProperty("color") && changes?.color === null ){
		// reset to default, refraction does not consider alpha channels.
		changes.color = 'rgba(0,0,0,0)';
	}

	for (const [name, value] of Object.entries(changes) ) {

		backdropView.Update(name, value);	
		
	}
}

const settings = {
	name: 'video',
	excludeFromShuffle: true,
	options: [
		{
			labelName: "Video URL:",
			name: "video_url",
			type: "text-input",
			value: '',
			className: 'hover',
			config: 'noBars'
		},	
		{
			type: 'spacer'
		},					
		{
			name: 'pageSplit',
			type: 'custom',
			value: null,
		},	
		{
			labelName: "Clip when scrolling",
			name: "clipScroll",
			type: "check-box",
			value: false,
		},
		{
			type: 'spacer'
		},
		{
			labelName: undefined,
			name: "scale_option",
			type: "radio",
			value: "cover",
			values: [
				{
					labelName: 'Fill Window',
					value: 'cover'
				},
				{
					labelName: 'Fit Window',
					value: 'contain'
				}
			],
		},
		// {
		// 	labelName: "Don't scale above 100%",
		// 	name: "limit_size",
		// 	type: "check-box",
		// 	value: false,
		// 	requirements: [
		// 		{
		// 			option: 'scale_option',
		// 			shouldBe: 'contain'
		// 		}
		// 	]
		// },
		{
			labelName: "Margin",
			name: "margin",
			type: "scrubber",
			value: '0',
			addDefaultUnitToUnitlessNumber: true,		 	
			defaultUnit: 'rem',
			allowedUnits: ['px', '%','em','rem', 'vmin', 'vmax', 'vw', 'vh'],
			excludeFromShuffle: true,
			min: 0,
			max: {
				'%': 50,
				'vh': 50,
				'vw': 50,
				'vmin': 100,
				'vmax': 50,
			},
		},
		{
			type: 'spacer'
		},
		{
			labelName: 'Overlay',
			name: "preset_pattern",
			type: "radio",
			value: "blank",
			values: [
				{
					labelName: undefined,
					value: 'checkerboard'
				},
				{
					labelName: undefined,
					value: 'hexed'
				},
				{
					labelName: undefined,
					value: 'noise'
				},
				{
					labelName: undefined,
					value: 'interlace'
				},
				{
					labelName: undefined,
					value: 'blank'
				}
			],
		},
		{
			labelName: "Video Opacity",
			name: "alpha",
			type: "range-input",
			value: 100,
			min: 0,
			max: 100,
			step: 1,
			excludeFromShuffle: true,
		},
		{
			labelName: "Background Color",
			name: "color",
			type: "color",
			value: '#FFFFFF00',
		},
		{
			type: 'spacer'
		},
		{
			labelName: "Display Still Image In Mobile Devices",
			name: "use_mobile_image",
			type: "check-box",
			value: false
		},	
		{
			name: 'image',
			type: 'custom',
			value: '7e333180710e85119b159e6bf7711aed653bf5ee8339ed26c74cae1f031548bc',
			renderWhenDisabled: false,
			requirements: [
				{
					option: 'use_mobile_image',
					shouldBe: true
				}
			]
		},		
	]
}

settings.transformChanges = (settings, changes)=>{

	if( changes['preset_pattern']){

		if(videoPresets[changes.preset_pattern]) {
			changes.using_preset = true;
			changes.image_active = true;
			changes = {
				...changes,
				...videoPresets[changes.preset_pattern]
			}

		} else {
			
			changes.using_preset = false;
			changes.image_active = false;

		}
	}
	return changes;
}


settings.defaults = collapseOptions(settings.options);

// settings with no ui, used only for defaults
settings.defaults.image_url = "//static.cargo.site/assets/backdrop/default_1024.jpg";

export default settings;

export {onChangeCustom}
