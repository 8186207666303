import { collapseOptions } from "../../../helpers";
import _ from 'lodash';
import tinycolor from 'tinycolor2';

		    // "image_url": "//static.cargo.site/assets/backdrop/default_1024.jpg",
		    // 'image_size': 1024,
		    // 'image': undefined,
		    // "reflection_type": "reflection",
		    // "flip": true,
		    // "refraction_ratio": 19,
		    // "mouse_interaction": true,
		    // "mouse_speed": 63,
		    // "refract_interaction": false,
		    // "refract_motion": "speed",
		    // "geometry": "shard",
		    // "zoom": 130,
		    // "rot_x": 145,
		    // "rot_y": 195,
		    // "rot_z": 115,
		    // "rot_speed_x": 3.5,
		    // "rot_speed_y": -1.5,
		    // "rot_speed_z": 2,
		    // "color": "rgba(255,255,255,1)",
		    // "rgb": "rgb(255,255,255)",
		    // "color_r": "255",
		    // "color_g": "255",
		    // "color_b": "255",
		    // "alpha": "1",
		    // "overlay_type": "multiply_color",
		    // "plugin_id": 20

const onChangeCustom = (prevSettings, newSettings, backdropView)=>{

	const changes = {};
	for (const [key, setting] of Object.entries(newSettings) ) {
		if(prevSettings[key] != setting){
			changes[key] = setting
		}
	}

	for (const [name, value] of Object.entries(changes) ) {

		backdropView.Update(name, value);	
		
	}
}

const settings = {
	name: 'refraction',
	options: [
		{
			labelName: "Image",
			name: "image",
			type: "image",
			value: '7e333180710e85119b159e6bf7711aed653bf5ee8339ed26c74cae1f031548bc'
		},
		{
			type: 'spacer',
		},
		{
			name: 'pageSplit',
			type: 'custom',
			value: null,
		},		
		{
			labelName: "Clip when scrolling",
			name: "clipScroll",
			type: "check-box",
			value: false,
		},
		{
			type: 'spacer'
		},			
		{
			// labelName: "Distortion Type",
			labelName: undefined,
			name: "reflection_type",
			type: "radio",
			value: "reflection",
			values: [
				{
					labelName: 'Reflection',
					value: 'reflection'
				},
				{
					labelName: 'Refraction',
					value: 'refraction'
				}				
			],
		},
		{
			labelName: "Flip Image",
			name: "flip",
			type: "check-box",
			value: true,
		},
		{
			labelName: "Refraction Ratio",
			name: "refraction_ratio",
			type: "range-input",
			requirements: [
				{
					option: 'reflection_type',
					shouldBe: 'refraction'
				}
			],
			value: 19,
			min: 0,
			max: 100,
			step: .1,
		},	
		{type: 'spacer'},


		{
			labelName: "Mouse Interaction",
			name: "mouse_interaction",
			type: "check-box",
			value: true,
		},
		{
			labelName: "Mouse Movement Range",
			name: "mouse_speed",
			type: "range-input",
			value: 63,
			min: 0,
			max: 100,
			step: .1,
		},
		{
			labelName: "Dynamic Image Refraction",
			name: "refract_interaction",
			type: "check-box",
			value: false,
			requirements: [
				{
					option: 'reflection_type',
					shouldBe: 'refraction'
				}
			]
		},
		{
			labelName: null,
			name: "refract_motion",
			type: "radio",
			value: "speed",
			requirements: [
				{
					option: 'reflection_type',
					shouldBe: 'refraction'
				},
				{
					option: 'refract_interaction',
					shouldBe: true
				}
			],			
			values: [
				{
					labelName: 'Gather',
					value: 'gather'
				},
				{
					labelName: 'Disperse',
					value: 'disperse'
				},
				{
					labelName: 'Speed',
					value: 'speed'
				}
			],
		},
		{type: 'spacer'},



		{
			labelName: "Geometry: ",
			name: "geometry",
			type: "select",
			values: [
				{
					labelName: 'Fullerene',
					value:"buckyball_3"
				},
				{
					labelName: 'Compound Eye',
					value:"compoundeye_2"
				},
				{
					labelName: 'Shattered Glass',
					value:"shattered"
				},
				{
					labelName: 'Shard',
					value:"shard"
				},
				{
					labelName: 'Warped Glass',
					value:"warped_glass_3"
				},
				{
					labelName: 'Tube',
					value:"tube"
				},
				{
					labelName: 'Cubic',
					value:"cubic"
				},
				{
					labelName: 'Turbine',
					value:"turbine"
				},
				{
					labelName: 'Icosahedron',
					value:"icosahedron_small"
				},
				{
					labelName: 'Geodesic',
					value:"icosahedron_2"
				},
			]
		},
		{
			labelName: "Geometry Zoom",
			name: "zoom",
			type: "range-input",
			value: 100,
			min: 100,
			max: 300,
			step: 1,
		},
		{
			labelName: "Rotation X",
			name: "rot_x",
			type: "range-input",
			value: 145,
			min: 0,
			max: 360,
			step: 1,
		},
		{
			labelName: "Rotation Y",
			name: "rot_y",
			type: "range-input",
			value: 195,
			min: 0,
			max: 360,
			step: 1,
		},
		{
			labelName: "Rotation Z",
			name: "rot_z",
			type: "range-input",
			value: 115,
			min: 0,
			max: 360,
			step: 1,
		},
		{
			labelName: "Rotation Speed X",
			name: "rot_speed_x",
			type: "range-input",
			value: 3.5,
			min: -20,
			max: 20,
			step: 0.1,
		},
		{
			labelName: "Rotation Speed Y",
			name: "rot_speed_y",
			type: "range-input",
			value: -1.5,
			min: -20,
			max: 20,
			step: 0.1,
		},
		{
			labelName: "Rotation Speed Z",
			name: "rot_speed_z",
			type: "range-input",
			value: 2,
			min: -20,
			max: 20,
			step: 0.1,
		},
		{type: 'spacer'},		
		{
			labelName: "Color Overlay",
			name: "color",
			type: "color",
			value: '#ffffff00',
		},
		{
			// labelName: "Overlay Type",
			labelName: undefined,
			name: "overlay_type",
			type: "radio",
			value: "multiply_color",
			values: [
				{
					labelName: 'Multiply',
					value: 'multiply_color'
				},
				{
					labelName: 'Screen',
					value: 'screen_color'
				}				
			],
		},	
	]
}

settings.defaults = collapseOptions(settings.options);

// settings with no ui, used only for defaults

settings.defaults.image_url = "//static.cargo.site/assets/backdrop/default_1024.jpg";

settings.defaults.rgb = {
	value: 'rgb(255,255,255)'
}
settings.transformChanges= (settings, changes)=>{

	if( changes.hasOwnProperty("color") && changes?.color === null ){
		// reset to default, refraction does not consider alpha channels.
		changes.color = 'rgb(255,255,255)';
	}

	if(changes.color) {
		const color = tinycolor(changes.color);
		changes['rgb'] = color.toRgbString();
		changes['color'] = color.toRgbString();
		changes['alpha'] = color.getAlpha();
	}

	return changes;
}

export default settings;
export {onChangeCustom}
