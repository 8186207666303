import React from 'react';
import './loader.scss';

export const LoadingAnimation = ({ className, height, width, reverseChance, type }) => {

    const style = height || width ? { height: height ? height : '', width: width ? width : '' } : { };

    let reverse = false;

    if( reverseChance && type !== 'linear' ){
        // Generate a random number between 0 and 1
        const random = Math.random();
        // Account for the chance variable passed.
        reverse = random < reverseChance / 100; 
    }

    return (
        <div 
            className={`loader${type === 'linear' ? ' linear' : ' pulsing'}${reverse ? ' reverse' : ''}${className ? ' '+className : ''}`}
        >
            <div style={style}>
                <svg className="loading" x="0px" y="0px" viewBox="0 0 60 60" fill="none">
                    <path d="M29.9999 0V29.9999H0C0 13.4314 13.4314 0 29.9999 0ZM29.9999 29.9999H60C60 46.5685 46.5683 60 29.9999 60V29.9999Z" fill="white"/>
                </svg>
            </div>
        </div>
    )
} 
