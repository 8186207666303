import { Component, createContext } from "preact";
import { PureComponent } from 'preact/compat';
import selectors from "../selectors";
import _ from 'lodash';
import { connect } from 'react-redux';
import { memoizeWeak } from "../helpers";

export const PinManagerContext = createContext();

export let updateHeights = () => {};

let _renderedPages = [];

class PinManager extends PureComponent {

	constructor(props) {

		super(props);

		this.state = {
			adjustPairs: [],
			levelData: {},
			pageHeights: {},
			renderedPins: [],
			renderedPages: [],
			nestingMap: {},
			onPageMount: el => {
				_renderedPages = [..._renderedPages, el];
			},
			onPageUnmount: el => {
				_renderedPages = _renderedPages.filter(pageEl => pageEl !== el);
			}
		}

		updateHeights = this.updateHeights;

	}

	updateHeights = (newPageHeights) => {

		this.setState((prevState) => {
			return {
				pageHeights: {
					...prevState.pageHeights,
					...newPageHeights
				}
			}
		});
	
	}

	getTallestOfAdjusterCandidates = (pinList) => {
		
		let tallestPage = null;
		let pinListHeights = [];

		_.each(pinList, page => {
			pinListHeights.push({'pageID': page.id, 'pageName': page.title, 'pageHeight': this.getHeightById(page.id)});
		})

		let tallestHeight = _.maxBy(pinListHeights, (page) => { return page.pageHeight; });

		return tallestHeight !== undefined ? _.filter(pinList, (page) => { return page.id === tallestHeight.pageID})[0] : false ;

	}

	componentDidUpdate = (prevProps, prevState) => {
		this.makePinAdjustments(prevProps, prevState);
	}

	findTallestOverlayAdjusterPageInLevel = (pinsInLevelAndPosition, location) => {

		let overlayPinsInLevel = pinsInLevelAndPosition.filter(page => {
			return page.pin_options?.overlay === true && page.pin_options.adjust === true;
		})

		return overlayPinsInLevel.length > 0 ? this.getTallestOfAdjusterCandidates(overlayPinsInLevel) : null;
	}

	getAdjustOnlyPageHeights = (location, level) => {

		let adjustablePinsOnSameLevel = _.filter(this.props.nestingMap[level][location], (page)=>{
			return page.pin_options?.adjust && !page.pin_options?.overlay;
		});

		let totalHeight = 0;
		_.each(adjustablePinsOnSameLevel, (page)=>{
			totalHeight += this.getHeightById(page.id);
		});

		return totalHeight;

	}

	getAdjustOnlyPinsByLevel = (location, startLevel) => {
		return _.filter(this.props.nestingMap[startLevel][location], (page)=>{
			let opts = page?.pin_options;
			return !opts?.overlay;
		})
	}

	getOverlayPinsByLevel = (location, startLevel) => {
		return _.filter(this.props.nestingMap[startLevel][location], (page)=>{
			let opts = page?.pin_options;
			return opts?.overlay;
		})
	}

	getClosestPageByProximity = (pages, location) => {
		return pages.sort((a,b) => 
			location === 'top' ? 
				this.props.sortMap[a.id] - this.props.sortMap[b.id] 
				: 
				this.props.sortMap[b.id] - this.props.sortMap[a.id]
		)[0];
	}

	getPagesToAdjust = (location, startLevel) => {

		let adjustedPages = [];
		let adjustedLevel = null;

		// ADJUSTS SELF:
		// if there are "adjustable" pins within the same level/location, they will recieve the adjust
		// "adjustable" pins in the same level are any non-"overlay" or "adjust only" pins
		let adjustablePinsOnSameLevel = this.getAdjustOnlyPinsByLevel(location, startLevel);
		if (adjustablePinsOnSameLevel.length > 0) {
			// get the one sorted closest to the adjustee in the sort
			adjustedPages.push(this.getClosestPageByProximity(adjustablePinsOnSameLevel, location))

		} else {

			// ADJUSTS PINS IN THE NEXT NEST LEVEL:
			// go through the nested sets and find any adjustable pins in the next affected level
			// note: all pins in the next level will receive an "adjust"
			_.each(this.props.nestingMap, (level, levelNumber) => {

				// only look at levels deeper than the starting level
				if (parseInt(levelNumber) > startLevel) {
					// if there are pages in this level (and location), then one of the pages on this level will recieve the adjustment
					if (level[location].length > 0) {

						let overlayPins = level[location].filter(page => page.pin_options?.overlay === true),
							adjustOnlyPins = level[location].filter(page => page.pin_options?.overlay === false);

							// are there overlay pins? 
							// They will ALL recieve the adjustment
							adjustedPages = overlayPins.length > 0 ? overlayPins : [] ;

							// if not, are there adjust only pages? 
							// the closest in proximity will receive the adjustment
							if (adjustedPages.length === 0 && adjustOnlyPins?.length > 0) {
								adjustedPages.push(this.getClosestPageByProximity(adjustOnlyPins, location));
							}

							adjustedLevel = levelNumber;

						// since a page was found to be adjusted, stop the loop
						// but, be sure there is one, and if not, then the loop will continue
						if (adjustedPages.length > 0) return false;
					}
				}
			})
		}

		

		// ADJUSTS THE CONTENT:
		// if there are no adjustable pins, then look into the "normal" pages (meaning, non-pinned pages)
		// note: only 1 page will recieve the adjust
		if (adjustedPages[0] === undefined) {

			const nonPinnedPages = this.props.renderedPages
				.filter(page => page.pin === false)
				.sort((a,b) => this.props.sortMap[a.id] - this.props.sortMap[b.id]);

			let direction = location === 'top' ? 0 : nonPinnedPages.length - 1;

			if (nonPinnedPages.length > 0) {
				adjustedPages.push(nonPinnedPages[direction]);
			}
			

		}

		return {adjustedPages: adjustedPages, adjustedLevel: adjustedLevel};

	}

	getHeightById = (pid) => {

		// returned last known height or calculate if not available yet.
		return this.state.pageHeights[pid] ?? _renderedPages.find(page => page.id === pid)?.getBoundingClientRect().height;

	}

	makePinAdjustments = (prevProps, prevState) => {

		// if there are no available pages do not continue
		if (!this.props.renderedPages || this.props.renderedPages.length === 0) return;
		// or there are no updates to pins or pages, don't continue
		if (_.isEqual(this.props.renderedPins, prevProps.renderedPins) 
			&& _.isEqual(this.state.pageHeights, prevState.pageHeights) 
			&& _.isEqual(this.props.renderedPages, prevProps.renderedPages)
			&& _.isEqual(this.props.sortMap, prevProps.sortMap)
		) return;

		let adjustPairs = [], 
			levelData = {
				levels: {},
				locations: {
					top: {totalHeight: 0},
					bottom: {totalHeight: 0}
				}
			};

		// loop through each nested level (sets)
		_.each(this.props.nestingMap, (level, levelNumber) => {

			let levelObj = {};
				levelNumber = parseInt(levelNumber);
				levelObj['level'] = levelNumber;

			//loop through each location (top pins, bottom pins)
			_.each(level, (locationPages, location) => {
				if (location === 'center') return; // 'center' is informational only
				
				levelObj[location] = {
					// if a level adjusts a pinned page within the same level (by location)
					adjustsSelf: false,
					// the total height of a "level" (by location)
					totalHeight: 0,
					// the "top" or "bottom" value where the pins of each level should start
					startingHeight: null,
					// the ID of the page doing the adjusting
					adjusterId: null,
					// the height of the page doing the adjusting
					adjusterHeight: null,
					// the level receiving an adjust
					adjustedLevel: null,
					// the pages receiving an adjust
					adjusteeIDs: null,
					// has overlay pins,
					hasOverlayPins: null
				};

				// shorthand for "location by level"
				let LL = levelObj[location];
				// indicate if the level has overlay pins
				LL['hasOverlayPins'] = this.getOverlayPinsByLevel(location, levelNumber).length > 0;

				// first check if there is an overlay pin in this level and designate the tallest if there are multiple
				// if ther are none, then no adjust will occur
				let adjuster = this.findTallestOverlayAdjusterPageInLevel(locationPages, location);
				if (adjuster) {

					// set the "adjusterId" of this levelObj
					LL['adjusterId'] = adjuster.id;
					LL['adjusterHeight'] = this.getHeightById(adjuster.id);

				// STEP 1: 
					// set a flag in the levelObj that this level adjusts itself
					LL['adjustsSelf'] = this.getAdjustOnlyPinsByLevel(location, levelNumber).length > 0;

				// STEP 2: 
					// find the page(s) in the next level to adjust
					let adjustees = this.getPagesToAdjust(location, levelNumber).adjustedPages;
					if (adjustees?.length > 0) {

						LL['adjustedLevel'] = this.props.renderedPins[adjustees[0].id]?.depth ?? 'content';
						LL['adjusteeIDs'] = _.map(adjustees, 'id');

						adjustPairs[adjuster.id] = {
							adjusts: _.map(adjustees, 'id'),
							location: location, 
							adjustedHeight: this.getHeightById(adjuster.id)
						};
					}
				}

				/// if a level adjusts itself include the tallest height, otherwise do not

			// STEP 3: store the height of each level
				let startingHeight = 0,
					tallestOverlayHeight = adjuster ? this.getHeightById(adjuster.id) : 0,
					// useTallestOverlay = LL['adjustsSelf'];
					useTallestOverlay = true;
				// the total height of each level is determined by the tallest "overlay" page in that location
				// + plus the sum of all the "adjust only" pages
				LL['totalHeight'] = (useTallestOverlay ? tallestOverlayHeight : 0) + this.getAdjustOnlyPageHeights(location, levelNumber);

				_.each(levelData.levels, function(priorLevel, levelIndex){
					// if the level is lower than this one
					if (levelIndex < levelNumber) {
						// the starting height of this level is the sum of the previous levels' total heights
						startingHeight += priorLevel[location].totalHeight;
					}
				});

				LL['startingHeight'] = startingHeight === 0 ? null : startingHeight;

				
			})

			levelData['levels'][levelNumber] = levelObj;

		})

		const level0 = this.props.nestingMap[0]?.top || [];
		const fixedAdjustPins = level0.filter(page => {
			const opts = page.pin_options;
			return opts?.fixed === true && opts?.adjust === true;
		});
		const totalTopFixedPinsHeight = fixedAdjustPins.length > 0 ? 
			this.getHeightById(this.getTallestOfAdjusterCandidates(fixedAdjustPins)?.id) 
			: null;

		// update state if something has changed
		if (
			!_.isEqual(prevState.adjustPairs, adjustPairs)
			|| !_.isEqual(prevState.renderedPins, this.props.renderedPins)
			|| !_.isEqual(prevState.levelData, levelData)
		) {
			this.setState({
				adjustPairs: adjustPairs, 
				renderedPins: this.props.renderedPins,
				levelData: levelData,
				totalTopFixedPinsHeight: totalTopFixedPinsHeight
			})
		}

	}

	getAdjustingLevelHeight = (levelData, levelNumber, location) => {
		let theLevelHeight = null;
		_.each(levelData.levels, (level) => {
			if (level[location].adjustedLevel === levelNumber) {
				theLevelHeight = level[location].totalHeight;
			}
		})
		return theLevelHeight;

	}

	render() {
		return (
			<PinManagerContext.Provider value={this.state}>
				{ this.props.children || null }
			</PinManagerContext.Provider>
		)
	}
}

const getNestingMap = memoizeWeak(renderedPages => {

	const state = store.getState();

	return renderedPages.reduceRight((map, page) => {

		let pageDepth = selectors.getParentSetList(state, page.id).length -1;

		// force fixed pins into the 0 level
		if (page.pin === true && page.pin_options.fixed === true) {
			pageDepth = 0;
		}

		if (!map[pageDepth]) {
			map[pageDepth] = {'top': [], 'bottom': [], 'center': []};
		}

		let level = map[pageDepth]
		let pagePosition = page.pin ? page.pin_options.position : 'center';
		let levelPosition = level[pagePosition];

		levelPosition.push(page);

		// sort by page sort
		levelPosition.sort((a,b) => state.structure.bySort[a.id] - state.structure.bySort[b.id]);

		// return accumulator
		return map;

	}, {});

});

const getRenderedPins = memoizeWeak((renderedPages, sortMap) => {

	const state = store.getState();

	let renderedPageSorts = renderedPages.map(page=>{
		return {
			'depth' : selectors.getParentSetList(state, page.id).length -1,
			'pageListSort': sortMap[page.id],
			'id': page.id,
			'fixed': page.pin_options?.fixed && page.pin_options?.fixed === true
		}
	}).sort((a,b) => a.pageListSort - b.pageListSort)
	  .sort((a,b) => {
		if (a.fixed || b.fixed) {
			return a.depth - b.depth;
		}
	  });
	

	return renderedPages.reduceRight((map, page, index) => {

		if (page.pin === true && _.isPlainObject(page.pin_options)) {
			let originalDepth = selectors.getParentSetList(state, page.id).length -1;

			// write page depth to map
			map[page.id] = {
				'depth': page.pin_options.fixed ? 0 : selectors.getParentSetList(state, page.id).length -1,
				'originalDepth': originalDepth,
				'location': page.pin_options?.position,
				'type': page.pin_options.fixed ? 'fixed' : page.pin_options.overlay ? 'overlay' : 'adjust',
				'adjusts': page.pin_options?.adjust,
				'sort': sortMap[page.id],
				'pinSort': renderedPageSorts.findIndex(item => item.id === page.id) + 1
			}
		}
		// return accumulator
		return map;

	}, {});

})

const getRenderedPages = memoizeWeak((pagesById, renderedPages) => {
	
	return renderedPages
		// map elements to page models
		.map(page => pagesById[page.id])
		// exclude pages without models and overlays
		.filter(page => page !== undefined && page.overlay !== true)

});

function mapReduxStateToProps(state, ownProps) {

	const renderedPages = getRenderedPages(state.pages.byId, _renderedPages);

	// sort rendered pages
	const renderedPins = getRenderedPins(renderedPages, state.structure.bySort);

	// where pins are nested by level and then, by location
	const nestingMap = getNestingMap(renderedPages);

	return {
		renderedPages,
		renderedPins,
		nestingMap,
		sortMap: state.structure.bySort,
		adminMode: state.frontendState.adminMode
	}

}

export default connect(
	mapReduxStateToProps
)(PinManager);