import { actionTypes } from "../actions";
import { combineReducers } from 'redux';
import { CRDTState } from "../globals";
import _ from "lodash";

const defaultState = {
	data: [],
	// assume media coming from the API is published
	crdt_state: CRDTState.Published
}

export default function(state = defaultState, action) {

	if(!action.payload) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_MEDIA_FULFILLED: 

			let media = action.payload.data;

			if(media) {

				return {
					...state,
					data: _.uniqBy([
						...state.data,
						...media
					], 'id')
				};

			}

		break;
	}

	return state;
};
