import { Component, createRef } from 'preact';
import { connect } from 'react-redux';
import { withPageInfo } from '../page-info-context';
import _ from 'lodash';
//import { withPageInfo } from '../page-info-context';

const draggableItems = new Map();

class DragItem extends Component {
	constructor(props){
		super(props);
		this.state = {
			z: 0,
			x: 0,
			y: 0,
			interacted: false,
			dragging: false,
		}

		switch(this.props.baseNode.tagName){
			case "DIV":
			case "SPAN":
			case "H1":
			case "H2":
			case "H3":
			case "H4":
			case "H5":
			case "H6":
			case "H7":
			case "H8":
			case "H9":
				this.displayInlineBlock  = true;
				break;

			default:
				this.displayInlineBlock = false;
				break;	
		}
		

		this.preventClick = false;

		this.inSlideshow = this.props.baseNode.parentElement.tagName === 'GALLERY-SLIDESHOW';
	}

	render(props, state){

		if(props.adminMode){
			return props.children
		}

		const {
			x,
			y,
			z
		} = state;

		return <>
			{props.children}
			{this.inSlideshow ? null : <style id="draggable-item">{`
				:host {
					${!this.props.adminMode ? `
						pointer-events: auto!important;
						-webkit-user-select: none; 
						-webkit-touch-callout: none;
					`: ''}
					${state.dragging ? `--scroll-transition: 0s all!important; cursor: grabbing;`: 'cursor: grab;'}
					${this.displayInlineBlock ? 'display: inline-block;' : ''}
					--drag-transform: ${state.interacted ? `translate3d(${x}px, ${y}px, ${z+100}px)` : `translate(${x}px, ${y}px)`};
					${!this.props.adminMode && state.interacted ?  `z-index: ${z+100};` : ''}					
				}
			`}</style>}
		</>		

	}

	onDragStart =(e)=>{
		e.preventDefault();
		e.stopPropagation();
	}

	onTouchMove = (e)=>{
		e.preventDefault();
	}

	onPointerDown = (e)=>{

		if( this.props.adminMode || e.button ==2 || this.inSlideshow){
			return;
		}
		
		this.preventClick = false;
	
		let currentZ = this.state.z;

		draggableItems.forEach((dragItem, baseNode)=>{

			if( baseNode === this.props.baseNode ){
				return;
			}

			if ( dragItem.state.z > currentZ){
				dragItem.setState(prevState=>{
					return {
						z: prevState.z - 1
					}
				})
			}
		});		

		this.setState({
			x: this.state.x,
			y: this.state.y,
			z: draggableItems.size+10,
			interacted: true,
			dragging: true,
		})

		this.initialPosition = {
			x: e.clientX + -this.state.x,
			y: e.clientY + -this.state.y
		}

		this.mousePosition = {
			x: e.clientX,
			y: e.clientY,
		}
		this.accumulatedDelta = 0;

		window.addEventListener('pointermove', this.onPointerMove)
		window.addEventListener('pointerup', this.onPointerUp)

	}


	onPointerMove = (e)=>{

		e.preventDefault();

		const x = e.clientX;
		const y = e.clientY;
		const delta = Math.sqrt( Math.pow(this.mousePosition.x - x, 2) + Math.pow(this.mousePosition.y - y, 2));

		this.accumulatedDelta+= delta;
		if( this.accumulatedDelta > 4 ){
			this.preventClick = true;
		}

		this.mousePosition = {
			x: e.clientX,
			y: e.clientY,
		}		

		this.setState({
			x: e.clientX - this.initialPosition.x,
			y: e.clientY - this.initialPosition.y,
		})

	}

	onPointerUp = (e)=>{
		this.setState({
			dragging: false
		})
		window.removeEventListener('pointermove', this.onPointerMove)
		window.removeEventListener('pointerup', this.onPointerUp)
	}

	onClickCapture = (e)=>{
		if( this.preventClick){
			e.preventDefault();
			e.stopPropagation();
		}
	}

	bindListeners= ()=>{

		if(this.props.adminMode){
			return;
		}

		this.props.baseNode.addEventListener('dragstart', this.onDragStart)
		this.props.baseNode.addEventListener('pointerdown', this.onPointerDown)
		this.props.baseNode.addEventListener('touchmove', this.onTouchMove)
		this.props.baseNode.addEventListener('click', this.onClickCapture, {capture: true})

	}

	unbindListeners = ()=>{
		this.props.baseNode.removeEventListener('dragstart', this.onDragStart)		
		this.props.baseNode.removeEventListener('pointerdown', this.onPointerDown)
		this.props.baseNode.removeEventListener('touchmove', this.onTouchMove)

		this.props.baseNode.removeEventListener('click', this.onClickCapture, {capture: true});
	}

	componentDidMount(){
		this.bindListeners();
		draggableItems.set(this.props.baseNode, this);
		this.setState({
			z: draggableItems.size+10
		})
	}

	componentDidUpdate(prevProps, prevState){

		if(
			prevProps.adminMode !== this.props.adminMode
		){
			this.setState({
				x: 0,
				y: 0,
				z: 0,
				interacted: false,
			})
			this.unbindListeners();
			this.bindListeners();
		}
	}

	componentWillUnmount(){
		this.unbindListeners();	
		draggableItems.delete(this.props.baseNode);
	}
}

DragItem.transformSlot = '--drag-transform'

const ConnectedDragItem = connect(
    (state, ownProps) => {
        return {
            adminMode: state.frontendState.adminMode
        };
    }
)(DragItem);

export default ConnectedDragItem;