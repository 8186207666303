import { Component, createRef, cloneElement, createElement, render, Fragment} from "preact";

import { createPortal, PureComponent } from 'preact/compat';
import { bindActionCreators } from 'redux';
import { connect} from 'react-redux';
import _ from 'lodash';
import * as helpers from "@cargo/common/helpers";
import { subscribe, unsubscribe, dispatch } from '../../customEvents';

import { withPageInfo } from "./page-info-context";
import {openQuickViewFromElement} from "./../quick-view"

import selectors from '../../selectors';

import Image from "./image";
import Video from "./video";
import Iframe from "./iframe";


import register from "./register"
import MediaItemEditor from '../overlay/media-item-editor';

import { UsesHost } from "./uses/uses"

let resizeObserver;
let mediaItemMap = new Map();

if(!helpers.isServer) {

	resizeObserver = new ResizeObserver(function(entries){
						
		entries.forEach(function(entry){
			// do not trigger resize if everything collapses to 0
			if(
				entry.contentRect.width == 0 &&
				entry.contentRect.height == 0 &
				entry.contentRect.top == 0 &&
				entry.contentRect.left == 0
			) {
				return
			}

			// offsetWidth isn't accurate to rendered size and will cause constant resizing
			// let padSize = entry.target.offsetWidth - entry.contentRect.width;
			let vertPadSize = 0;
			let horizPadSize = 0;

			let leftPad = 0;
			let rightPad = 0;
			let leftBorder = 0;
			let rightBorder = 0;
			let topPad = 0;
			let bottomPad = 0;
			let bottomBorder = 0;
			let topBorder	= 0;

			// videos don't crop naturally without clip-margin support
			const needsPaddingData = entry.target.tagName === 'DIV'

			if( entry.contentBoxSize && entry.borderBoxSize && !needsPaddingData){
				if(entry.borderBoxSize.length){
					vertPadSize = entry.borderBoxSize[0].blockSize - entry.contentBoxSize[0].blockSize
					horizPadSize = entry.borderBoxSize[0].inlineSize - entry.contentBoxSize[0].inlineSize
				} else {
					vertPadSize = entry.borderBoxSize.blockSize - entry.contentBoxSize.blockSize
					horizPadSize = entry.borderBoxSize.inlineSize - entry.contentBoxSize.inlineSize
				}
				
			} else {
				const style = window.getComputedStyle(entry.target);
				leftPad = parseFloat(style.getPropertyValue('padding-left')) || 0 ;
				rightPad = parseFloat(style.getPropertyValue('padding-right')) || 0 ;
				leftBorder = parseFloat(style.getPropertyValue('border-right-width')) || 0 ; 
				rightBorder = parseFloat(style.getPropertyValue('border-left-width')) || 0 ;
				topPad = parseFloat(style.getPropertyValue('padding-top')) || 0 ;
				bottomPad = parseFloat(style.getPropertyValue('padding-bottom')) || 0 ;
				bottomBorder = parseFloat(style.getPropertyValue('border-bottom-width')) || 0 ; 
				topBorder = parseFloat(style.getPropertyValue('border-top-width')) || 0 ;				
				// padSize = leftPad + rightPad + leftBorder + rightBorder;
				vertPadSize = topPad + bottomPad + topBorder + bottomBorder;
				horizPadSize = leftPad + rightPad + leftBorder + rightBorder;
			}

			dispatch(entry.target, 'elementResize', {
				padSize: horizPadSize,
				vertPadSize: vertPadSize,
				horizPadSize: horizPadSize,
				width: entry.contentRect.width,
				height: entry.contentRect.height,
				leftPad,
				rightPad,
				leftBorder,
				rightBorder,
				topPad,
				bottomPad,
				bottomBorder,
				topBorder,
			}, {
				bubbles: false
			});

		});

	});

}

class MediaItem extends PureComponent {

	constructor(props){
		super(props);

		this.state = {
			zoomTemporarilyDisabled: false,
			scrollTransitionDisabled: false,

			slottedMediaNode: null,

			// if we have custom slotted media elements, grab its dimensions here
			// we can be embedding a video/img/vimeo/etc into a media-item and have differing
			// height/width attributes for them. cache the native dimensions here so that we can compare
			nativeMediaDimensions: {
				width: null,
				height: null,
			},

			dimensions: {
				padSize: 0,
				vertPadSize: 0,
				horizPadSize: 0,
				width: 0,
				height: 0,
			},

			mediaDimensions: {
				padSize: 0,
				vertPadSize: 0,
				horizPadSize: 0,
				width: 0,
				height: 0,


				leftPad: 0,
				rightPad: 0,
				leftBorder: 0,
				rightBorder: 0,
				topPad: 0,
				bottomPad: 0,
				bottomBorder: 0,
				topBorder: 0,				
			},

			isVisible: false,
			viewportIntersection: { hasLayout: false, visible: false, position: 'unknown' }, 
			isLazyLoadable: false,
			loaded: false,

		}


		this.uid = _.uniqueId();

		this.figureRef = createRef();
		this.mediaRef = createRef();
		this.lastMediaRef = createRef();
		this.figCaptionRef = createRef();
		this.sizingFrameRef = createRef();

		
		Object.defineProperty(this.props.baseNode, '_size', {
			get: ()=> { 
				const dimensions = {
					width: this.props.width ?? this.props.model?.width ?? this.state.nativeMediaDimensions.width ?? 1600,
					height: this.props.height ?? this.props.model?.height ?? this.state.nativeMediaDimensions.height ?? 1000,
					mediaItemSize: this.state.dimensions,
					mediaSize: this.state.mediaDimensions,
				}

				
				return dimensions;
			},
			configurable: true
		});

		Object.defineProperty(this.props.baseNode, '_nativeMediaDimensions', {
			get: ()=> { 
				return {
					width: this.state.nativeMediaDimensions.width ?? 1600,
					height: this.state.nativeMediaDimensions.height ?? 1600,
				}
			},
			configurable: true
		});

		Object.defineProperty(this.props.baseNode, '_model', {
			get: ()=> { 
				return this.props.model || null
			},
			configurable: true
		});		


		let preExistingonload = this.props.baseNode.onload

		Object.defineProperty(this.props.baseNode, 'onload', {
			get: ()=> { 
				return this.onloadCallback
			},
			set: (val)=>{
				this.onloadCallback = val;
				if( this.state.loaded && !!val ){
					this.onLoad();
				}
			},
			configurable: true

		});

		this.props.baseNode.onload = preExistingonload;


		let preExistingOnError = this.props.baseNode.onerror

		Object.defineProperty(this.props.baseNode, 'onerror', {
			get: ()=> { 
				return this.onErrorCallback
			},
			set: (val)=>{
				this.onErrorCallback = val;
			},
			configurable: true

		});		
		this.props.baseNode.onerror = preExistingOnError;

		this.props.baseNode._setZoomStatus = (status)=>{
			this.setState(prevState=>{

				if( prevState.zoomTemporarilyDisabled === status ){
					return null;
				}

				return {
					zoomTemporarilyDisabled: status
				}
			});
		};

		// disable scroll transition when in gallery.
		this.props.baseNode._setScrollTransitionStatus = (status) => {
			this.setState(prevState=>{

				if( prevState.scrollTransitionDisabled === status ){
					return null;
				}

				return {
					scrollTransitionDisabled: status
				}
			});
		};

		this.props.baseNode._forceRedraw = (status)=>{
			this.setState({
				forceRedraw: Math.random()
			});
		};		

		// getting captions

// 		Object.defineProperty(this.props.baseNode, '_caption', {
// 			get: ()=> { 
// 				return Array.from(this.props.baseNode.querySelector('figcaption')?.childNodes) || [];
// 			},
// 			set: (childArray)=>{
// 				let figCaption = this.props.baseNode.querySelector('figcaption');
// 				if( !figCaption) {
// 					figCaption = document.createElement('figcaption');
// 					figCaption.setAttribute('slot', 'caption');
// 					figCaption.classList.add('caption');
// 					this.props.baseNode.appendChild(figCaption)					
// 				}
// 				while(figCaption.firstChild){
// 					figCaption.firstChild.remove();
// 				}
// 
// 				if( !childArray || childArray.length == 0 ){
// 					figCaption.classList.add('empty')
// 				} else {
// 					figCaption.classList.remove('empty')
// 					childArray.forEach(el=>{
// 						figCaption.appendChild(el);
// 					})
// 				}
// 			},
// 			configurable: true
// 		});

		this.props.baseNode.getFileType = this.getFileType;
	}

	handleClick =(e)=>{

		// dragging will suppress quick view and other click actions
		if( e?.defaultPrevented){

			return;
		}


		// if we clicked directly on a link inside the item or inside a caption, let it trigger naturally
		let composedPath = e.composedPath();
		let clickedLink = composedPath.find(node=>node.tagName==='A')

		if( clickedLink){
			return;
		}

		// if a media item has a link attached to the sizing frame, turn the rest of the item into a clickable 'card'
		// this will override click-to-zoom
		let mainLink = this.props.baseNode.shadowRoot.querySelector('a.sizing-frame');

		// if we didn't click directly on a link, we either clicked 'the card' or are selecting text
		// if a selection is active and the basenode contains it, allow continued interaction
		let selectedTextInsideMediaItem = false;
		const selection = window.getSelection();

		if( selection && selection.rangeCount > 0 && !this.props.adminMode){

			const selectedRange = selection.getRangeAt(0);
			if (
				selectedRange &&
				selectedRange.commonAncestorContainer &&
				!selectedRange.collapsed &&
				this.props.baseNode.contains(selectedRange.commonAncestorContainer)
			){
				selectedTextInsideMediaItem = true;
			}
		}
		
		// if we clicked in the card but didn't select any text, trigger the main link
		if (mainLink && !selectedTextInsideMediaItem && !this.props.adminMode ){
			mainLink.click();
			return;
		}

		// autoplaying videos will simply behave as images normally do
		// but if a video is zoomable, then it launches/plays if we're outside of the admin
		// single click outside of admin, double click inside admin
		const fileType = this.getFileType();
		const isZoomable = this.isZoomable();

		if(
			( fileType === 'video' || fileType.startsWith('vimeo') || fileType.startsWith('youtube') ) &&
			((!this.props.adminMode && e.detail == 1) || (e.detail == 2) ) && 
			!this.props.autoplay &&
			!isZoomable
		){

			if(
				(composedPath.some(el=>el.nodeName == 'VIDEO' || el.nodeName === 'IFRAME')) &&
				this.props['browser-default']
			 ){
				return;
			}

			e.preventDefault();
			if( this.props.baseNode.playing){
				this.props.baseNode.pause();
			} else {
				this.props.baseNode.play();
			}
			return;

		}

		// if there's no image zoom happening, return normally
		if ( !isZoomable || selectedTextInsideMediaItem || !composedPath.find(el=> el && el.classList?.contains('sizing-frame')) ){		
			return;
		}

		// do not launch quick view in admin modes
		if(this.props.adminMode){
			return;
		}

		e.preventDefault();
		
		openQuickViewFromElement(this.props.baseNode);

	}

	isZoomable = ()=>{

		return this.props['force-quick-view'] || (this.props.imageSettings.image_zoom && !this.props['disable-zoom'] && !this.props.href && !this.state.zoomTemporarilyDisabled);
	}

	getFileType = ()=>{
		let {
			model,
			src,
			'dynamic-src': dynamicSrc
		} = this.props;

		src = dynamicSrc || src;

		if(
			this.props['dynamic-filetype'] !== undefined  && this.props['dynamic-filetype'] !== null
		){
			return this.props['dynamic-filetype'];
		}

		let fileType = 'none';
		if( model ){

			if (model.is_image){
				fileType = 'image'
			} else if ( model.is_video){
				fileType = 'video'				
			} else if ( model.is_url){
				fileType = 'url';
				if( model.url_type && !model.url_id){
					fileType = model.url_type;
				} else if( model.url_id && model.url_type){
					fileType = model.url_type+':'+model.url_id;
				}
			}

		} else if (src){
			const fileSrc = src.toLowerCase();
			if(
				fileSrc.endsWith('.mov') ||
				fileSrc.endsWith('.mp4') ||
				fileSrc.endsWith('.webm')
			){
				fileType = 'video'
			} else if ( 
				fileSrc.endsWith('.png') ||
				fileSrc.endsWith('.webp') ||
				fileSrc.endsWith('.jpg') ||
				fileSrc.endsWith('.jpeg') ||
				fileSrc.endsWith('.gif') ||
				fileSrc.endsWith('.avif') ||
				fileSrc.endsWith('.apng') ||
				fileSrc.endsWith('.svg')
			){
				fileType = 'image'
			} else {

				fileType = 'url'

				var id = ''
		        var vimeoRegExp = /(?:<iframe [^>]*src=")?(?:https?:\/\/(?:[\w]+\.)*vimeo\.com(?:[\/\w:]*(?:\/videos)?)?\/([0-9]+)[^\s]*)"?(?:[^>]*><\/iframe>)?(?:<p>.*<\/p>)?/;
		        var match = src.match(vimeoRegExp);

		        if (match && match[0]){
		        	let urlParts = match[0].split(/\/|\?h=|&|\?/);

		        	for( var i = 0; i < urlParts.length; i++){
		        		if(
		        			!urlParts[i].includes(':') &&
		        			!urlParts[i].includes('.') &&
		        			!urlParts[i].includes('vimeo') &&
		        			!urlParts[i].includes('=') &&
		        			!urlParts[i].includes('video') &&
		        			!urlParts[i].includes('showcase') &&
		        			!urlParts[i].includes('player') &&
		        			urlParts[i].length > 3
		        		 ) {
	        				if(id.length == 0){
	        					id = urlParts[i]
	        				} else {
	        					id+='/'+urlParts[i]
	        				}
		        			
		        		}
		        	}

		        	fileType = 'vimeo:'+id
		        }

				var youtubeRegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
		        var match = src.match(youtubeRegExp);
		        if (match && match[7].length==11){
		            id = match[7];
		            fileType = 'youtube:'+id		            
		        }		        

			}

		}
		return fileType;	
	}

	onCaptionClick = (e)=>{
		if(this.props.adminMode){
			return;
		}		
		if( e.target.closest('a') ){
			e.stopPropagation();			
		}		
	}

	render(props, state, context){

		const {
			dimensions,
			mediaDimensions,
			viewportIntersection,
			loading,
			nativeMediaDimensions,
			scrollTransitionDisabled
		} = state;

		let {
			rotation,
			drag,

			'force-visible': forceVisible,
			'dynamic-src': dynamicSrc,

			adminMode,
			imageSettings,
			pageInfo,
			width,
			height,
			baseNode,
			model,
			posterModel,

			href,
			src,
			hash,
			isMobile,
		} = props;

		const isVisible = forceVisible == undefined ? this.state.isVisible : forceVisible;
		const isLazyLoadable = forceVisible == undefined? this.state.isLazyLoadable : forceVisible;

		const {
			uid
		} = this;

		let scale = this.props.scale !== undefined ? this.props.scale : isMobile && imageSettings.mobile_image_width_maximize ? '100%' : imageSettings.scale;
		let limitBy = this.props['limit-by'] !== undefined ? this.props['limit-by'] : isMobile && imageSettings.mobile_image_width_maximize ? 'width' : imageSettings.limit_by;

		src = dynamicSrc || src;

		// SCALING AND MAX WIDTH
		// Andrew Web Consortium Sizing Guide 2023

		// 'dimensions' is the size of the media-item tag itself
		// 'mediaDimensions' is the size of the item inside the media-item tag		

		// these width/height values are 'attribute' type values that describe the 'ideal' dimensions ( like the naturalWidth / naturalHeight of an <img> ) of the element they contain
		// they determine not the actual size but the aspect ratio of the media-item
		// the order of precedence is as follows: 'width' / 'height' attributes, width/height properties inside the model, the native dimensions of the element after it loads
		let maxWidth = width ?? model?.width ?? nativeMediaDimensions.width ?? 1600
		let maxHeight = height ?? model?.height ?? nativeMediaDimensions.height ?? 1000



		// the attribute values are modulated by the scale values, which are set by the scale and limit-by attributes
		// it's possible to put non-unit values (like "100") in the 'scale' attribute, so we interpret them a certain way by making sure a default unit is appended
		const scaleArray = helpers.getCSSValueAndUnit(scale, limitBy == 'height' ? 'rem' : '%');


		// ratios are generally configured height/width due to how the padding trick is configured (padding-bottom is a percentage)
		const imageRatio = maxHeight/maxWidth;

		const pixelWidthOfImage = mediaDimensions.width;
		const pixelHeightOfImage = pixelWidthOfImage * (imageRatio);

		/*
		divide by imageRatio to get a theoretical pixel height. then add the pads to it
		*/
		const horizPadSize = mediaDimensions.horizPadSize + dimensions.horizPadSize;
		const vertPadSize = mediaDimensions.vertPadSize + dimensions.vertPadSize;

		const widthWithInnerBorder = mediaDimensions.width + mediaDimensions.horizPadSize;
		const heightWithInnerBorder = pixelHeightOfImage + mediaDimensions.vertPadSize;
		

		let framePaddingRatio = heightWithInnerBorder/widthWithInnerBorder;


		let frameWidth = scaleArray.join('');		
		let framePadding;
		let customFitHeight = null;

		if( isNaN(framePaddingRatio) ){
			framePaddingRatio = maxHeight/maxWidth 
		}

		const widthIsPercentage = maxWidth.toString().trim().endsWith('%');
		const heightIsPercentage = maxHeight.toString().trim().endsWith('%');


		const widthArray = helpers.getCSSValueAndUnit(maxWidth,'%');
		const heightArray = helpers.getCSSValueAndUnit(maxHeight,'%');

		// if both values are a percentage, base entire size off of scale and parent/fit-heights
		if( widthIsPercentage && heightIsPercentage){

			if (limitBy ==='width' || limitBy =='height'){

				frameWidth = `calc(${widthArray[0] *.01 } * var(--resize-parent-width, 100%) )`;
				customFitHeight = `calc( var(--fit-height) * ${heightArray[0] * .01} )`;
				framePadding = 'var(--custom-fit-height)';

			} else {

				frameWidth = `calc(${widthArray[0] * scaleArray[0] *.0001 } * var(--resize-parent-width, 100%) )`;
				customFitHeight = `calc( var(--fit-height) * ${heightArray[0] * scaleArray[0] * .0001} )`;
				framePadding = 'var(--custom-fit-height)';
			}

		} else if ( limitBy =='height' ){

			if( heightIsPercentage ){

				customFitHeight = `calc( var(--fit-height) * ${heightArray[0] * .01} )`;
				framePadding = 'var(--custom-fit-height)';
				frameWidth = `${widthArray[0] *.01 }px`;

			} else {

				let frameHeight = '';
				// ex. scale="calc( 100vh - 3rem )"
				if( scaleArray[1] == '%' ){
					frameHeight = `( ${scaleArray[0]*.01} * var(--resize-parent-width, 100%) )`;
				} else if( scaleArray[1].indexOf('calc(') > -1 ){
					scaleArray[1] = scaleArray[1].replace(/[\d*\.]+%/g , function(percentStr){
						const percentArray = helpers.getCSSValueAndUnit(percentStr, '%');
						return `( ${percentArray[0]*.01} * var(--resize-parent-width, 100%) )`;
					})				
					frameHeight = scaleArray[1].replace('calc(', '(')
				} else {
					// ex. scale="30" or scale="20rem"
					// default height value is 'rem'
					frameHeight = scaleArray.join('')
				}

				// if my image ratio is 2wide 1high
				// if i have a height of 320px, a media pad of 40px and an outer pad of 100px
				// then my *actual* max pixel height is 180
				// which means the *actual* max pixel width is 360
				// then my max outer width is... 360+140
				// fw = ( fh - ( 140px ) ) * ( 1 / imageRatio ) + (140px) 			

				if( widthIsPercentage ){
					frameWidth = `calc( ${widthArray[0] *.01 } * var(--resize-parent-width, 100%) )`;
					framePadding = `calc( ${frameHeight} )`;
				} else {
					frameWidth = `calc( ( ${frameHeight} - ${vertPadSize}px ) * ( 1 / ${imageRatio} ) + ${horizPadSize}px )`
					framePadding = (framePaddingRatio *100) + '%';
				}
			}



		} else if( limitBy ==='width') {

			if( widthIsPercentage ){

				frameWidth = `calc( ${widthArray[0] *.01 } * var(--resize-parent-width, 100%) )`;
				framePadding = `${heightArray[0]}px`;
			} else {

				// default width value is '%' 
				if( scaleArray[1] == '%' ){
					frameWidth = `calc(${scaleArray[0]*.01} * var(--resize-parent-width, 100%) )`;
				} else if( scaleArray[1].indexOf('calc(') > -1 ){
					
					scaleArray[1] = scaleArray[1].replace(/[\d*\.]+%/g , function(percentStr){
						const percentArray = helpers.getCSSValueAndUnit(percentStr, '%');
						return `( ${percentArray[0]*.01} * var(--resize-parent-width, 100%) )`;
					})
					frameWidth = scaleArray.join('');
				} else {
					frameWidth = scaleArray.join('')
				}

				if( heightIsPercentage ){
					customFitHeight = `calc( var(--fit-height) * ${heightArray[0] * .01} )`;
					framePadding = 'var(--custom-fit-height)';
				} else {
					framePadding = (framePaddingRatio *100) + '%';
				}				

			}

		// else if limit by fit
		} else {

			if ( widthIsPercentage ){
				frameWidth = `calc(${widthArray[0] * scaleArray[0] * .0001 } * var(--resize-parent-width, 100%) )`;
				customFitHeight = `calc( min( var(--fit-height) * ${scaleArray[0]*.01} , ${heightArray[0]*scaleArray[0]*.01}px ) )`;
				framePadding = 'var(--custom-fit-height)';
			} else if ( heightIsPercentage ){
				frameWidth = `calc( min( ${scaleArray[0] * .01 } * var(--resize-parent-width, 100%) , ${widthArray[0]*scaleArray[0]*.01}px ) )`;
				customFitHeight = `calc(  var(--fit-height) * ${scaleArray[0]* heightArray[0] *.0001}  )`;
				framePadding = 'var(--custom-fit-height)';
			} else {
				frameWidth = `calc( ( ( var(--fit-height, 50vh) * ${(scaleArray[0]/100)} ) - ${horizPadSize}px ) * ( 1 / ${imageRatio} ) + ${horizPadSize}px )`
				framePadding = (framePaddingRatio *100) + '%';
			}

		}

		let scrollAnimation = imageSettings?.scroll_animation && props['disable-scroll-animation'] !== true && !scrollTransitionDisabled && this.props.scrollContext.scrollUid !== 'default';
		let usesProps = this.props.uses || '';

		if ( usesProps.indexOf('scroll-transition') === -1 && scrollAnimation ){
			usesProps= usesProps+' scroll-transition';
		}

		let media = null;

		const fileType = this.getFileType() || '';
		const isZoomable = this.isZoomable();

		// we want to show the poster over zoomable items but only if it's not autoplaying
		const isAutoplaying = props.autoplay && props.muted;

		switch(true){

			case !isAutoplaying && isZoomable && fileType.startsWith('youtube') && posterModel && !props['browser-default']:
			case !isAutoplaying && isZoomable && fileType.startsWith('vimeo') && posterModel && !props['browser-default']:
			case !isAutoplaying && isZoomable && fileType==='video' && posterModel && !props['browser-default']:
			case isZoomable && fileType==='url' && posterModel:

				media = <Image
					{...props}
					model={posterModel}		
					isZoomable={isZoomable}
					ref={this.mediaRef}
					onLoad={this.onLoad}
					isLazyLoadable={isLazyLoadable}
					renderWidth={mediaDimensions.width}
					renderHeight={mediaDimensions.height}
					part="media"
					onError={this.onErrorCallback}		
				/>
				break;

			case fileType == "video":
			case fileType.startsWith('vimeo:'):
			case fileType.startsWith('youtube:'):

				media = <Video
					{...props}	
					baseNode={this.props.baseNode}
					isZoomable={isZoomable}
					ref={this.mediaRef}
					onLoad={this.onLoad}
					visible={isVisible}
					fileType={fileType}
					isLazyLoadable={isLazyLoadable}					
					renderWidth={dimensions.width}
					renderHeight={dimensions.height}
					src={src}

				/>				
				break;

			case fileType == "image":
				media = <Image
					{...props}				
					isZoomable={isZoomable}
					ref={this.mediaRef}
					onLoad={this.onLoad}
					isLazyLoadable={isLazyLoadable}
					renderWidth={mediaDimensions.width}
					renderHeight={mediaDimensions.height}
					part="media"
					onError={this.onErrorCallback}		
				/>
				break;

			// iframe component should go here
			case fileType == 'url':
				media = <Iframe
					{...props}
					loaded={this.state.loaded}
					onLoad={this.onLoad}
					ref={this.mediaRef}
					isLazyLoadable={isLazyLoadable}
					src={src}
				/>
				break;				
			default:

				media = <div
						ref={this.mediaRef}
						className="placeholder"
						part="media placeholder"
						width="1600"
						height="1000"
						draggable="true"
					>
						<svg
							preserveAspectRatio="none"
							part="placeholder-svg"
							viewBox="0 0 1600 1600"
							style={{
								display: 'block',
								width: '100%',
								height: '100%',
							}}
						>
							<rect part="placeholder-rect"></rect>
							<line part="placeholder-line" vector-effect="non-scaling-stroke" x1="0" y1="0" x2="1600" y2="1600" stroke="rgb(200,200,200)" stroke-width="1"></line>
						</svg>
				</div>
				break;

		}

		let slot = <slot key="media-slot-key" onSlotChange={this.onMediaSlotChange} name="custom-media">{media}</slot>
		const jsx = <Fragment key="media-item-fragment">
<style id="media-item">{`
:host {
	 ${this.state.forceRedraw ? `/* ${this.state.forceRedraw} */`: ''}
	${!adminMode && href && href !== '' ? `cursor: pointer;`: ''}
	display: inline-flex;
	vertical-align: bottom;
	flex-direction: column; 
	position: relative;

	width: var(--resize-parent-width, 100%);
	max-width: ${frameWidth || '100%'};
	${customFitHeight ? `--custom-fit-height: ${customFitHeight};` : ''}
	transform-origin: center center;
	margin: 0;
	padding: 0;
	z-index: var(--z-index, initial);    
}
 :host, * {
	box-sizing: border-box;
}
a {
	cursor: inherit;
}

${!adminMode  && isZoomable ? `[part*="media"]:active {
    opacity: 0.7;
}`: ''}

${!adminMode && href ? `:host(:active) {
    opacity: 0.7;
}`: ''}

figure {
	flex-direction: column;
	position: relative;
	display: flex;
	width: 100%;
	margin: 0;
	padding: 0;
	z-index: 2;
}

::slotted(figcaption){
	-webkit-user-select: text;	
	user-select: text;
	width: 100%;
}

:host([image-fit="fit"]) {
	--object-fit: contain;
}
:host([image-fit="fill"]) {
	--object-fit: cover;
}
:host([image-fit="stretch"]) {
	--object-fit: fill;
}

[part*="media"],
[part*="video"],
[part*="poster"] {
	object-fit: var(--object-fit, cover);
}

[part*="media"]:-webkit-full-screen,
[part*="media"]:fullscreen,
[part*="video"]:-webkit-full-screen,
[part*="video"]:fullscreen {
	object-fit: contain!important;
}


.sizing-frame {
	display: block;
	width: 100%;
	flex-grow: 1;
	height: 0;
	padding-bottom: var(--frame-padding, ${framePadding});
	position: relative;
}

.frame {
	display:contents;
}

/* for images and custom media */
::slotted([slot="custom-media"]),
[part*="media"] {
	object-position: center center;
	display: block;
	margin: 0;
	width: 100%;
	min-width: 100%;
	min-height: 100%;
	height: 100%;
	position: absolute;
	overflow: clip;
	overflow-clip-margin: content-box;
	inset: 0;
}

/*
	for URL fileType and default setup for iframe-videos
*/
iframe {
	opacity: 0;
	pointer-events: ${!adminMode && !this.props.href && !isZoomable ? 'auto': 'none'};
}

iframe.loaded {
	opacity: 1;
}

/* enable proper padding cropping for videos, in most scenarios */
.video-player-crop {
	all: inherit;
	filter: unset;
	will-change: unset;
	width: unset;
	height: unset;
	padding: 0;
	border-left-width: ${mediaDimensions.leftPad + mediaDimensions.leftBorder}px;
	border-right-width: ${mediaDimensions.rightPad + mediaDimensions.rightBorder}px;
	border-top-width: ${mediaDimensions.topPad + mediaDimensions.topBorder}px;
	border-bottom-width: ${mediaDimensions.bottomPad + mediaDimensions.bottomBorder}px;
	top: ${-mediaDimensions.topBorder}px;
	left: ${-mediaDimensions.leftBorder}px;
	right: ${-mediaDimensions.rightBorder}px;
	bottom: ${-mediaDimensions.bottomBorder}px;

	min-width: unset;
	min-height: unset;

	margin: auto;
	border-color: transparent;
	background: transparent;
}

/* for video filetype */
[part*="video"], [part*="poster"] {
	object-position: 50% 50%;	
	display: block;
	margin: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	inset: 0;
}

[part*="video"] iframe[part*="iframe"] {
	opacity: 1;
}

[part*="poster"] {
	object-fit: cover;
	z-index: 2;
	opacity: 0;
}
${props['browser-default'] ?
`[part*="poster-playing"],
[part*="poster-paused"]{
	pointer-events:none;
}
` :''}	
[part*="poster-stopped"]{
	opacity: 1;
}
${loading ? `[part*="media"] {
	opacity: 0;
}` : ''}

${adminMode ?`
::slotted(video[slot="custom-media"]),
::slotted(iframe[slot="custom-media"]){
	pointer-events:none;
}

[part*="media"] iframe {
	pointer-events:none!important;
}

` : `
[part*="media"].image-zoom {
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
}	
`}

${pageInfo.isEditing ? `
:host, * {
	-webkit-user-select: text;
	user-select: text;	
}
`: ''}

				`}</style>
			<UsesHost
				scrollContext={this.props.scrollContext}
				uses={ usesProps }
				key="media-host"
				visibility={ viewportIntersection }
				onLazyLoadChange={this.onlazyLoadChange}
				adminMode={adminMode}
				customElementMode={true}
				baseNode={baseNode}
			>
				<figure
					draggable={this.props.adminMode ? 'true': null}
					part="figure"
					ref={this.figureRef}
				>
					<div part="frame" className="frame">
					{this.props.href !== undefined ? 
						<a
							ref={this.sizingFrameRef}
							className="sizing-frame"
							part="link sizing-frame"
							href={this.props.href}
							rel={this.props.rel}
							target={this.props.target}
							data-tags={this.props['data-tags']}
						>{slot}</a> :
						<div ref={this.sizingFrameRef} className="sizing-frame" part="sizing-frame">{slot}</div>
					}</div>
					<slot name="caption"></slot>
				</figure>

			</UsesHost>
			{pageInfo.isEditing && adminMode && <MediaItemEditor
				{...this.props}
				zoomTemporarilyDisabled={this.state.zoomTemporarilyDisabled}

				fileType={fileType}
				limitBy={limitBy}
				width={width}
				height={height}
				rotation={rotation || 0}
				scale={scale}
				scrollContext={this.props.scrollContext}
				
				key={`media-item-editor-${this.uid}`}
				baseNode={baseNode}

				nativeMediaDimensions={this.state.nativeMediaDimensions}
				figCaptionElement={this.figCaptionRef.current}
				sizingFrameElement={this.sizingFrameRef.current}
				figureElement={this.figureRef.current}
				mediaElement={this.mediaRef.current}
				size={this.props.baseNode._size}
				uid={this.uid}
			/>}				
			</Fragment>;

		return createPortal(jsx, baseNode.shadowRoot)
	}

	onViewportIntersectionChange = data =>{

		if( this.state.viewportIntersection?.position !== data.position ){
			this.setState({
				isVisible: data.position === 'inside',
				viewportIntersection: data
			});
		} else if ( this.state.viewportIntersection.hasLayout !== data.hasLayout || this.state.viewportIntersection.visibility !== data.visibility ){
			this.setState({
				viewportIntersection: data
			});
		}
		
	}

	onLazyloadIntersectionChange = data => {

		const isLazyLoadable = data.position === 'inside';

		if( this.state.isLazyLoadable !== isLazyLoadable){
			this.setState({
				isLazyLoadable
			});
		}

	}


	onMediaSlotChange = (e)=>{

		let assignedNodes = e.target.assignedNodes();

		if( assignedNodes[0] ){
			this.setState({
				nativeMediaDimensions: {
					width: parseFloat(assignedNodes[0].getAttribute('width')) || null,
					height: parseFloat(assignedNodes[0].getAttribute('height')) || null
				},
				slottedMediaNode:assignedNodes[0]
			})

			this.mediaRef.current = assignedNodes[0];

		} else {
			this.setState({
				nativeMediaDimensions: {
					width: null,
					height: null,
				},				
				slottedMediaNode:null
			})
		}
		
	}

	bindMediaListeners = (el)=>{
		if( !el){
			return;
		}

		const width = parseFloat(el.getAttribute('width')) || null
		const height = parseFloat(el.getAttribute('height')) || null

		if(
			width && height && 
			this.state.nativeMediaDimensions.width !== width
			&& this.state.nativeMediaDimensions.height !== height
		) {
			this.setState({
				nativeMediaDimensions: {
					width,
					height
				}
			})
		}

		subscribe(el, 'elementResize', this.onMediaResize);
		resizeObserver.observe(el);

	}

	unbindMediaListeners = (el)=>{
		if( !el){
			return;
		}
		this.setState({
			nativeMediaDimensions: {
				width: null,
				height: null
			},
		})

		unsubscribe(el, 'elementResize', this.onMediaResize);
		resizeObserver.unobserve(el)
	
	}


	componentDidMount(){

		if ( helpers.isServer ){
			return;
		}

		// if we're in admin mode, the editor will handle it
		if( !this.props.adminMode){
			if( this.isZoomable() ){
				this.props.baseNode.classList.add('zoomable');
			} else {
				this.props.baseNode.classList.remove('zoomable');
			}

			if( this.props.href !== undefined){
				this.props.baseNode.classList.add('linked');	
			} else {
				this.props.baseNode.classList.remove('linked');
			}			
		}


		const src =this.props['dynamic-src'] || this.props.modelSrc || this.props.src;


		// trigger load if there's nothing to load
		if(this.props.hash ==='placeholder' && !src && !this.props.baseNode.hasAttribute('hash')){
			this.onLoad();
		}



		subscribe(this.props.baseNode, 'viewportIntersectionChange', this.onViewportIntersectionChange);
		subscribe(this.props.baseNode, 'lazyLoadIntersectionChange', this.onLazyloadIntersectionChange);
		subscribe(this.props.baseNode, 'elementResize', this.onResize);

		this.props.baseNode.addEventListener('click', this.handleClick);
		this.props.baseNode.addEventListener('dragstart', this.onDragStart)

		this.bindMediaListeners(this.mediaRef.current);
		this.lastMediaRef.current = this.mediaRef.current;

		resizeObserver.observe(this.props.baseNode);

	}

	componentWillUnmount(){
		if ( helpers.isServer ){
			return;
		}

		cancelAnimationFrame(this.itemResizeAfterModelChange);

		unsubscribe(this.props.baseNode, 'viewportIntersectionChange', this.onViewportIntersectionChange);
		unsubscribe(this.props.baseNode, 'lazyLoadIntersectionChange', this.onLazyloadIntersectionChange);
		unsubscribe(this.props.baseNode, 'elementResize', this.onResize);

		this.props.baseNode.removeEventListener('dragstart', this.onDragStart)
		this.props.baseNode.removeEventListener('click', this.handleClick);


		resizeObserver.unobserve(this.props.baseNode);
		this.unbindMediaListeners(this.mediaRef.current);

	}

	componentDidUpdate(prevProps, prevState){

		if(this.props.hash !== prevProps.hash || this.props.src !== prevProps.src){
			this.setState({
				nativeMediaDimensions: {
					width: null,
					height: null,
				},
			})
		}		

		const fileType = this.getFileType();
		if( 
			(fileType.startsWith('vimeo') || fileType.startsWith('youtube')) &&
			(
				prevProps.src !== this.props.src ||
				prevProps.autoplay !== this.props.autoplay ||
				prevProps['browser-default'] !== this.props['browser-default'] ||
				prevProps.loop !== this.props.loop ||
				prevProps.muted !== this.props.muted
			)
		){

			this.setState({
				loaded: false,
			});
		}

		if(
			this.props.imageSettings.image_zoom !== prevProps.imageSettings.image_zoom || 
			this.props['disable-zoom'] !== prevProps['disable-zoom'] ||
			this.state.zoomTemporarilyDisabled !== prevState.zoomTemporarilyDisabled
		){
			if( this.isZoomable() ){
				this.props.baseNode.classList.add('zoomable');
			} else {
				this.props.baseNode.classList.remove('zoomable');
			}
		}

		if( this.props.href !== prevProps.href ){
			if( this.props.href !== undefined ){
				this.props.baseNode.classList.add('linked');		
			} else {
				this.props.baseNode.classList.remove('linked');
			}
			
		}

		// if we just added an item load prompt to something, fire the load event to catch up
		if(
			this.props.itemLoad !== prevProps.itemLoad &&
			this.props.itemLoad &&
			this.state.loaded
		){
			this.onLoad();
		}


		if( this.props.itemResize && 

			// if this element is inside the light dom of a custom element but isn't slotted, do not trigger resize			
			!(this.props.baseNode.slot && !this.props.baseNode.assignedSlot) &&
			(
				(this.props.model && prevProps.model && prevProps.model.hash !== this.props.model.hash) ||
				(this.props.width !== prevProps.width) ||
				(this.props.height !== prevProps.height) || 
				(this.state.nativeMediaDimensions.width !== prevState.nativeMediaDimensions.width) ||
				(this.state.nativeMediaDimensions.height !== prevState.nativeMediaDimensions.height) ||
				(this.state.dimensions.width != prevState.dimensions.width) ||
				(this.state.dimensions.height != prevState.dimensions.height) ||
				(this.state.dimensions.padSize != prevState.dimensions.padSize) ||
				(this.state.mediaDimensions.padSize != prevState.mediaDimensions.padSize) 
			)
		){
			this.props.itemResize(this.props.baseNode._size, this.props.baseNode)	
		}

		if(prevProps.hash !== 'placeholder' && this.props.hash ==='placeholder'){
			this.onLoad();
		}



		if( this.lastMediaRef.current !== this.mediaRef.current ){
			this.unbindMediaListeners(this.lastMediaRef.current);
			this.bindMediaListeners(this.mediaRef.current)
			this.lastMediaRef.current = this.mediaRef.current
		}

	}

	//
	// Event listeners
	//

	onSetZoomDisabled = (data)=>{

	}

	onResize = data => {
		this.setState({
			dimensions: {...data}
		})
	}


	onMediaResize = data => {

		if(
			data.width !== this.state.mediaDimensions.width ||
			data.horizPadSize !== this.state.mediaDimensions.horizPadSize ||
			data.vertPadSize !== this.state.mediaDimensions.vertPadSize ||			
			data.height !== this.state.mediaDimensions.height
		){
			this.setState({
				mediaDimensions: {...data}
			})
		}
	}

	onDragStart = (e)=>{

		if( typeof this.props.itemDragStart === 'function'){
			this.props.itemDragStart(e);
		}

		const figCaption = this.props.baseNode.querySelector('figcaption');

		if(
			this.props.baseNode.contains(e.target) &&
			(
				(figCaption && !figCaption.contains(e.target)) ||
				!figCaption

			) &&
			this.props.adminMode &&
			this.props.pageInfo.isEditing 
		) {
			e.dataTransfer.setData("media-drag", "true");
		}

	}


	onLoad = (loadedDimensions)=>{

		this.setState((prevState)=>{
			if( loadedDimensions && (
				loadedDimensions.width !== prevState.nativeMediaDimensions.width ||
				loadedDimensions.height !== prevState.nativeMediaDimensions.height	
			) ){
				return {
					nativeMediaDimensions: {...loadedDimensions},
					loaded: true
				}
			} else {
				return {
					loaded: true
				}
			}
			
		},()=>{

			if( this.onloadCallback){
				this.onloadCallback(this.props.baseNode);
			}
			if( this.props.itemLoad ){
				this.props.itemLoad(this.props.baseNode)
			}			

		})
	}

}


MediaItem.defaultProps = {

	// these options should have default global options to read from if they are not defined on-item
	// if the global option is not defined they should default to the commented value 
	'scale': undefined, // '100%'
	'limit-by' : undefined, // 'width'

	'force-quick-view': false,

	'disable-zoom': false,
	'disable-scroll-animation': false,
	'width': undefined, 
	'height': undefined,
	'hash': 'placeholder',

	'src': undefined,
	'rotation': 0,

	model: null,
	'image-fit': 'fill',

	'play-on': 'click',
	autoplay: false,
	muted: false,
	loop: false,
	'browser-default': false,
	'playback-rate': 1.0
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateFrontendState: actions.updateFrontendState
	}, dispatch);
}

const ConnectedMediaItem = withPageInfo(connect(
	(state, ownProps) => {

		let imgHash = ownProps['dynamic-hash'] || ownProps.hash || null;
		let model = imgHash ? selectors.getMediaByHash(state)[imgHash] : null;

		if(ownProps.model) {
			// passing a model object will overrule a hash
			model = ownProps.model;
		}

		// this can be a hash or a src
		let posterHash = ownProps.poster || null;
		let posterModel = null;
		let modelSrc;

		if(ownProps.posterModel) {
			// passing a model object will overrule a hash
			posterModel = ownProps.posterModel;
		} else if( posterHash && !posterHash.includes?.('.') ){
			posterModel =selectors.getMediaByHash(state)[posterHash] || null;
		}

		return {
			posterModel,
			model,
			isMobile: state.frontendState.isMobile,			
			adminMode: state.frontendState.adminMode,
			imageSettings: {
				limit_by: 'width',
				scale: '100%',
				...state.siteDesign.images
			},
		};
	}, mapDispatchToProps
)(MediaItem));


const watchedAttributes = [

	// object props — should not show up as attributes
	'itemResize',
	'itemLoad',
	'itemDragStart',
	'model',

	// attributes that do not go into the crdt
	'dynamic-hash',
	'dynamic-filetype',
	'dynamic-src',

	// regular attributes
	'rotation',	
	'limit-by',
	'disable-zoom',
	'disable-scroll-animation',
	'scale',
	'hash',
	'src',
	'force-visible',

	// img attributes
	'alt',

	// link attributes
	'href',
	'rel',
	'target',
	'tag-text',

	// video attributes
	'autoplay',
	'muted',
	'loop',
	'poster',
	'play',
	'browser-default',
	'playback-rate',

	// video img attributes
	'image-fit',

	// used to override native media options
	// expose this for iframes, videos
	'width',
	'height',
]

register(ConnectedMediaItem, 'media-item', watchedAttributes) 

export default ConnectedMediaItem

