import { collapseOptions } from "../../../helpers";
import _ from 'lodash';

const onChangeCustom = (prevSettings, newSettings, backdropView)=>{

	const changes = {};
	for (const [key, setting] of Object.entries(newSettings) ) {
		if(prevSettings[key] != setting){
			changes[key] = setting
		}
	}

	for (const [name, value] of Object.entries(changes) ) {

		backdropView.Update(name, value);	
		
	}
}

const settings = {
	name: 'wave',
	options: [
		{
			labelName: "Image",
			name: "image",
			type: "image",
			value: '7e333180710e85119b159e6bf7711aed653bf5ee8339ed26c74cae1f031548bc'
		},
		{
			name: 'pageSplit',
			type: 'custom',
			value: null,
		},			
		{
			labelName: "Clip when scrolling",
			name: "clipScroll",
			type: "check-box",
			value: false,
		},
		{
			type: 'spacer'
		},			
		{
			labelName: "Waves",
			name: "ripples.on",
			type: "check-box",
			value: true,
		},
		{
			labelName: "Wave Speed",
			name: "ripples.noiseSpeed",
			type: "range-input",
			value: 15,
			min: 0,
			max: 20,
			step: 0.1,
			requirements: [
				{
					option: 'ripples.on',
					shouldBe: true
				}
			]
		},	
	]
}

settings.defaults = collapseOptions(settings.options);

// settings with no ui, used only for defaults
settings.defaults.image_url = "//static.cargo.site/assets/backdrop/default_1024.jpg";

export default settings;

export {onChangeCustom}
