import axios from 'axios';
import * as helpers from "@cargo/common/helpers";
import { store } from "../index"
import { actionTypes } from "../actions";

// only use auth on the frontend. SSR does not handle authorized requests.
if(!helpers.isServer) {

	// add OAuth token support to axios
	axios.interceptors.request.use( async (config) => {

		if(config.use_auth !== false) {

			const state = store?.getState();

			if(
				config.headers.Authorization === undefined && // if no Auth was already set
				state &&
				state.auth.data && // and we have auth data
				state.auth.data.access_token // and a token
			) {

				config.headers['Authorization'] = `Bearer ${state.auth.data.access_token}`;

			}

		}

		return config;

	});

	// Handle expired OAuth token
	axios.interceptors.response.use(undefined, error => {

		if(error.response?.status === 403) {

			// unauthorized request. Assume login credentials are not valid (anymore);
			store.dispatch({
				type: actionTypes.AUTHENTICATE_USER_REJECTED,
				payload: {}
			});

		}

		return Promise.reject(error);

	});

}