import { Component } from 'preact'
import _ from 'lodash';

class NotificationPage extends Component {

	constructor(props) {
		super(props);
	}

	render = () => {

		return (
			<>
			<style>
				{`
					body {
						letter-spacing: 0;
						padding: 30px;
						color: rgba(0,0,0,.85);
						font-size: 18px;
						line-height: 1;
						font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, "Sans Serif", Icons;
					}
				`}
			</style>
			{this.props.message}
			</>
		)

	}

}

export default NotificationPage
