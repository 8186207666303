import { h, cloneElement, createElement, isValidElement, render, hydrate, options as preactOptions, Component, createRef, Fragment } from 'preact';
import { createPortal, PureComponent } from 'preact/compat';
import { listenToVnodeDiff } from '../../helpers';

import _ from 'lodash';

import * as helpers from "@cargo/common/helpers"
import { Provider } from 'react-redux';
import { withScroll } from './scroll-element';

import { subscribe, unsubscribe, dispatch } from '../../customEvents';

function register(Component, tagName, propNames, options) {

	if(helpers.isServer) {
		return;
	}

	propNames =
		propNames ||
		Component.observedAttributes ||
		Object.keys(Component.propTypes || {});	

	function PreactElement() {
		
		const inst = Reflect.construct(HTMLElement, [], PreactElement);

		inst.__observedAttributes = propNames;

		inst._editorProperties = {};
		inst._vdomComponent = Component;
		inst.attachShadow({
			mode: 'open' ,
			delegatesFocus: options && options.delegatesFocus ,
		});

		// component has slots for user-defined	elements
		inst._customSlots = options && options.customSlots ? options.customSlots : [];
		inst._replaceChild = 
			options && options.replaceChild ;

		inst._props = {};

		inst._uid = _.uniqueId();
		inst._initialMount = true;

		const onMutation = (mutationList)=>{

			// HTML Collections are live objects so they don't change even if their contents change. Only
			// memoize them for this single callback
			const memoizedHTMLCollections = new WeakMap();

			mutationList.forEach(mutation => {
			    switch(mutation.type) {
					case 'childList':	

						if (inst._customElementComponent){

							if(!memoizedHTMLCollections.has(inst.children)) {
								// Converting this collection to an Array can happen many times in the same
								// callback causing significant overhead. Cache this.
								memoizedHTMLCollections.set(inst.children, Array.from(inst.children))
							}

							inst._customElementComponent.setState({
								childArray: memoizedHTMLCollections.get(inst.children)
							});

						}
						break;

					case "attributes":

						let newValue = mutation.target.getAttribute(mutation.attributeName);

						if( mutation.attributeName !== 'style' && mutation.attributeName !== 'class' && newValue !== inst[mutation.attributeName]){

							if( newValue == null ){
								newValue = undefined;
							}

							if( inst.__observedAttributes?.indexOf(mutation.attributeName) > -1){
								// this path triggers the get/setters for observed attributes
								inst[mutation.attributeName] = newValue;

							} else if( inst._customElementComponent ){

								inst._customElementComponent.setState((prevState)=>{
									const baseNodeProps = {...prevState.baseNodeProps};
									const prevValue = baseNodeProps[mutation.attributeName];

									if( prevValue !== newValue ){


										baseNodeProps[mutation.attributeName] = newValue;

										return {
											baseNodeProps
										}
									}

								})

							} 
							
							inst._props[mutation.attributeName] = newValue;
						}
						break;
				}
			});

		};

		inst.__observer = new MutationObserver(onMutation);
		inst.flushMutationQueue = function(){
			let mutations = inst.__observer.takeRecords();
			if (mutations.length > 0) {
				onMutation(mutations);
			}
		}

		propNames.forEach(attributeName=>{

			const camelCasedAttribute = _.camelCase(attributeName);

			const get = function(){
				if( inst._customElementComponent ){
					return inst._customElementComponent.state.baseNodeProps[attributeName]	
				} else {
					return inst._props?.[attributeName] ?? undefined;
				}
			}

			const set = function(v) {
				
				let propValue = v;

				propValue = (propValue == null || propValue === '') ? undefined : propValue;
				propValue = propValue === 'true' ? true : propValue;
				propValue = propValue === 'false' ? false : propValue;

				let isStringAttribute = false;
				const type = typeof propValue;

				if (
					propValue == null ||
					type === 'string' ||
					type === 'boolean' ||
					type === 'number'
				) {
					isStringAttribute = true;
				}


				if( inst._customElementComponent ){

					inst._customElementComponent.setState((prevState)=>{

						const baseNodeProps = {...prevState.baseNodeProps};
						const prevValue = baseNodeProps[attributeName];

						if( prevValue !== propValue ){

							baseNodeProps[attributeName] = propValue;

							return {
								baseNodeProps
							}					
						}

					})

				} 
				
				inst._props[attributeName] = propValue;

				if (isStringAttribute){

					const lowercaseName = attributeName.toLowerCase()
					if ( v === '' || v=== undefined || v== null ){

						if( inst._customElementComponent.props.baseNode.hasAttribute(lowercaseName) && lowercaseName !== 'tag-text'){
							inst.removeAttribute(lowercaseName);
						}

					} else if( v !== '' && v !== inst.getAttribute(lowercaseName)){
						inst.setAttribute(lowercaseName, v);
					}
				}				
			}

			Object.defineProperty(inst, attributeName, {
				get,
				set,
				configurable: true
			});

			Object.defineProperty(inst, camelCasedAttribute, {
				get,
				set,
				configurable: true
			});

		})

		return inst;

	}

	PreactElement.prototype = Object.create(HTMLElement.prototype);
	PreactElement.prototype.constructor = PreactElement;
	PreactElement.prototype.connectedCallback = connectedCallback;
	PreactElement.prototype.disconnectedCallback = disconnectedCallback;
	PreactElement.prototype.adoptedCallback = adoptedCallback;

	const customElementTagName = tagName || Component.tagName || Component.displayName || Component.name;

	if( options?.extends ){
		return customElements.get(customElementTagName) || customElements.define(
			customElementTagName,
			PreactElement,
			{ extends: options.extends }
		);
	} else {
		return customElements.get(customElementTagName) || customElements.define(
			customElementTagName,
			PreactElement
		);		
	}

}

const missedConnectedCallbacks = [];


function connectedCallback() {

	// no vdom creation for elements mounted inside tester iframe
	// or for special cases where they're just being used for css
	if ( this.ownerDocument?.defaultView?.frameElement?.id == 'contentEditableStyleTester' || this.hasAttribute('no-component') ){
		return;
	}



	// make sure children are initialized first
	// commented out to fix visibility issue when switching galleries
	// uncomment if jumping galleries come back
	// this.querySelectorAll('*').forEach(node => {
	// 	if(node.connectedCallback) {
	// 		node.connectedCallback();
	// 	}
	// });

	const isTextIcon = this.nodeName == 'TEXT-ICON';



	if( this._initialMount ){

		const style = document.createElement('style');
		style.id = 'space-filler';			

		if( isTextIcon ){
			style.innerHTML = `:host {
				height: 1.2em;
				width: 1.2em;
				contain: layout;
				cursor: text;

				margin-top: -1em;
				margin-bottom: -.25em;
				display: inline-block;
				vertical-align: baseline;
			
				position: relative;
			}`
		} else {
			style.innerText = ':host { display: block; opacity: 0; }'
		}
		
		this.shadowRoot.appendChild(style);
	} 

	delete this._initialMount

	this.__observer.observe(this, {attributes: true, childList: true, subtree: false});

	const evtData = {
		element: this,
		portalHost: null
	}

	if( isTextIcon ){
		evtData.component = <CustomElement
			key={'custom-element-'+this._uid}
			baseNode={this}
			customElementComponent={this._vdomComponent}
			observedAttributes={this.__observedAttributes}
		/>;
	} else {
		evtData.component = <CustomElementWithScroll
			key={'custom-element-'+this._uid}
			baseNode={this}
			customElementComponent={this._vdomComponent}
			observedAttributes={this.__observedAttributes}
		/>;
	}


	dispatch(this, 'custom-element-connected', evtData);

	this._portalHost = evtData.portalHost;

	if(this._portalHost === null) {
		missedConnectedCallbacks.push(this);
	}

}



function adoptedCallback(){

}

function disconnectedCallback() {

	if ( this.ownerDocument?.defaultView?.frameElement?.id == 'contentEditableStyleTester' || this.hasAttribute('no-component') ){
		return;
	}

	this.__observer.disconnect();

	if( this._portalHost ){
		dispatch(this._portalHost, 'custom-element-disconnected', {
			component: null,
			element: this
		});

	}

	this._portalHost = null;
	this._selectState = null;
}

class CustomElement extends Component {
	constructor(props){
		super(props);

		const initialProps = this.getPropsFromAttributes();

		this.state={
			baseNodeProps : {...initialProps, ...props.baseNode._props},
			childArray: Array.from(props.baseNode.children)
		}

		props.baseNode._customElementComponent = this;
		
	}

	getPropsFromAttributes = ()=>{
		const a = this.props.baseNode.attributes;
		let i = 0;

		const initialProps = {};

		for (i = a.length; i--; ) {

			let val = a[i].value === 'true' ? true : a[i].value;
			val = val === 'false' ? false : val;

			// props[_.camelCase(a[i].name)] = val;
			initialProps[a[i].name] = val;

		}

		// return initialProps;
		return {...initialProps, ...this.props.baseNode._props}
	}

	componentDidMount(){

		if(helpers.isServer){
			return
		}

		// it's possible to manipulate the attributes of an element while it's unmounted, so let's check here:
		const initialProps = this.getPropsFromAttributes();

		this.setState((prevState)=>{
			if( !_.isEqual(initialProps, this.state.baseNodeProps) ){
				return {
					baseNodeProps: initialProps
				}
			} else {
				return null
			}
		})

		subscribe(this.props.baseNode, 'custom-element-connected', this.refreshAttributes)
	}

	componentWillUnmount(){
		unsubscribe(this.props.baseNode, 'custom-element-connected', this.refreshAttributes)					
	}


	refreshAttributes = (evt)=>{
		if( evt.element == this.props.baseNode){

			const initialProps = this.getPropsFromAttributes();

			this.setState((prevState)=>{
				if( !_.isEqual(initialProps, this.state.baseNodeProps) ){
					return {
						baseNodeProps: initialProps
					}
				} else {
					return null
				}
			})

		}
	}

	render(props, state){
		const {
			baseNodeProps,
			childArray
		} = state;

		return createElement(this.props.customElementComponent, {
			...props,
			...baseNodeProps,
			childArray
		})
	}

}

const CustomElementWithScroll = withScroll(CustomElement);

class CustomElementHost extends PureComponent {
	
	constructor(props){

		super(props);
		
		this.state={
			portals: []
		}

		if( helpers.isServer){
			return;
		}

		if(isValidElement(this.props.portalHost)) {

			// the portal host is a preact vnode. Wait for it to mount
			this.stopListeningToVnodeDiff = listenToVnodeDiff(this.props.portalHost, this.onPortalHostMounted);

		} else if(this.props.portalHost instanceof Node){

			// the portal host is a raw DOM node, use it immediately
			this.onPortalHostMounted(this.props.portalHost);

		}


	}

	render(props) {

		return 	<Fragment key="portal-map">
			{this.state.portals.map(([component, el], index) => {
				if ( component && el ){
					return createPortal(component, el)
				} else {
					return createPortal(null, null);
				}
				
			})}
		</Fragment>
	}

	addPortal = data => {

		if( data.portalHost ){
			return;
		}

		const {
			component,
			element
		} = data;

		if( !component || !element){
			return;
		}

		data.portalHost = this.portalHostElement;
		let refreshPortal = false;

		this.setState(prevState => {
			
			const existingPortalIndex = prevState.portals.findIndex(([component, el]) => el === element && component !== null);
			const newPortals = [...prevState.portals];

			if(existingPortalIndex === -1) {
				newPortals.push([component, element]);
			} else {
				newPortals[existingPortalIndex] = [null, null];
				refreshPortal = true;
			}

			return {
				portals: newPortals
			}	

		}, ()=>{

			if( refreshPortal){
				this.addPortal(data)
			}
		});

	}

	removePortal = data => {

		if(!data.element){
			return;
		}

		this.setState(prevState=>{
			
			const portals = [...prevState.portals];
			const existingPortalIndex = portals.findIndex(([component, el]) => el === data.element);	

			if(existingPortalIndex !== -1) {

				 portals[existingPortalIndex][1] = null;

				return {
					portals: [...portals]
				}

			} else {

				return null;
			}
			
		}, ()=>{


		});

	}

	onElementHostConnected = data => {

		const newHost = data.element
		const toReconnect = [];
	
		this.setState(prevState => {
			
			const portals = [...prevState.portals];

			portals.forEach(([component, el], index)=>{
				if( newHost.contains(el)){
					toReconnect.push(el);
					portals[index] = [null, null];
				}
			});

			return {
				portals
			}
			
		}, () => {
			
			toReconnect.forEach(el=>{
				el.connectedCallback();
			});

		})

	}

	onPortalHostMounted = element => {

		if(this.portalHostElement !== element) {

			this.portalHostElement = element;

			dispatch(this.portalHostElement, 'element-host-connected', {
				element: this.portalHostElement
			});

			subscribe(this.portalHostElement, 'custom-element-connected', this.addPortal);
			subscribe(this.portalHostElement, 'custom-element-disconnected', this.removePortal);
			subscribe(this.portalHostElement, 'element-host-connected', this.onElementHostConnected);

		}

		// grab all children that got their connectedCallback before we mounted
		const childrenToConnect = missedConnectedCallbacks.filter(el => this.portalHostElement.contains(el));

		// re-run connectedCallback on them
		childrenToConnect.forEach(child => child.connectedCallback());

		// pull them from the queue
		_.pullAll(missedConnectedCallbacks, childrenToConnect);

	}

	componentDidMount(){

	}

	componentDidUpdate(prevProps, prevState){
		
		if( helpers.isServer){
			return;
		}

		this.state.portals.forEach(portal=>{
			if( portal[1]?.shadowRoot?.children){
				const fillerStyle = Array.from(portal[1].shadowRoot.children).find(child=>child.id==='space-filler');
				if( fillerStyle){
					fillerStyle.remove();
				}
			}
		})

	}

	componentWillUnmount(){
		
		if( helpers.isServer){
			return;
		}

		if( this.portalHostElement ){
			unsubscribe(this.portalHostElement, 'element-host-connected', this.onElementHostConnected);
			unsubscribe(this.portalHostElement, 'custom-element-disconnected', this.removePortal);
			unsubscribe(this.portalHostElement, 'custom-element-connected', this.addPortal);
			delete this.portalHostElement;
		}

		this.stopListeningToVnodeDiff?.();

	}
}

export default register;
export {CustomElementHost};

