const listeners = new Map();
if(typeof window !== 'undefined') {
	window.listeners = listeners;
}

export const dispatch = (element, topic, data, options = {}) => {

	let parent = element;

	while(parent) {

		if(
			listeners.has(parent)
			&& listeners.get(parent).has(topic)
		) {
			// run callbacks for this topic
			listeners.get(parent).get(topic).forEach(cb => cb(data))
		}

		if(options.bubbles === false) {
			break;
		}

		parent = parent.parentNode || (parent.nodeType === Node.DOCUMENT_FRAGMENT_NODE && parent.host);

	}

}

export const subscribe = (element, topic, callback) => {

	if(!element) {
		console.error('Unable to subscribe to non-existing element');
		return;
	}

	if(!listeners.has(element)) {
		listeners.set(element, new Map());
	}

	const existingTopics = listeners.get(element);

	if(!existingTopics.has(topic)) {
		existingTopics.set(topic, [])
	}

	const existingCallbacks = existingTopics.get(topic);

	if(!existingCallbacks.includes(callback)) {
		existingCallbacks.push(callback);
	}

}

export const unsubscribe = (element, topic, callback) => {

	// element is not subscribed at all.
	if(!listeners.has(element)) {
		return;
	}

	const existingTopics = listeners.get(element);

	if(!existingTopics.has(topic)) {
		// element isn't subscribed to this topic. Nothing to do
		return;
	}

	// no callback specfied, kill the entire topic
	if(!callback) {
		existingTopics.delete(topic);
		return;
	}

	// we have a topic and callback supplied. Kill only the specified callback from the topic
	existingTopics.set(topic, existingTopics.get(topic).filter(cb => cb !== callback));

	// No callbacks for this topic? Delete it
	if(existingTopics.get(topic).length === 0) {
		existingTopics.delete(topic);
	}

	// no topics for this el? Delete it
	if(existingTopics.size === 0) {
		listeners.delete(element);
	}

}