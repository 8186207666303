import { collapseOptions } from "../../../helpers";
import _ from 'lodash';
import tinycolor from 'tinycolor2';

// "transition_time": 4,
// "transition_alpha": 76,
// "zoom": 14,
// "cycle_images": true,
// "shuffle": true,
// "morph_size": 85,
// "morph_speed": 15,
// "small_warp": 40,
// "amplitude": 22,
// "phase": 95,
// "jitter": 18,
// "color": "rgba(255, 255, 255, 0)",
// "hex": "#ffffff",
// "alpha": "0",
// "blend": "MULTIPLY",
// "requires_webgl": "true",
// "image": "/_jsapps/backdrop/morphovision/morph_default.jpeg",

const onChangeCustom = (prevSettings, newSettings, backdropView)=>{

	const changes = {};
	for (const [key, setting] of Object.entries(newSettings) ) {
		if(prevSettings[key] != setting){
			changes[key] = setting
		}
	}

	for (const [name, value] of Object.entries(changes) ) {

		backdropView.Update(name, value);	
		
	}
}

const settings = {
	name: 'morphovision',
	options: [
		{
			labelName: "Image",
			name: "image",
			type: "image",
			value: '7e333180710e85119b159e6bf7711aed653bf5ee8339ed26c74cae1f031548bc'
		},
		{
			type: 'spacer',
		},
		{
			name: 'pageSplit',
			type: 'custom',
			value: null,
		},			
		{
			labelName: "Clip when scrolling",
			name: "clipScroll",
			type: "check-box",
			value: false,
		},
		{
			type: 'spacer'
		},			
		{
			labelName: "Transition Duration",
			name: "transition_time",
			type: "range-input",
			value: 4,
			suffix: ' sec',
			min: 0.5,
			max: 10,
			step: 0.1
		},
		{
			labelName: "Intensity",
			name: "transition_alpha",
			type: "range-input",
			value: 76,
			suffix: '%',
			min: 10,
			max: 100,
			step: 1
		},
		{
			labelName: "Zoom",
			name: "zoom",
			type: "range-input",
			value: 14,
			suffix: '%',
			min: -100,
			max: 100,
			step: 1
		},
		{
			labelName: "Cycle Images",
			name: "cycle_images",
			type: "check-box",
			value: true,
		},
		{
			labelName: "Randomize Order",
			name: "shuffle",
			type: "check-box",
			value: true,

			renderWhenDisabled: true,
			requirements: [
				{
					option: 'cycle_images',
					shouldBe: true
				}
			]	
		},
		{
			type: 'spacer'
		},
		{
			labelName: "Morph Size",
			name: "morph_size",
			type: "range-input",
			value: 85,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1
		},
		{
			labelName: "Morph Movement",
			name: "morph_speed",
			type: "range-input",
			value: 15,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1
		},
		{
			labelName: "Morph Amplitude",
			name: "amplitude",
			type: "range-input",
			value: 22,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1
		},
		{
			labelName: "Phase Shift",
			name: "phase",
			type: "range-input",
			value: 95,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1
		},
		{
			labelName: "Movement Jitter",
			name: "jitter",
			type: "range-input",
			value: 18,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1
		},
		{
			type: 'spacer'
		},
		{
			labelName: "Image Warp",
			name: "small_warp",
			type: "range-input",
			value: 40,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1
		},
		{
			type: 'spacer'
		},
		{
			labelName: "Overlay Color",
			name: "color",
			type: "color",
			value: '#33333300',
		},
		{
			labelName: undefined,
			name: "blend",
			type: "radio",
			value: "MULTIPLY",
			values: [
				{
					labelName: 'Normal',
					value: 'NORMAL'
				},
				{
					labelName: 'Screen',
					value: 'SCREEN'
				},
				{
					labelName: 'Multiply',
					value: 'MULTIPLY'
				}
			],
		},
	]
}
settings.transformChanges= (settings, changes)=>{
	
	if( changes.hasOwnProperty("color") && changes?.color === null ){
		changes.color = 'rgba(0,0,0,0)';
	}

	if(changes.color) {
		const color = tinycolor(changes.color);

		changes['hex'] = color.toHexString();
		changes['alpha'] = color.getAlpha();
	}

	return changes;
}
settings.defaults = collapseOptions(settings.options);

// settings with no ui, used only for defaults
settings.defaults.image_url = "/_jsapps/backdrop/morphovision/morph_default.jpeg";

export default settings;

export {onChangeCustom}
