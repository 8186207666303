import { collapseOptions } from "../../../helpers";
import _ from 'lodash';
import tinycolor from "tinycolor2";

// 	"dot_scale": 53,
// 	"dot_gain": 48,
// 	"undercolor_removal": 95,
// 	"c_rand_offset_x": "0.89148018555716",
// 	"c_rand_offset_y": "-0.034963104873896",
// 	"m_rand_offset_x": "-0.45742203574628",
// 	"m_rand_offset_y": "-0.1833441699855",
// 	"y_rand_offset_x": "0.60032634017989",
// 	"y_rand_offset_y": "0.83121883729473",
// 	"color_shift": 94,
// 	"cyan": true,
// 	"cyan_halftone": true,
// 	"c_opacity": 70,
// 	"c_rotation": 140,
// 	"c_rot_speed": -1,
// 	"magenta": true,
// 	"magenta_halftone": true,
// 	"m_opacity": 69,
// 	"m_rotation": 42,
// 	"m_rot_speed": 1,
// 	"yellow": true,
// 	"yellow_halftone": true,
// 	"y_opacity": 99,
// 	"y_rotation": 59,
// 	"y_rot_speed": -1,
// 	"black": true,
// 	"black_halftone": false,
// 	"k_opacity": 100,
// 	"k_rotation": 91,
// 	"k_rot_speed": -1,
// 	"color": "rgba(51,51,51,0)",
// 	"hex": "#333333",
// 	"alpha": "0",
// 	"overlay_type": "multiply_color",
// 	"mouse_interaction": true,
// 	"image":'',
// 	"image_url": "/_jsapps/backdrop/default_256.jpeg",
// 	"hires_image_url": "/_jsapps/backdrop/default_1024.jpeg",
// 	"image_size": 256,
// 	"hires_image_size": 1536,
// 
// 	"c_direction": 0,
// 	"k_direction": 90,
// 	"m_direction": 30,
// 	"scroll_interaction": true,
// 	"size": "cover",
// 	"y_direction": 60

const onChangeCustom = (prevSettings, newSettings, backdropView)=>{

	const changes = {};
	for (const [key, setting] of Object.entries(newSettings) ) {
		if(prevSettings[key] != setting){
			changes[key] = setting
		}
	}

	const delayed = [];

	for (const [name, value] of Object.entries(changes) ) {
		backdropView.Update(name, value);	
	}
}

const settings = {
	name: 'halftone',
	options: [
		{
			labelName: "Image",
			name: "image",
			type: "image",
			value: '7e333180710e85119b159e6bf7711aed653bf5ee8339ed26c74cae1f031548bc'
		},
		{
			type: 'spacer',
		},
		{
			name: 'pageSplit',
			type: 'custom',
			value: null,
		},		
		{
			labelName: "Clip when scrolling",
			name: "clipScroll",
			type: "check-box",
			value: false,
		},
		{
			type: 'spacer'
		},			
		{
			labelName: "Screen Size",
			name: "dot_scale",
			type: "range-input",
			value: 53,
			suffix: '%',
			min: 0,
			max: 100,
			step: 0.5
		},
		{
			labelName: "Dot Gain",
			name: "dot_gain",
			type: "range-input",
			value: 48,
			suffix: '%',
			min: -100,
			max: 200,
			step: 1
		},
		{
			labelName: "Under-Color Removal",
			name: "dot_gain",
			type: "range-input",
			value: 95,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1
		},
		{
			labelName: "Registration Accuracy",
			name: "color_offset",
			type: "range-input",
			value: 94,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1
		},
		{
			type: 'spacer'
		},
			{
				labelName: "Cyan",
				name: "cyan",
				type: "check-box",
				value: true
			},
				{
					labelName: "Halftone",
					name: "cyan_halftone",
					type: "check-box",
					value: true,
					requirements: [
						{
							option: 'cyan',
							shouldBe: true
						}
					]
				},
					{
						labelName: "Opacity",
						name: "c_opacity",
						type: "range-input",
						value: 70,
						suffix: '%',
						min: 0,
						max: 100,
						step: 1,
						requirements: [
							{
								option: 'cyan',
								shouldBe: true
							}
						]
					},
					{
						labelName: "Rotation",
						name: "c_rotation",
						type: "range-input",
						value: 140,
						suffix: '°',
						min: 0,
						max: 360,
						step: 1,
						requirements: [
							{
								option: 'cyan',
								shouldBe: true
							},
							{
								option: 'cyan_halftone',
								shouldBe: true
							}
						]
					},
					{
						labelName: "Rotation Speed",
						name: "c_rot_speed",
						type: "range-input",
						value: -1,
						suffix: '°/sec',
						min: -10,
						max: 10,
						step: 0.5,
						requirements: [
							{
								option: 'cyan',
								shouldBe: true
							},
							{
								option: 'cyan_halftone',
								shouldBe: true
							}
						]
					},
		{
			type: 'spacer'
		},
			{
				labelName: "Magenta",
				name: "magenta",
				type: "check-box",
				value: true
			},
				{
					labelName: "Halftone",
					name: "magenta_halftone",
					type: "check-box",
					value: true,
					requirements: [
						{
							option: 'magenta',
							shouldBe: true
						}
					]
				},
					{
						labelName: "Opacity",
						name: "m_opacity",
						type: "range-input",
						value: 69,
						suffix: '%',
						min: 0,
						max: 100,
						step: 1,
						requirements: [
							{
								option: 'magenta',
								shouldBe: true
							}
						]
					},
					{
						labelName: "Rotation",
						name: "m_rotation",
						type: "range-input",
						value: 42,
						suffix: '°',
						min: 0,
						max: 360,
						step: 1,
						requirements: [
							{
								option: 'magenta',
								shouldBe: true
							},
							{
								option: 'magenta_halftone',
								shouldBe: true
							}
						]
					},
					{
						labelName: "Rotation Speed",
						name: "m_rot_speed",
						type: "range-input",
						value: 1,
						suffix: '°/sec',
						min: -10,
						max: 10,
						step: 0.5,
						requirements: [
							{
								option: 'magenta',
								shouldBe: true
							},
							{
								option: 'magenta_halftone',
								shouldBe: true
							}
						]
					},
		{
			type: 'spacer'
		},
			{
				labelName: "Yellow",
				name: "yellow",
				type: "check-box",
				value: true
			},
				{
					labelName: "Halftone",
					name: "yellow_halftone",
					type: "check-box",
					value: true,
					requirements: [
						{
							option: 'yellow',
							shouldBe: true
						}
					]
				},
					{
						labelName: "Opacity",
						name: "y_opacity",
						type: "range-input",
						value: 99,
						suffix: '%',
						min: 0,
						max: 100,
						step: 1,
						requirements: [
							{
								option: 'yellow',
								shouldBe: true
							}
						]
					},
					{
						labelName: "Rotation",
						name: "y_rotation",
						type: "range-input",
						value: 59,
						suffix: '°',
						min: 0,
						max: 360,
						step: 1,
						requirements: [
							{
								option: 'yellow',
								shouldBe: true
							},
							{
								option: 'yellow_halftone',
								shouldBe: true
							}
						]
					},
					{
						labelName: "Rotation Speed",
						name: "y_rot_speed",
						type: "range-input",
						value: -1,
						suffix: '°/sec',
						min: -10,
						max: 10,
						step: 0.5,
						requirements: [
							{
								option: 'yellow',
								shouldBe: true
							},
							{
								option: 'yellow_halftone',
								shouldBe: true
							}
						]
					},
		{
			type: 'spacer'
		},
			{
				labelName: "Black",
				name: "black",
				type: "check-box",
				value: true
			},
				{
					labelName: "Halftone",
					name: "black_halftone",
					type: "check-box",
					value: true,
					requirements: [
						{
							option: 'black',
							shouldBe: true
						}
					]
				},
					{
						labelName: "Opacity",
						name: "k_opacity",
						type: "range-input",
						value: 100,
						suffix: '%',
						min: 0,
						max: 100,
						step: 1,
						requirements: [
							{
								option: 'black',
								shouldBe: true
							}
						]
					},
					{
						labelName: "Rotation",
						name: "k_rotation",
						type: "range-input",
						value: 45,
						suffix: '°',
						min: 0,
						max: 360,
						step: 1,
						requirements: [
							{
								option: 'black',
								shouldBe: true
							},
							{
								option: 'black_halftone',
								shouldBe: true
							}
						]
					},
					{
						labelName: "Rotation Speed",
						name: "k_rot_speed",
						type: "range-input",
						value: 0,
						suffix: '°/sec',
						min: -10,
						max: 10,
						step: 0.5,
						requirements: [
							{
								option: 'black',
								shouldBe: true
							},
							{
								option: 'black_halftone',
								shouldBe: true
							}
						]
					},
		{
			type: 'spacer'
		},
		{
			labelName: "Overlay Color",
			name: "color",
			type: "color",
			value: '#33333300',
		},
		{
			labelName: undefined,
			name: "overlay_type",
			type: "radio",
			value: "multiply_color",
			values: [
				{
					labelName: 'Normal',
					value: 'normal_color'
				},
				{
					labelName: 'Screen',
					value: 'screen_color'
				},
				{
					labelName: 'Multiply',
					value: 'multiply_color'
				}
			],
		},
		{
			labelName: "Mouse Interaction",
			name: "mouse_interaction",
			type: "check-box",
			value: true,
		},
	]
}

settings.transformChanges= (settings, changes)=>{

	if( changes.hasOwnProperty("color") && changes?.color === null ){
		changes.color = 'rgba(0,0,0,0)';
	}

	if(changes.color) {
		const color = tinycolor(changes.color);

		changes['hex'] = color.toHexString();
		changes['alpha'] = color.getAlpha();
	}

	return changes;
}

settings.customShuffle = (settings)=>{

	settings.overlay_type = Math.random() > .5 ? 'multiply_color' : 'screen_color';

	let random = Math.floor(Math.random() *4);
	switch (random){
		case 0:
			settings.black = true;
			settings.c_opacity = Math.max(75, settings.c_opacity);
			break;

		case 1:
			settings.cyan = true;
			settings.c_opacity = Math.max(75, settings.c_opacity);
			break;

		// if picking yellow, put black in there
		case 2:
			settings.yellow = true;
			settings.y_opacity = Math.max(100, settings.y_opacity);

			settings.black = true;
			settings.c_opacity = Math.max(50, settings.c_opacity);

			break;

		case 3:
			settings.magenta = true;
			settings.m_opacity = Math.max(75, settings.m_opacity);
			break;
	}
	
	

	return settings;
}



settings.defaults = collapseOptions(settings.options);

// settings with no ui, used only for defaults
settings.defaults.image_url = "//static.cargo.site/assets/backdrop/default_1024.jpg";

export default settings;

export {onChangeCustom}
