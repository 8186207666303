export const overlayDefaults = {
	closeOnClickout: false,
	closeOnNavigate: false,
	animateOnOpen: {
        fade: false,
        speed: 0,
        slideAmount: 0,
        slideDirection: 'default',
        wipeShape: 'default',
        easing: 'linear',
        scaleAmount: 0,
        rotateAmount: 0,
    },
	animateOnClose: {
        fade: false,
        speed: 0,
        slideAmount: 0,
        slideDirection: 'default',
        wipeShape: 'default',
        easing: 'linear',
        scaleAmount: 0,
        rotateAmount: 0,
    }
}

export default {overlayDefaults};