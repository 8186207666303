import { loadCSS } from '../util';

const API = 'https://fonts.googleapis.com/css?family=';
const fullGoogleFVDarr = ['100','100italic','200','200italic','300','300italic','400','400italic','500','500italic','600','600italic','700','700italic','800','800italic','900','900italic'];

export default (globalConfig, families) => {

	return new Promise((resolve, reject) => {

		if(!families || families.length === 0) {
			// nothing to load
			return resolve();
		}


		if(globalConfig.context === "server") {
			// nothing to load. Google fonts need to be loaded by the client
			// as the API sniffs the user agent to determine what font files to load
			return reject('Google fonts should only be loaded by the client');
		}

		// load the sheet
		const familyUrl = families.map(family => 
			family.indexOf(':') === -1 
			// load all variants
			? family + ':' + fullGoogleFVDarr.join(',') 
			// or just the ones already specified
			: family
		)
			// insert separator
			.join('%7C')
			// make string web safe
			.replace(/ /g, '+');

		const preloadSpans = families.map(family => {
			const span = globalConfig.context.document.createElement('span');
			span.style = `display:block;position:absolute;top:-9999px;left:-9999px;
			font-size:300px;width:auto;height:auto;line-height:normal;margin:0;
			padding:0;font-variant:normal;white-space:nowrap;font-family:${family};`
			span.textContent = 'BESbswy';
			return span;
		});

		// insert spans using the font so the browser will preload the woff files
		preloadSpans.forEach(span => globalConfig.context.document.body.appendChild(span));

		loadCSS(API + familyUrl, globalConfig.context)
			.then(e => {
				globalConfig.context.document.fonts.ready.then(() => {
					preloadSpans.forEach(span => span.remove());
					resolve();
				});
			})
			.catch(reject)

	})


}