import { Component } from 'preact';
import _ from 'lodash';

class RotatingItem extends Component {
	constructor(props){
		super(props);
		this.state = {
			suppressTransition: false,
			rotation: parseFloat(this.props.rotation),
		}
	}

	render(props, state){

		const { animate } = props;
		const animates = Number(animate) !== 0
		const animationDuration = animates ? 50/Number(animate) : 1;

		let fromCSS = `var(--base-translate, translate(0))`;
		let toCSS = fromCSS;

		props.transformSlots.forEach((variable)=>{
			if(variable==='--rotate-transform'){
				fromCSS+=` rotate(${parseFloat(props.rotation)}deg)`;
				toCSS+=` rotate(${parseFloat(props.rotation)+360}deg)`;
			} else {
				fromCSS+=` var(${variable}, translate(0))`;
				toCSS+=` var(${variable}, translate(0))`;
			}
		})

		return (
			<>
			{props.children}
			<style id="rotating-item">{animates ? `
				@keyframes rotate {
                    from {
                        transform: ${fromCSS};
                    }
                    to {
                        transform: ${toCSS};
                    }
                }

                :host {
                	animation: rotate ${Math.abs(animationDuration)}s linear ${Math.sign(Number(animate)) === -1 ? 'reverse' : ''} infinite;
					will-change: transform;
                }
				` : `
				:host { 
					--rotate-transform: rotate( ${props.rotation}deg);
                }
            `}</style>			
		</>)
	}

	// when changing stuff in the editor, allow changes to be reflected immediately
	allowTransition = _.debounce(()=>{
		this.setState({
			suppressTransition: false,
		}, ()=>{
		})
	}, 120)

	componentDidUpdate(prevProps){
		if(
			this.props.animate!== prevProps.animate ||
			this.props.rotation!== prevProps.rotation
		){

			this.setState({
				suppressTransition: true,
			}, ()=>{
				// flush css changes
				this.allowTransition();			
			})
		}
	}

	componentWillUnmount(){
		this.allowTransition.cancel();
	}
}


RotatingItem.defaultProps = {
	'rotation': 0,
	'animate': 0
}

RotatingItem.transformSlot = '--rotate-transform'


export default RotatingItem