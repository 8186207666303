import { helpers } from '@cargo/common';
import _ from 'lodash';

let counter = 0;
const isMac = helpers.isMac();

class HotKeyManager {
    constructor() {
        if (helpers.isServer) { return }

        if (!HotKeyManager.instance) {
            this.shortcuts = {};     // Stores shortcuts per scope
            this.scopeOrder = [];    // Pre-defined scope priority order
            this.onKeyDown = this.onKeyDown.bind(this);

            // Create named handlers so we can clean them up later
            this.handleEditorKeyDown = (e) => this.onKeyDown('editor', e);

            // If window is available, attach the 'editor' hotkey listener
            if (typeof window !== 'undefined') {
                window.addEventListener('keydown', this.handleEditorKeyDown);
            }

            // If the iframe contentWindow is available, attach the 'interface' hotkey listener
            if ( window.__c3_admin__ ) {

                this.handleInterfaceKeyDown = (e) => this.onKeyDown('interface', e);

                const editorFrame = document.getElementById('client-frame');

                editorFrame?.contentWindow?.addEventListener(
                    'keydown',
                    this.handleInterfaceKeyDown
                );
                
            }

            HotKeyManager.instance = this;
        }

        return HotKeyManager.instance;
    }

    setScopeOrder(scopes) {
        if (this.scopeOrder && _.isEmpty(this.scopeOrder)) {
            _.each(scopes, (scope) => { this.scopeOrder.push(scope); });
        }
    }

    getConfigKey(config) {
        const { keyCode, metaKey = "false", shiftKey = "false", altKey = "false", macCtrlKey = "false" } = config;
        return `${keyCode}-${metaKey}-${shiftKey}-${altKey}-${macCtrlKey}`;
    }

    getEventConfig(event) {
        return {
            keyCode: event.keyCode,
            metaKey: isMac ? event.metaKey : event.ctrlKey,
            shiftKey: event.shiftKey,
            altKey: event.altKey,
            macCtrlKey: isMac ? event.ctrlKey : false,
        };
    }

    registerHotKey(shortcutName, config, callback, scopeName = 'default') {
        const id = counter++;

        const configKey = this.getConfigKey(config);

        // Initialize the scope in the shortcuts if it doesn't exist
        if (!this.shortcuts[scopeName]) {
            this.shortcuts[scopeName] = {};
        }

        // Initialize the stack for the configKey in the scope if it doesn't exist
        if (!this.shortcuts[scopeName][configKey]) {
            this.shortcuts[scopeName][configKey] = [];
        }

        // Push the shortcut onto the stack
        this.shortcuts[scopeName][configKey].push({ id, callback });

        // Return unregister function
        return () => {
            const stack = this.shortcuts[scopeName][configKey];
            if (stack) {
                // Find the index of the shortcut with this id
                const index = stack.findIndex(item => item.id === id);
                if (index !== -1) {
                    stack.splice(index, 1);
                    // If the stack is empty, delete the key
                    if (stack.length === 0) {
                        delete this.shortcuts[scopeName][configKey];
                        // If the scope has no shortcuts, delete the scope
                        if (Object.keys(this.shortcuts[scopeName]).length === 0) {
                            delete this.shortcuts[scopeName];
                        }
                    }
                }
            }
        };
    }

    onKeyDown(zone, event) {
        if (event.repeat) return;

        const eventConfig = this.getEventConfig(event);
        const configKey = this.getConfigKey(eventConfig);

        if( store.getState()?.frontendState?.contactForm.inited ){
			// No shortucts can be used while contact form is open.
			return
		}

        // Iterate over the scopes based on the pre-defined priority order
        for (let i = 0; i < this.scopeOrder.length; i++) {
            const scopeName = this.scopeOrder[i];
            const scopeShortcuts = this.shortcuts[scopeName];
            if (scopeShortcuts && scopeShortcuts[configKey]) {
                const stack = scopeShortcuts[configKey];
                if (stack && stack.length > 0) {
                    // Get and execute the most recently registered shortcut
                    const shortcut = stack[stack.length - 1];
                    if (shortcut && shortcut.callback) {
                        event.preventDefault();
                        shortcut.callback(event);
                        return; // Stop after executing the first matching shortcut
                    }
                }
            }
        }

        // If no scope handled the shortcut, check the 'default' scope
        if (!this.scopeOrder.includes('default') && this.shortcuts['default'] && this.shortcuts['default'][configKey]) {
            const stack = this.shortcuts['default'][configKey];
            if (stack && stack.length > 0) {
                // Get and execute the most recently registered shortcut
                const shortcut = stack[stack.length - 1];
                if (shortcut && shortcut.callback) {
                    event.preventDefault();
                    shortcut.callback(event);
                }
            }
        }
    }
}

// Create a singleton instance
const hotKeyManager = new HotKeyManager();
Object.freeze(hotKeyManager);

// Export the singleton instance as a named export
export { hotKeyManager as HotKeyManager };

// Use in a the JSX of a class based component like this:

// <HotKey 
//    shortcut="cmd+shift+g"
//    config={{ keyCode: 71, metaKey: true,	shiftKey: true, altKey: false, macCtrlKey: false}}
//    callback={this.toggleDevGridMode}
//    scope="default"
//  />

// Use in a functional component like this:

// useHotKey(
//     'escape',
//     { keyCode: 27 },
//     callback,
//     'modal' 
// );

// Can also bind in a class based component like this:

// unregisterHotKeys = [];

// componentDidMount() {
//   const unregisterEscape = HotKeyManager.registerHotKey(
//     'escape',
//     { keyCode: 27 },
//     this.handleEscapeKey,
//     'alert'
//   );

//   const unregisterDelete = HotKeyManager.registerHotKey(
//     'delete',
//     { keyCode: 8 },
//     this.handleDeleteKey
//     'contextmenu'
//   );

//   this.unregisterHotKeys.push(unregisterEscape, unregisterDelete);
// }

// componentWillUnmount() {
//   this.unregisterHotKeys.forEach((unregister) => unregister());
// }
