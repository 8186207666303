import axios from 'axios';
import * as helpers from "@cargo/common/helpers";
import { store } from '../index';

const cache = new Map();

export const setStore = store => {

	let lastPublishedAt = undefined;

	// store listener to kill API cache when a publish occurred (and the live data has been updated)
	store.subscribe(() => {

		const state = store.getState();

		if(!state.adminState || !state.adminState.crdt) {
			return;
		}

		const publishedAt = state.adminState.crdt.publishedAt;

		if(publishedAt !== lastPublishedAt) {
			// evict all API cache results after a publish so we do not
			// hold onto stale data
			cache.clear();
		}

		lastPublishedAt = publishedAt;

	});

}

// only use auth on the frontend. SSR does not handle authorized requests.
if(!helpers.isServer) {

	// Handle interception previously cached calls and inject
	// new cache entries
	axios.interceptors.request.use( async (config) => {

		if(config.use_cache === true) {

			const requestUrl = axios.getUri(config);

			if(cache.has(requestUrl)) {

				// already have this url cached.
				config.adapter = function (config) {
					return cache.get(requestUrl).cachedResponsePromise
				}

			} else {

				// create a new response promise that we can resolve or reject
				// when from the response interceptor
				config.cachedResponsePromise = new Promise((resolve, reject) => {
					config.resolveCachedResponsePromise = resolve;
					config.rejectCachedResponsePromise = reject;
				});

				// Don't throw an Uncaught Promise error just for this resolver
				config.cachedResponsePromise.catch(e => e);

				// not cached yet. Run the request and set the cache
				cache.set(requestUrl, config);

			}

		}

		return config;

	});

	// resolve cached responses
	axios.interceptors.response.use(response => {

		if(response.config.resolveCachedResponsePromise) {
			response.config.resolveCachedResponsePromise(response);
		}

		return response;

	}, error => {

		if(error.config?.rejectCachedResponsePromise) {
			// reject
			error.config.rejectCachedResponsePromise(error);
		}

		return Promise.reject(error);

	});

}