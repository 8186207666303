import { Component, createRef } from "preact";
import { createPortal } from 'preact/compat';
import { useState, useEffect } from "preact/hooks";
import _ from 'lodash';
import * as helpers from "@cargo/common/helpers"
import { connect } from 'react-redux';
import register from "./register"

import { withPageInfo } from "./page-info-context";

import AudioPlayerEditor from '../overlay/audio-player-editor';
let resizeObserver;

if(!helpers.isServer) {

	resizeObserver = new ResizeObserver(entries => {

		entries.forEach(entry => {
			if( audioPlayerInstances.has(entry.target)){

				const component = audioPlayerInstances.get(entry.target);

				let box = entry.borderBoxSize[0] || entry.borderBoxSize;

				component.setState({
					size: {
						width: box.inlineSize,
						height: box.blockSize,
					}
				})

			}

		});
		
	});	

}

const audioPlayerInstances = new Map();

class AudioPlayer extends Component {

	constructor(props) {
		
		super(props);


		const filetype = this.getFiletype(props.src);

		this.audio_track  = createRef();
		this.bar          = createRef();
		this.track_buffer = createRef();
		this.current_time = createRef();
		this.progressIndicator = createRef();

		this.state = {
			size: {
				width: 100,
				height: 100,
			},
			mounted: filetype !== 'stream',
			filetype,
			seeking: false,
			mouseInteractionDisabled : false,
			mouseMoveActive          : false,
			dragging                 : false,
			streaming                : false,
			paused 					 : true,
			playing 				 : false,
			started                  : false,
			ended                    : false,
			preventTransition        : false,
			currentProgress          : 0,
			currentBuffer			 : 0,
			remainingBuffer          : 0, 
			currentTime              : '0:00',
			rawTimeStamp			 : 0,
			totalTime				 : '0:00',
			progress				 : 0,
			hasError                 : false,
			hasMetadata				 : false,
		}

	}

	render(props, state) {
		const {
			baseNode,
			adminMode,
			label,
			loop,
			"total-time": totalTime,
			"playback-time": playbackTime,
			"seek-controls": seekControls,
			"browser-default": browserDefault,
			pageInfo,
		} = props;

		const {
			visible
		} = state;

		let playing = !this.state.paused || this.state.started;

		let src = this.props.src ? this.props.src : null;

		return createPortal(<>
				{adminMode && pageInfo.isEditing && <AudioPlayerEditor
					audioPlayerInstance={baseNode}
				/> }
				<style>{`
					* {
				    	box-sizing: border-box;						
					}

					:host {
						--audio-player-progress: ${(this.state.currentProgress / 100) || 0};
						--audio-player-buffer-progress: ${(this.state.currentBuffer / 100) || 0};
						--audio-player-buffer-remaining: ${(this.state.remainingBuffer / 100) || 0};
						max-width: 100%;
						${!browserDefault ? `
						    position: relative;
						    display: inline-flex;
						    align-items: stretch;
						    width: 100%;` :`
						    width: 400px;
						    `
						}
					}

					${browserDefault &&`
					audio {
						width: 100%;
					}

					[part="time-bar"], 
					[part="button"],
					[part="separator"]{
						display:none!important;
					}						
					`}



					[part="buffer"],
					[part="progress"] {
						display: ${playing && !this.state.streaming ? 'block': 'none'};
					}

					[part="label"] {
						display: ${this.state.paused && !this.state.started ? 'block': 'none'};
					}

					[part="current-time"] {
						display: ${!this.state.streaming && this.state.started ? 'block': 'none'};
					}

					[part="stream-anim"] {
						display: ${ playing && this.state.streaming ? 'block' :'none'};
					}

					[part="total-time"] {
						display: ${ playing && !this.state.streaming ? 'block' :'none'};
					}

					[part="buffer"]{
						left: calc( var(--audio-player-buffer-progress, 0) * 100% );
						width: calc( var(--audio-player-buffer-remaining, 0) * 100% );
					}

					[part="progress"]{
						width: calc( var(--audio-player-progress, 0) * 100% );
					}

					[part="stream-anim"] > span {
					  animation-name: period;
					  animation-duration: 1.5s;
					  animation-iteration-count: infinite;
					  animation-timing-function: steps(2, end);  
					}

					[part="stream-anim"] > span:before {
						content: '.';
					}

					[part="stream-anim"] > span:nth-child(1){
					  animation-delay: -.4s;
					}

					[part="stream-anim"] > span:nth-child(2){
					  animation-delay: -.2s;
					}

					[part="stream-anim"] > span:nth-child(3){
					  animation-delay: 0s;
					}

					@keyframes period {
					  from {
					    visibility: hidden;
					  }
					  to {
					    visibility: visible;
					  }
					   
					}					
				
					`}
				</style>
				
				<div 

					part="button"
					onClick={(e) => this.togglePlay(e) }
				>
					<svg
						vector-effect="non-scaling-stroke"
						part={this.state.paused ?
						"play-icon icon" :
						"pause-icon icon"
						}
						x="0px" y="0px" 
						viewBox={this.state.paused ?
							"0 0 11.3 12" :
							"0 0 10 12"
						}
					>
						{this.state.paused ? 
							<polygon points="0 0 0 12 11.3 6 0 0" part="play-icon-path icon-path" /> :
							<><polygon part="pause-icon-path icon-path" points="0 0 0 12 1 12 4 12 4 0 1 0 0 0"/><polygon part="pause-icon-path icon-path" points="9 0 6 0 6 12 9 12 10 12 10 0 9 0"/></>
						}
					</svg>
				</div>
				<div
					part="separator"
				> </div>
				<div 
					draggable={this.props.adminMode? 'true': null}
					part="time-bar"
					ref={this.bar}
					onMouseDown  ={(e) => this.onMouseDown(e)}
					onTouchStart ={(e) => this.onMouseDown(e)}
					onTouchEnd   ={(e) => this.onMouseUp(e)}
					// onMouseMove  ={(e) => this.onMouseInteraction(e) }
					// onTouchMove  ={(e) => this.onMouseInteraction(e) }
				>
					<div 
							ref={this.track_buffer} 
							part="buffer"
							// style={{ left: this.state.currentBuffer+'%', width: this.state.remainingBuffer+'%' }}
						> </div>
						<div 
							part="progress"
						>
							<div 
								ref={this.progressIndicator}
								part="progress-indicator"
							></div>
						</div>

						<div part="label">{!this.state.hasError ? (<>
							{ this.props.label }
						</>): 'Unable to load file' }</div>

						<div 
							part="current-time"
							ref={ this.current_time }
						>{this.state.currentTime}</div>

						<div part="stream-anim">
							<span part="animated-period period-one"/>
							<span part="animated-period period-two"/>
							<span part="animated-period period-three"/>
						</div>

						<div part="total-time">{this.state.totalTime}</div>

				</div>

				<audio 
					draggable={this.props.adminMode? 'true': null}				
					part="audio-element"
					controls={browserDefault === true ? true : false}
					src  ={this.state.mounted ? src : null}
					ref  ={this.audio_track}
					loop ={this.props.loop === true || this.props.loop === 'true' ? true : undefined }
					preload={this.state.filetype === 'file' ? "metadata" : null}
					onProgress       ={(e) => this.onProgress(e) }
					onLoadedMetaData ={(e) => this.onLoadedMetadata(e) }
					onTimeUpdate     ={(e) => this.updatePlayerTime(e) }
					onSeeking        ={(e) => this.onSeekStart(e) }
					onSeeked         ={(e) => this.onSeekEnd(e) }
					onEnded          ={(e) => this.onTrackEnd(e) }
					onError          ={(e) => this.onError(e) }
					onCanPlay        ={(e) => this.clearError(e) }
					>
				</audio>
			</> , baseNode.shadowRoot)
		
	}

	disableMouseInteraction = ()=> {
		this.setState({
			mouseInteractionDisabled: true,
		})
	}

	enableMouseInteraction = ()=> {
		this.setState({
			mouseInteractionDisabled: false,
		})
	}

	setPlayState = (e)=>{

		this.setState({ 
			playing : true, 
			paused  : false,
			started : true,
			streaming: this.state.filetype === 'stream'
		}, ()=>{
			this.props.baseNode.setAttribute('status', this.state.filetype === 'stream' ? 'streaming' : 'playing')
		})

	}

	setPauseState = (e)=> {

		this.setState({ paused: true },
			()=>{
				this.props.baseNode.setAttribute('status', 'stopped')
			})

		if(this.state.streaming) {
			this.setInitialState();
		}

	}

	setInitialState = (e, options)=>{

		if(this.state.streaming) {
			this.setState({ 
				started   : false
			});
		}

		this.setState({
			playing  : false,
			paused   : true,
			animIndex      : 0,
			currentTime    : 0,
			currentProgress: 0,
			currentBuffer  : 0,
			remainingBuffer: 0,
			rawTimeStamp: 0
		}, ()=> {
			this.props.baseNode.setAttribute('status', options?.error === true ? 'error' : 'stopped');
		})
	}

	onMouseDown = (e)=>{

		// don't listen to right click
		if(this.state.mouseInteractionDisabled || this.state.streaming || e.button === 2 || this.state.started === false ) {
			return;
		}

		e.preventDefault();					

		// add a class to get the resize cursor
		document.body.classList.add('audio-player-dragging');

		this.calculateProgressPosition(e);

		this.setState({ 
			mouseMoveActive: true, 
			preventTransition: true,
			dragging: false 
		})

		//Bind mousemove listener
		this.bar.current.addEventListener('mousemove', this.onMouseInteraction);
		this.bar.current.addEventListener('touchmove', this.onMouseInteraction);
		window.addEventListener('mousemove', this.onMouseInteraction);
		window.addEventListener('mouseup', this.onMouseUp);


	}

	calculateProgressPosition = (e) =>{
		
		let rect = this.bar.current.getBoundingClientRect();
		let x_pos = e.clientX || e.touches[0].clientX;
		// if (x_pos <= 0) return;

		// drag all the way to the left
		if (x_pos < rect.left) {
			this.audio_track.current.currentTime = 0;
		// drag all the way to the right	
		} else if (x_pos > rect.right) {
			this.audio_track.current.currentTime = this.audio_track.current.duration;
		// drag within the duration
		} else {
			this.audio_track.current.currentTime = this.audio_track.current.duration * ((x_pos - rect.left) / this.state.size.width);	
		}

		this.updatePlayerTime();
		
		
	}

	onMouseInteraction = (e)=>{
		
		if(this.state.mouseInteractionDisabled || this.state.streaming || !this.state.started || !this.state.mouseMoveActive || this.audio_track.current.readyState === this.audio_track.current.HAVE_NOTHING)  {
			return;
		}

		this.calculateProgressPosition(e)

		this.setState({ 
			dragging: true 
		})
	}

	onMouseUp = (e)=> {
		document.body.classList.remove('audio-player-dragging');

		//unbind mousemove listener...
		if(this.state.dragging === true){

			if(!this.state.mouseInteractionDisabled && !this.state.streaming && this.state.started && this.state.mouseMoveActive && this.audio_track.current.readyState !== this.audio_track.current.HAVE_NOTHING )  {
				this.calculateProgressPosition(e)
			}
			
			// this.setState({ preventTransition: true })
			// remove resize cursor class


			if(this.state.paused === false && this.state.started === true) {
				if (this.audio_track.current.currentTime >= this.audio_track.current.duration - 0.001) {
					this.setPauseState();
				} else {
					this.audio_track.current.play();	
				}
				
			}

		}

		window.removeEventListener('mouseup', this.onMouseUp);
		window.removeEventListener('mousemove', this.onMouseInteraction);
		this.bar.current.removeEventListener('mousemove', this.onMouseInteraction);
		this.bar.current.removeEventListener('touchmove', this.onMouseInteraction);		

		this.setState({ 
			dragging: false, 
			mouseMoveActive: false 
		})		

	}

	onSeekStart = (e)=> {
		this.setState({ 
			seeking: true,
		})		
	}

	onSeekEnd = (e)=> {
			
		this.setState({ 
			seeking: false,
		})
	}

	togglePlay = (e)=> {

		if(this.state.mouseInteractionDisabled || !this.state.mounted) {
			return;
		}

		if( this.audio_track.current.paused ) {

			let playPromise = this.audio_track.current.play();

			audioPlayerInstances.forEach((component, el)=>{
				if( component !== this ){
					component.audio_track.current.pause();
					component.setPauseState();
				}
			});

			if( playPromise ) {
				
				playPromise.then(function(){
					this.setPlayState();
					this.setState({ 
						preventTransition: false,
					})							
				}.bind(this)).catch(this.onError).finally(function(){

					this.setState({ 
						preventTransition: true
					})		

				}.bind(this));

			} else {
				
				this.setPlayState();

			}
			
			
		} else {

			this.audio_track.current.pause();
			this.setPauseState();


		}

	}

	onError = (e)=> {

		// If in the admin, and there's an empty src (new element), don't show an error just yet.
		// if(this.isAdminEdit && this.audio_track.getAttribute('src') === "") {
		// 	return true;
		// }
		// 

		if( this.props.adminMode ){ return }

		this.setState({ hasError: true },()=> {
			this.setInitialState(null, {error: true});
		})

		// If we're dealing with an exception, log it.
		if(e instanceof DOMException) {
			console.error(e);
		}

	}

	clearError = (e)=> {

		if(this.state.hasError) {
			this.setState({ hasError: false },()=> {
				this.setInitialState(null, {error: false});
			})
		}

	}

	onTrackEnd = (e)=> {

		if (this.state.dragging !== true) {

			this.setState({ 
				ended: true,
			})

			this.setPauseState();
		}
	}

	onLoadedMetadata = (e)=>{
		if( !this.audio_track.current){
			return
		}

		let duration  = this.timestampToHumanReadable(this.audio_track.current.duration);
		this.setState({
			totalTime: duration,
			streaming: false,
			hasMetadata: true,
		}, ()=> {
			this.props.baseNode.setAttribute('status', 'stopped')
		})		

	}

	onProgress = (e)=>{

		if (!this.audio_track.current) {
			return;
		}

		if( this.state.filetype === 'stream' ){
			this.setState({
				totalTime: 0,
				streaming: true,
				hasMetadata: true,					
			}, ()=> {
				this.props.baseNode.setAttribute('status', 'streaming')
			})
		} else {
			this.setState({
				streaming: false,
			}, ()=>{
				this.props.baseNode.setAttribute('status', 'playing')
			})
			
		}
		
		this.updateBufferSize();
	}

	updatePlayerTime = (e)=>{

		cancelAnimationFrame(this.updateFrame);

		if(!this.state.started || !this.audio_track.current || this.audio_track.current.readyState === this.audio_track.current.HAVE_NOTHING ) {
			return;
		}


		this.setState({
			rawTimeStamp: this.audio_track.current.currentTime
		})

		// Do not continue if using the browser's default player
		const { "browser-default": browserDefault } = this.props;

		if( browserDefault ) {
			return;
		}

		let preventTransition = false;

		let rawTimeStamp     		 = this.audio_track.current.currentTime,
			currentTimeStamp 		 = this.timestampToHumanReadable( rawTimeStamp ),
		    percentage      		 = (rawTimeStamp / this.audio_track.current.duration) * 100,
			bar_width                = this.state.size.width,
			progress_indicator_width = parseInt(window.getComputedStyle(this.progressIndicator.current).width);

		if(  ( this.props.loop === 'true' || this.props.loop === true ) && ( percentage >= 99.5 || percentage === 0 ) ){
			preventTransition = true;
		}

		// if less than the width of the progress indicator, position it to the right of the bar
		if (bar_width * (percentage/100) < progress_indicator_width) {
			percentage = ((100 - percentage) * (progress_indicator_width/bar_width));
			// this.elements.progress.style.transition = 'none';
			// this.elements.track_buffer.style.transition = 'none';
			// preventTransition = true;

		}	

		
		this.setState((prevState)=>{

			// Safari has a tendency to jump around while scrubbing
			let smoothedPercentage = percentage;
			if(prevState.dragging){
				if(Math.abs(prevState.currentProgress-percentage) > 1){
					smoothedPercentage = percentage*.1 + prevState.currentProgress*.9
				}
			}			

			// update the buffer bar
			this.updateBufferSize( .01 * smoothedPercentage*this.audio_track.current.duration );

			return{
				currentTime           : currentTimeStamp,
				currentBuffer         : smoothedPercentage,
				currentProgress       : smoothedPercentage,
				preventTransition     : preventTransition
			}
		})

		if(this.state.dragging){
			this.updateFrame = requestAnimationFrame(this.updatePlayerTime)			
		}

	}


	getFiletype = (src='')=>{
		// this isn't necessarily to check for compatibility, but to check whether the src is is
		// a stream, a playlist or something else
		// streams generally do not have a file extension, so anything that doesn't have one will be treated as a stream

		let hasExtension = false;

		// playlist files are currently unusable 
		try {
			hasExtension = new URL(src).pathname.split('/').pop()?.indexOf('.') > -1;
		} catch(e) {console.error(e)};

		const playlistTypes = ['m3u', 'pls', 'm3u8', 'asx'];

		if( hasExtension && playlistTypes.some(ext=>src.toLowerCase().endsWith(ext)) ){

			return "playlist"

		} else if ( hasExtension ){

			return "file"

		} else {

			return "stream"
		}
	}

	updateBufferSize = ( currentTimeStamp ) => {

		if ( !this.audio_track.current) {
			return;
		}

		let availableBuffer,
			currentPlayerTimestamp = currentTimeStamp || this.audio_track.current.currentTime,
			availableBufferPercentage = 0;

		// go through all buffer ranges
		for(var i = 0; i < this.audio_track.current.buffered.length; i++) {

			// locate which buffer range contains the current player timestamp
			if(
				currentPlayerTimestamp >= this.audio_track.current.buffered.start(i) &&
				currentPlayerTimestamp <= this.audio_track.current.buffered.end(i)
			) {

				// the current track time is contained in a buffer range. See how much of this 
				// buffer range is playable from here on.
				availableBuffer = Math.max(this.audio_track.current.buffered.end(i) - currentPlayerTimestamp, 0);
				availableBufferPercentage = (availableBuffer / this.audio_track.current.duration) * 100;
				break;

			}

		}

		// add a little more to prevent Chrome from improperly rounding down.
		if(availableBufferPercentage !== 0) {
			availableBufferPercentage += 1;
		}

		this.setState({ remainingBuffer : availableBufferPercentage })

	}

	timestampToHumanReadable = (timestamp) => {
		if(isNaN(timestamp)) {
			return '0:00';
		}

		var seconds = Math.floor(timestamp % 60),
			minutes = Math.floor(timestamp / 60),
			hours = Math.floor(minutes / 60);

		if(seconds < 10){
			seconds = '0' + seconds;
		}

		if (hours > 0) {
			minutes = (minutes - (hours * 60));
			if (minutes < 10) {
				minutes = '0' + minutes;
			}
			return hours + ':' + minutes + ':' + seconds;
		}


		return minutes + ':' + seconds;
	}

	onDragStart = (e)=>{

		// reset audio player on drag
		this.setState({ 
			started   : false
		});			
		this.audio_track.current.pause();
		this.setInitialState();		

		e.dataTransfer.setData("audio-player-drag", "true");		
	}


	componentDidMount(){

		this.setPauseState();

		// mounting a stream immediately in safari is bad news for some reason
		if( !this.state.mounted){
			setTimeout(()=>{
				this.setState({
					mounted: true,
				});
			}, this.state.filetype === 'stream' ? 2000 : 0);
		}

		if(!helpers.isServer){
			this.props.baseNode.addEventListener('dragstart', this.onDragStart)
			audioPlayerInstances.set(this.bar.current, this);
			resizeObserver.observe(this.bar.current)			
		}

	}

	componentDidUpdate(prevProps){

		if (prevProps.src !== this.props.src ){

			this.setState({
				filetype: this.getFiletype(this.props.src)
			})

			// Gracefully handle transition from blob src to http src
			if( prevProps.src && prevProps.src.substring(0, 5) === 'blob:') {
				if (this.state.rawTimeStamp !== 0) {
					this.audio_track.current.currentTime = this.state.rawTimeStamp;
				}
				if (this.state.paused === false) {
					this.audio_track.current.play()
				}
			}


		} 

	}

	componentWillUnmount(){
		if(!helpers.isServer){
			this.props.baseNode.removeEventListener('dragstart', this.onDragStart);			
			audioPlayerInstances.delete(this.bar.current);
			resizeObserver.unobserve(this.bar.current)			
		}		
		window.removeEventListener('mouseup', this.onMouseUp);		

	}


}


const ConnectedAudioPlayer = withPageInfo(connect(
    (state, ownProps) => {
        return {
            adminMode: state.frontendState.adminMode,
        };
    }
)(AudioPlayer));


register(ConnectedAudioPlayer, 'audio-player', [
	'src',
	'label',
	'loop',
	'total-time',
	'playback-time',
	'seek-controls',
	'browser-default',
]) 




export default ConnectedAudioPlayer;
