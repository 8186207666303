import { Component } from 'preact';
import _ from 'lodash';
import { effectsDropshadowDefaults } from '../../../../../admin/src/defaults/dropshadow-defaults';

class Dropshadow extends Component {
	constructor(props){
		super(props);
	}

	render(props, state){

        const offsets = this.props['dropshadow-offset'].split(' ');
        const offsetY = offsets[0];
        const offsetX = offsets.length > 1 ? offsets[1] : offsets[0];

		return (
			<>
                {props.baseNode.tagName==='MEDIA-ITEM' 
                    ? <style>{`
                        img {
                            filter: drop-shadow(${offsetX} ${offsetY} ${this.props['dropshadow-blur-radius']} ${this.props['dropshadow-color']});
                        }
                    `}</style> 
                    : <style>{`
                        :host {
                            filter: drop-shadow(${offsetX} ${offsetY} ${this.props['dropshadow-blur-radius']} ${this.props['dropshadow-color']});
                        }
                    `}</style>
                }
				{props.children}
			</>
		)

	}
}


Dropshadow.defaultProps = effectsDropshadowDefaults;

export default Dropshadow