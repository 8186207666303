import { lt, uniqueId } from 'lodash';
import { Component } from 'preact';
import { createRef, createPortal } from 'preact/compat'

import { flyingObjectDefaults } from '../../../../../admin/src/defaults/flyingobject-defaults';

import { subscribe, unsubscribe, dispatch } from '../../../customEvents';

let flierPortalHost;
let flierSet = new Set();

class FlyingObject extends Component {
	constructor(props){
		super(props);

		this.flierID = '-'+uniqueId();

		this.flierRef = createRef();
		const startingFlightCount = this.generateRandomIntInclusive(1, 10000);
		this.state = {
			portalElement: null,
			currentAnimation: 'fly1',
			flightCount: startingFlightCount,
			calculatedAnimations: {
				fly1: this.calculateFlyAnimation(startingFlightCount, 1),
				fly2: this.calculateFlyAnimation(startingFlightCount, 2),
				fly3: this.calculateFlyAnimation(startingFlightCount, 3),
				fly4: this.calculateFlyAnimation(startingFlightCount, 4),
			}
		}
	}

	generateRandomIntInclusive(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min; // max & min both included 
	}

	calculateDuration(speed) {
		const percent = (Math.max((100 - Math.abs(speed)), 5)) * 0.01;
		const total = 100;
		return (total * percent);
	}

	generateRandomIntFromSeed(seed) {
		var x = Math.sin(seed) * 10000; 
		return x - Math.floor(x);
	}

	shuffleFromSeed(array, seed) {
		var m = array.length, t, i;
		  
		while (m) {
			i = Math.floor(this.generateRandomIntFromSeed(seed) * m--);
			t = array[m];
			array[m] = array[i];
			array[i] = t;
			++seed
		}
		return array;
	}

	calculateFlyAnimation(seed, part) {

		const isEven = seed % 2 == 0;

		const arr = this.shuffleFromSeed(['ltr','rtl','ttb','btt'], seed);
		const offsetNumber = Number(this.props.scale.replace('vmax', ''));

		const totalRotation = (this.props.rotation / 5) * 180;
		
		const ltr = [
			{'x':`calc(0vw - ${1.4 * offsetNumber}vmax)`, 'y':`${this.generateRandomIntInclusive(isEven ? 0 - (1.4 * offsetNumber) : 60, isEven ? 40 : 100 + (1.4 * offsetNumber))}vh`},
			{'x':`calc(100vw + ${1.4 * offsetNumber}vmax)`, 'y':`${this.generateRandomIntInclusive(isEven ? 60 : 0 - (1.4 * offsetNumber), isEven ? 100 + (1.4 * offsetNumber) : 40)}vh`}
		]

		const rtl = [
			{'x':`calc(100vw + ${1.4 * offsetNumber}vmax)`, 'y':`${this.generateRandomIntInclusive(isEven ? 60 : 0 - (1.4 * offsetNumber), isEven ? 100 + (1.4 * offsetNumber) : 40)}vh`},
			{'x':`calc(0vw - ${1.4 * offsetNumber}vmax)`, 'y':`${this.generateRandomIntInclusive(isEven ? 0 : 60, isEven ? 40 : 100 + (1.4 * offsetNumber))}vh`},
		]

		const ttb = [
			{'x':`${this.generateRandomIntInclusive(isEven ? 0 - (1.4 * offsetNumber) : 60, isEven ? 40 : 100 + (1.4 * offsetNumber))}vw`, 'y':`calc(0vh - ${1.4 * offsetNumber}vmax)`},
			{'x':`${this.generateRandomIntInclusive(isEven ? 60 : 0 - (1.4 * offsetNumber), isEven ? 100 + (1.4 * offsetNumber) : 40)}vw`, 'y':`calc(100vh + ${1.4 * offsetNumber}vmax)`},
		]

		const btt = [
			{'x':`${this.generateRandomIntInclusive(isEven ? 60 : 0 - (1.4 * offsetNumber), isEven ? 100 + (1.4 * offsetNumber) : 40)}vw`, 'y':`calc(100vh + ${1.4 * offsetNumber}vmax)`},
			{'x':`${this.generateRandomIntInclusive(isEven ? 0 - (1.4 * offsetNumber) : 60, isEven ? 40 : 100 + (1.4 * offsetNumber))}vw`, 'y':`calc(0vh - ${1.4 * offsetNumber}vmax)`},
		]

		const points = arr.reduce((prevValue, currentValue) => {
			switch(currentValue) {
				case 'ltr':
					return prevValue.concat(ltr);
				case 'rtl':
					return prevValue.concat(rtl);
				case 'ttb':
					return prevValue.concat(ttb);
				case 'btt':
					return prevValue.concat(btt);
				default:
					return prevValue;
			}
		}, [])

		switch ( part ) {
			case 1: {
				return `
					0% {
						transform: translateX(${points[0].x}) translateY(${points[0].y});
						opacity: 0;
					}
					0.001% {
						transform: translateX(${points[0].x}) translateY(${points[0].y});
						opacity: 1;
					}
				
					99.999% {
						transform: translateX(${points[1].x}) translateY(${points[1].y});
						opacity: 1;
					}
		
					100% {
						transform: translateX(${points[1].x}) translateY(${points[1].y});
						opacity: 0;
					}
				`
			}
			case 2: {
				return `
					0% {
						transform: translateX(${points[2].x}) translateY(${points[2].y});
						opacity: 0;
					}
				
					0.001% {
						transform: translateX(${points[2].x}) translateY(${points[2].y});
						opacity: 1;
					}
				
					99.999% {
						transform: translateX(${points[3].x}) translateY(${points[3].y});
						opacity: 1;
					}
		
					100% {
						transform: translateX(${points[3].x}) translateY(${points[3].y});
						opacity: 0;
					}
				`
			}
			case 3: {
				return `
					0% {
						transform: translateX(${points[4].x}) translateY(${points[4].y});
						opacity: 0;
					}
		
					0.001% {
						transform: translateX(${points[4].x}) translateY(${points[4].y});
						opacity: 1;
					}
				
					99.999% {
						transform: translateX(${points[5].x}) translateY(${points[5].y});
						opacity: 1;
					}
		
					100% {
						transform: translateX(${points[5].x}) translateY(${points[5].y});
						opacity: 0;
					}
				`
			}
			case 4: {
				return `
					0% {
						transform: translateX(${points[6].x}) translateY(${points[6].y});
						opacity: 0;
					}
		
					0.001% {
						transform: translateX(${points[6].x}) translateY(${points[6].y});
						opacity: 1;
					}
				
					99.999% {
						transform: translateX(${points[7].x}) translateY(${points[7].y});
						opacity: 1;
					}
		
					100% {
						transform: translateX(${points[7].x}) translateY(${points[7].y});
						opacity: 0;
					}
				`
			}
		}
	}

	onAnimationEnd(e) {
		const prev = Number(e.animationName.substr(3, 1));
		const next = prev < 4 ? prev + 1 : 1;
		this.setState({
			currentAnimation: `fly${next}`,
			...(next === 1 && {
				flightCount: this.state.flightCount + 1,
				calculatedAnimations: {
					fly1: this.calculateFlyAnimation(this.state.flightCount + 1, 1),
					fly2: this.calculateFlyAnimation(this.state.flightCount + 1, 2),
					fly3: this.calculateFlyAnimation(this.state.flightCount + 1, 3),
					fly4: this.calculateFlyAnimation(this.state.flightCount + 1, 4),
				}
			}),
		});
	}

	componentDidMount() {

		if( !flierPortalHost){
			flierPortalHost = document.createElement('media-item');
			flierPortalHost.setAttribute('no-component', '');

			if( !flierPortalHost.shadowRoot){
				flierPortalHost.attachShadow({mode: 'open'});				
			}

			flierPortalHost.shadowRoot.innerHTML = `
			<style>
				:host {
					all:unset!important;
					border:none!important;
					padding: 0!important;
					position: fixed!important;
					z-index: 5000!important;
					pointer-events:none!important;
					background:none!important;
					inset: 0!important;
					overflow: hidden!important;
				}

				.spinner [part="media"] {
					pointer-events: none;
					border-radius:0!important;
					padding: 0!important;
					border: none !important;
					position: absolute;
					width: 100%;
					height: 100%;
					inset: 0;
				}

				.spinner img,
				.spinner video {
					object-fit: contain;
				}
				.spinner .frame,
				.spinner .sizing-frame {
					display: contents;
				}				
			</style>
			`
			document.body.appendChild(flierPortalHost);

		}

		if( !this.state.portalElement ){
			const portalElement = document.createElement('div');
			portalElement.style.display = 'contents';
			flierPortalHost.shadowRoot.appendChild(portalElement);
			this.setState({
				portalElement: portalElement,
			},()=>{
				if ( this.flierRef.current) {
					this.flierRef.current.addEventListener('animationend', (e) => this.onAnimationEnd(e))
				}				
			});
		}

		flierSet.add(this)
	}



	componentWillUnmount() {
		if ( this.flierRef.current) {
			this.flierRef.current.removeEventListener('animationend', (e) => this.onAnimationEnd(e))
		}

		if( this.state.portalElement ){
			this.state.portalElement.remove();
		}

		flierSet.delete(this)

		if ( flierSet.size == 0 && flierPortalHost){
			flierPortalHost.remove();
			flierPortalHost = null;
		}

	}

	render(props, state){

		const { 
			speed, 
			rotation,
			scale,
			delay,
			adminMode 
		} = props;

		const { 
			currentAnimation,
			flightCount,
			calculatedAnimations,
			portalElement,
		} = state;

		if( !portalElement){
			return null;
		}

		return <>
			<style>{':host {display:none!important;}'}</style>
			{createPortal(
				<>
				<style>{`

					.flier${this.flierID} {
						pointer-events: none !important;
						top: 0;
						left: 0;
						position: absolute;
						z-index:9995;
						transform: translateX(-100%) translateY(-100%);
						animation-delay: ${delay}s;
						animation-duration: ${this.calculateDuration(speed) / 4}s;
						animation-timing-function: linear;
						animation-direction: ${Math.sign(speed) === 1 ? 'normal' : 'reverse'};
					}

					.fly1${this.flierID} {
						animation-name: fly1${this.flierID};
					}

					.fly2${this.flierID} {
						animation-name: fly2${this.flierID};
					}

					.fly3${this.flierID} {
						animation-name: fly3${this.flierID};
					}

					.fly4${this.flierID} {
						animation-name: fly4${this.flierID};
					}

					.flier${this.flierID} .spinner {
						${Number(rotation) !== 0 ? `animation: spin ${this.calculateDuration(rotation)}s ${Math.sign(Number(rotation)) === -1 ? 'reverse' : 'normal'} linear infinite;` : ''}
						width: ${scale};
						height: ${scale};
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.flier${this.flierID} .spinner figure {
						padding: 0;
						margin: 0;
						width: ${scale};
						height: ${scale};
					}

				
					/* Keyframe values control where the element will begin
						and end its trajectory across the screen. Each rule
						represents a path the element follows across the screen. */
					
				
					@keyframes fly1${this.flierID} {
						${calculatedAnimations.fly1}
					}

					@keyframes fly2${this.flierID} {
						${calculatedAnimations.fly2}
					}

					@keyframes fly3${this.flierID} {
						${calculatedAnimations.fly3}
					}

					@keyframes fly4${this.flierID} {
						${calculatedAnimations.fly4}
					}

					@keyframes spin {
						0% {
							transform: rotateZ(0deg);
						}
						100% {
							transform: rotateZ(3600deg);
						}
					}
				`}</style>

				<div className={`flier${this.flierID} ${currentAnimation}${this.flierID}`} ref={this.flierRef}>
					<div className="spinner">
						{props.children}
					</div>
				</div>
			</>, portalElement)}
		</>
	}

}

FlyingObject.defaultProps = flyingObjectDefaults;

export default FlyingObject