import { actionTypes } from "../actions";
import { combineReducers } from 'redux';
import { CRDTState } from "../globals";

function byId(state = {}, action) {

	if(!action.payload) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_SCAFFOLDING_FULFILLED: 
		case actionTypes.FETCH_ADMIN_LIST_FULFILLED: 
		case actionTypes.FETCH_CONTENT_FULFILLED:
		case actionTypes.FETCH_SITE_PACKAGE_FULFILLED: 

			let data = action.payload.data;

			if(action.type === actionTypes.FETCH_SITE_PACKAGE_FULFILLED) {
				data = data.scaffolding;
			}

			if(data) {

				return {
					...state,
					...(data).reduce((map, item) => {

						if(item.page_type !== "set") {
							return map;
						}

						// clone the item
						item = {...item};

						// delete forbidden fields from clone
						delete item.index
						delete item.sort
						delete item.set_id
						delete item.is_homepage
						
						if(item.purl) {
							item.purl = item.purl.toLowerCase();
						}

						// assume every set coming from the API is published
						item.crdt_state = CRDTState.Published;

						map[item.id] = item

						return map;

					}, {})
				};

			}

		break;
	}

	return state;
};

export const rawReducers = {
	byId
}

export default combineReducers(rawReducers);