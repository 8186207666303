export const loadCSS = (url, context = window) => {

	return new Promise((resolve, reject) => {
		var head  = context.document.getElementsByTagName('head')[0];
		var link  = context.document.createElement('link');
		link.rel  = 'stylesheet';
		link.type = 'text/css';
		link.href = url;
		link.media = 'all';
		link.onload = resolve;
		link.onError = reject;
		head.appendChild(link);
	});

}