import { Component } from 'preact';
import _ from 'lodash';
import { effectsDropshadowDefaults } from '../../../../../admin/src/defaults/dropshadow-defaults';

class InlinePartStyles extends Component {
	constructor(props){
		super(props);
	}

	render(props, state){
        const rules = Object.keys(props).reduce((acc, curr) => {
            if (curr.includes('-style')) {
                const part = curr.replace('-style', '');
                acc.push({
                    part,
                    styles: props[curr] ? props[curr].replaceAll(';', '!important;') : ''
                })
            }
            return acc;
        }, [])
		return (
			<>
                <style>{rules.map(({part, styles}) => `[part*="${part}"] { ${styles} }`)}</style>
				{props.children}
			</>
		)

	}
}


InlinePartStyles.defaultProps = {

};

export default InlinePartStyles