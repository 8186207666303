import { layoutData as gridLayoutData } from './layouts/grid';
import { layoutData as columnizedLayoutData } from './layouts/columnized';
import { layoutData as slideshowLayoutData } from './layouts/slideshow';
import { layoutData as freeformLayoutData } from './layouts/freeform';
import { layoutData as justifyLayoutData } from './layouts/justify';
// import { layoutData as fitgridLayoutData } from './layouts/fit-grid';

// options are not used directly by the galleries or here, but imported into one place so that they're available to the editor
const galleryData = {
	grid: gridLayoutData,
	columnized: columnizedLayoutData,
	slideshow: slideshowLayoutData,
	freeform: freeformLayoutData,
	justify: justifyLayoutData,
	// 'fit-grid': fitgridLayoutData,

};

export {
	galleryData
}

