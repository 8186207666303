import { Component, createRef, createContext } from "preact";
import { connect } from 'react-redux';

const PIDMap = new Map();

const PageInfoContext = createContext({
	isEditing: false,
	pid: null,
	filterIndexes: [],
	setPageData: ()=>{
		return false
	},
	getPageData: ()=>{
		return false
	}
}, 'PageInfoContext');

class PageInfoContextProvider extends Component {

	constructor(props) {
		super(props);
	}

	setPageData = (data={}, baseNode)=> {

		if ( !this.props.pageRef.current){
			return;
		}

		const itemsInPage = Array.from(this.props.pageRef.current.querySelectorAll(baseNode.tagName));
		const indexOf = itemsInPage.indexOf(baseNode);

		if( indexOf !== -1){
			let mapItem =	PIDMap.get(this.props.value.pid+'-'+baseNode.tagName+'-'+indexOf);	
			if( mapItem ){
				mapItem ={
					...mapItem,
					...data
				}
			} else {
				mapItem = data;
			}

			PIDMap.set(this.props.value.pid+'-'+baseNode.tagName+'-'+indexOf, mapItem);	
		}
		
	}

	getPageData = (baseNode) => {

		if ( !this.props.pageRef.current){
			return null;
		}
		
		const itemsInPage = Array.from(this.props.pageRef.current.querySelectorAll(baseNode.tagName));
		const indexOf = itemsInPage.indexOf(baseNode);

		if( indexOf !== -1){
			let mapItem =	PIDMap.get(this.props.value.pid+'-'+baseNode.tagName+'-'+indexOf);	
			return mapItem || {}
		}

		return {};

	}

	getContextObject = () => {

		if(this.props.value !== this.lastProps?.value) {

			// only generate new context if the value prop changes
			this.currentContextValue =  {
				...this.props.value,
				setPageData: this.setPageData,
				getPageData: this.getPageData
			}

		}

		this.lastProps = this.props;

		return this.currentContextValue

	}

	render(props) {

		return <PageInfoContext.Provider value={
			this.getContextObject()
		}>
			{props.children}
		</PageInfoContext.Provider>
	}

	componentDidUpdate(prevProps){
		if(prevProps.adminMode !== this.props.adminMode){
			PIDMap.clear();
		}
	}

}

function withPageInfo(WrappedComponent) {

  class EditingHOC extends Component {
		constructor(props) {
			super(props);
		}

		render(props) {
			return <WrappedComponent {...this.props} pageInfo={this.context} />;
		}
  };

  EditingHOC.contextType = PageInfoContext;

  return EditingHOC
}

export default connect(
	(state, ownProps) => {
		return {
			adminMode: state.frontendState.adminMode
		};
	}
)(PageInfoContextProvider);

export { PageInfoContext, withPageInfo}