import { Component , cloneElement, toChildArray, createRef} from "preact";
import { createPortal } from 'preact/compat'
import { ADMIN_FRAME, FRONTEND_DATA } from "../../globals";
import EditorOverlay from "./editor-overlay";
import { editorOverlayAPI } from "./editor-overlay-controller"

import _ from 'lodash';

import SelectionStatus from "./selection-status";

class AudioPlayerEditor extends Component {
	constructor(props){
		super(props);

		this.state= {
			buttonHovered: false,
			UIWindow: null,
			focused: false,
			isDraggingInEditor: false,
			initialShowOutlines: false,
			showTransition: false,
			draggingOver: false,
			showInsertionIndicator: false,
		}

	}

	render(props,state){
		const {
			audioPlayerInstance,
		} = props;

		const {
			draggingOver,
			showInsertionIndicator,
			buttonHovered,
			focused,
		} = state;		
		const buttonHeight = 23;



		return (
			<>
				<style id="audio-player-editor">
					{`::selection{
						background-color:transparent;
					}
					:host(::selection) {
						background-color:transparent;
					}`}
				</style>
			
				<EditorOverlay
					hasFocus={focused}
					onScrollWhileHovered={this.updateUIWindowPosition}				
					trackResize={true}
					baseNode={this.props.audioPlayerInstance}
					render={(overlayProps)=>{

						const {
							pointerAttention,
							overlayPosition
						} = overlayProps;

						let dip = 0;
						if( overlayPosition.y <= 1 ){
							dip = Math.min(-overlayPosition.y +1, overlayPosition.height + -buttonHeight +1);
						}								

						return <>
						
						<SelectionStatus baseNode={audioPlayerInstance} overlayPosition={overlayPosition} inFront={false} overlayMode={true} />
						<div
							className={`editor-overlay editor-outline${pointerAttention ? ' hover': ''}${pointerAttention ? ' button-hover': ''}${focused ? ' focus': ''}`}
							style={{
								transform: `translate3d(${overlayPosition.x}px, ${overlayPosition.y}px, 0)`,
								width: overlayPosition.width +'px',
								height: overlayPosition.height+'px',
								display: (pointerAttention || buttonHovered || focused) ? 'block': 'none',
								minHeight: '27px'
							}}
						>
							<div
								className="in-editor-buttons audio-player"
								style={{
									transform: `translateY(${dip}px)`
								}}
								onPointerEnter={()=>{this.setState({buttonHovered: true})}}
								onPointerLeave={()=>{this.setState({buttonHovered: false})}}
							>
								<button
									onMouseDown={(e)=>{this.launchUIWindow(e)}}
									ref={this.AudioPlayerButtonRef}
									className="text"
								>
									Audio Player
								</button>
								{props.children}
							</div>
							{props.otherUI}
						</div>
						</>
				}}/>

			</>)
	}

	launchUIWindow = (e, galleryRect)=>{

		if (e) {
			if (e.metaKey || e.button == 2){
				return;
			}

			e.preventDefault();
			e.stopPropagation();
		}

		const rect = this.props.audioPlayerInstance.getBoundingClientRect();
		const svgPosition = {
			x: rect.x,
			y: rect.y,
			left: rect.right,
			right: rect.right,
			top: rect.top - 10,
			bottom: rect.top + 27,
			width: rect.width,
			height: 10
		}

		ADMIN_FRAME.adminWindow.UIWindowOpener.openUIWindow({
			windowName: 'audioplayer-ineditor',
			windowAlias: 'audioplayer',
			positionRect: svgPosition,
			closeButton: false,
			closeOnAllClickout: true,
			supportsMobile: false,
			props: {
				audioPlayerNode: this.props.audioPlayerInstance
			}
		});		
	}	

	scrollIntoView =()=>{

		const rect = this.props.audioPlayerInstance.getBoundingClientRect();
		
		if (rect.bottom < 0 || rect.top > window.innerHeight){
			this.props.audioPlayerInstance.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});	
		}		

	}

	updateUIWindowPosition = (scrollDelta)=>{


		if( this.props.onScrollWhileHovered ){
			this.props.onScrollWhileHovered(scrollDelta)
		}

		if(!this.state.UIWindow){
			return;
		}

		this.state.UIWindow.props.superBadScrollHack.scroll(scrollDelta)

	}

	setUIWindow = (UIWindow)=>{
		this.setState({
			UIWindow,
			focused: !!UIWindow,
		})
	}

	componentDidMount = () => {
		this.props.audioPlayerInstance._editorInterface = this;
	}

	componentWillUnmount = () => {
		this.state.UIWindow?.closeWindow?.();
		delete this.props.audioPlayerInstance._editorInterface
	}

}

export default AudioPlayerEditor
