import { collapseOptions } from "../../helpers";

const settings = {
	name: 'gradient',
	options: [
		{
			type: 'custom',
			name: 'gradient-preset'
		},
		{
			type: 'spacer',
		},
		{
			name: 'pageSplit',
			type: 'custom',
			value: null,
		},	
		{
			labelName: "Clip when scrolling",
			name: "clipScroll",
			type: "check-box",
			value: false,
		},
		{
			type: 'spacer',
		},
		{
			name: "gradient-type",
			type: "radio",
			value: "linear",
			values: [
				{
					labelName: 'Linear',
					value: 'linear'
				},
				{
					labelName: 'Radial',
					value: 'radial'
				}				
			],
		},
		{	
			type: 'checkbox-color',
			checkbox: {
				labelName: "Color One",
				name: "color-one-active",
				type: "check-box",
				value: true,
			},
			color: {
				type: 'color',
				labelName: 'Color One',
				name: 'color-one',
				value: '#f2c409',
				requirements: [
					{
						option: 'color-one-active',
						shouldBe: true
					}
				]
			}
		},
		{
			type: 'checkbox-color',
			checkbox: {
				labelName: "Color Two",
				name: "color-two-active",
				type: "check-box",
				value: false,
			},
			color: {
				type: 'color',
				labelName: 'Color Two',
				name: 'color-two',
				value: '#02ff00',
				requirements: [
					{
						option: 'color-two-active',
						shouldBe: true
					}
				]
			},
		},
		{
			type: 'checkbox-color',
			checkbox: {
				labelName: "Color Three",
				name: "color-three-active",
				type: "check-box",
				value: true,
			},		
			color: {
				type: 'color',
				labelName: 'Color Three',
				name: 'color-three',
				value: '#fe2201',
				requirements: [
					{
						option: 'color-three-active',
						shouldBe: true
					}
				]
			},
		},
		{type: 'spacer'},
		// radial options

		{
			type: 'group',
			className: 'grid-columns-even',
			requirements: [
				{
					option: 'gradient-type',
					shouldBe: 'radial'
				}
			],			
			children: [
				{
					labelName: "X",
					name: "pos-x",
					type: "scrubber",
					numberOnlyMode: true,
					suffix: '%',	
					requirements: [
						{
							option: 'gradient-type',
							shouldBe: 'radial'
						}
					],
					value: 0,
					min: -150,
					max: 150,
					step: 0.5,
				},			
				{
					labelName: "Y",
					name: "pos-y",
					type: "scrubber",
					numberOnlyMode: true,
					suffix: '%',	
					requirements: [
						{
							option: 'gradient-type',
							shouldBe: 'radial'
						}
					],
					value: 0,
					min: -150,
					max: 150,
					step: 0.5,
				},
			]
		},		
	
		// linear-only options
		{
			labelName: "Rotation",
			name: "rotation",
			type: "scrubber",
			suffix: '°',
			requirements: [
				{
					option: 'gradient-type',
					shouldBe: 'linear'
				}
			],
			loopValues: true,
			numberOnlyMode: true,
			value: 0,
			min: 0,
			max: 360,
			step: 1,
		},

		// shared options		
		{
			labelName: "Smoothness",
			name: "smoothness",
			type: "scrubber",
			numberOnlyMode: true,
			suffix: '%',
			value: 50,
			min: 0,
			max: 100,
			step: 0.1,
		},
		{
			labelName: "Scale",
			name: "scale",
			type: "scrubber",
			numberOnlyMode: true,
			suffix: '%',			
			value: 50,
			min: .1,
			max: 100,
			step: 0.1,
		},
		{
			type: 'group',
			className: 'grid-columns-even',
			children: [
				{
					labelName: "Repeat",
					name: "repeat",
					type: "check-box",
					value: false,
				},			
				{
					labelName: "Animation",
					name: "animation-speed",
					type: "range-input",
					requirements: [
						{
							option: 'repeat',
							shouldBe: true
						},
					],
					renderWhenDisabled: true,
					value: 0,
					min: -100,
					max: 100,
					step: 1,
				},
			]
		},

	]
}

settings.defaults = collapseOptions(settings.options);
settings.customShuffle = (shuffledSettings)=>{

	let shuffle = {...shuffledSettings};

	let r = Math.floor(Math.random()*256);
	let g = Math.floor(Math.random()*256);
	let b = Math.floor(Math.random()*256);

	// 20% chance of choosing a gray color - black, white or gray
	let grayRandom = Math.floor((Math.random()*20));
	switch(grayRandom){
		case 0:
			r = g = b = 0;
			break;

		case 1:
			r = g = b = 255;
			break;

		case 2:
		case 3:
			r = g = b;
			break;
	}

	shuffle['color-one'] = "rgb("+r+", "+g+", "+b+")";

	let transforms = [];

	if( Math.random() > .3){
		for (var i = 0; i < 3; i++ ) {
			transforms.push(Math.random()*240 + -120);
		}
	} else {
		transforms[0] = transforms[1] = transforms[2] = Math.random()*240 + -120;
	}


	let r2 = Math.floor( (r+transforms[0] + 1024 ))%256;
	let g2 = Math.floor( (g+transforms[1] + 1024 ))%256;
	let b2 = Math.floor( (b+transforms[2] + 1024 ))%256;
	shuffle['color-two'] = "rgb("+r2+", "+g2+", "+b2+")";

	let r3 = Math.floor( (r+transforms[0] + 1024 )*2)%256;
	let g3 = Math.floor( (g+transforms[1] + 1024 )*2)%256;
	let b3 = Math.floor( (b+transforms[2] + 1024 )*2)%256;
	shuffle['color-three'] = "rgb("+r3+", "+g3+", "+b3+")";

	shuffle['color-one-active'] = true;

	if( Math.random() > .4 ){
		shuffle['color-two-active'] = true;	
		shuffle['color-three-active'] = true;
	} else if( Math.random() > .4 ){
		shuffle['color-two-active'] = true;	
		shuffle['color-three-active'] = false;	
	} else {
		shuffle['color-three-active'] = true;
	}
	

	if ( Math.random() > .5 ){
		shuffle['animation-speed'] = 0;
	}

	if( Math.random() > .5){
		shuffle['rotation'] = Math.round(shuffle['rotation']/45)*45
	}

	if( Math.random() > .5){
		shuffle['pos-x'] = 0;
	}

	if( Math.random() > .5){
		shuffle['pos-y'] = 0;
	}

	if( Math.random() > .8){
		shuffle['smoothness'] = 100;
	}		

	return shuffle;

};

export default settings;
