import { Component } from 'preact';
import _ from 'lodash';

const eyeRolls = new Map();

const eyeBallRotation = (e)=>{

	let eyesInUnison = [];
	let xPositions = [];
	let yPositions = [];
	eyeRolls.forEach((eyeRoll, baseNode)=>{

		eyeRoll.locateEyeball();

		const {
			rect
		} = eyeRoll.state;		

		if( eyeRoll.props['roll-independently'] == true ){

			const angle = Math.atan2(e.clientY - (rect.top+rect.height*.5), e.clientX - (rect.left+rect.width*.5))* (180/Math.PI) +90
			eyeRoll.setState({
				eyeRotation: angle
			})

		} else {
			xPositions.push(rect.left + rect.width*.5);
			yPositions.push(rect.top + rect.height*.5);
			eyesInUnison.push(eyeRoll);
		}

	})

	let pos = {
		x: _.mean(xPositions),
		y: _.mean(yPositions)
	}

	eyesInUnison.forEach((eyeRoll)=>{
		const angle = Math.atan2(e.clientY - pos.y, e.clientX - pos.x)* (180/Math.PI) +90
		eyeRoll.setState({
			eyeRotation: angle
		})
	});

}

class EyeRoll extends Component {
	constructor(props){
		super(props);
		this.state = {
			eyeRotation: 0,
			rect: {
				top: 0,
				left: 0,
				width: 0,
				height: 0,
			},
			centerPoint: {
				x: 0,
				y: 0,
			}
		}
		this.locateEyeball = _.debounce(this.locateEyeball, 120, {leading: true, trailing: false})
	}

	render(props, state){
		return (
			<>
			{props.children}
			<style>
				{`
					:host { 
						pointerEvents: ${props.lowestLevel ? 'auto' : 'none'};
						transformOrigin: center center;
						--rotate-transform: rotate( ${this.state.eyeRotation}deg);
					}
				`}
			</style>
			</>
		)
		// return<span style={{
		// 	pointerEvents: props.lowestLevel ? 'auto' : 'none',
		// 	display: 'inline-block',
		// 	'transformOrigin': 'center center',
		// 	'transform': `rotate(${this.state.eyeRotation}deg)`
		// }}>{props.children}</span>
	}

	locateEyeball = () =>{
		this.setState({
			rect: this.props.baseNode.getBoundingClientRect(),
		});
	}

	componentDidMount(){

		if( eyeRolls.size == 0){
			window.addEventListener('pointermove', eyeBallRotation);
		}

		eyeRolls.set(this.props.baseNode, this);
	}

	componentWillUnmount(){

		eyeRolls.delete(this.props.baseNode);

		if( eyeRolls.size === 0){
			window.removeEventListener('pointermove', eyeBallRotation);
		}
	}
}

EyeRoll.defaultProps = {
	'roll-independently': true
}

EyeRoll.transformSlot = '--rotate-transform'

export default EyeRoll;