import { Component } from 'preact';
import _ from 'lodash';
import { effectsBorderDefaults } from '../../../../../admin/src/defaults/border-defaults';

class Blink extends Component {
	constructor(props){
		super(props);
	}

	calculateDuration(speed) {
		const percent = (Math.max((100 - Math.abs(speed)), 5)) * 0.01;
		const total = 5;
		return (total * percent);
	}

	render(props, state){

		const animationDuration = this.calculateDuration(Number(this.props['blink-speed']));
		const timeOn = Math.max(99.9 - this.props['blink-pulse'], 0.1);

		let timingFunction = 'steps(1)';
		switch (true) {
			case (isNaN(Number(this.props['blink-transition'])) && this.props['blink-transition'] == 'linear'):
				timingFunction = 'linear';
				break;
			case (isNaN(Number(this.props['blink-transition'])) && this.props['blink-transition'] == 'ease'):
				timingFunction = 'ease';
				break;
			case (Number(this.props['blink-transition']) > 0): 
				timingFunction = 'ease';
				break;
		}

			

		return (
			<>
				{props.children}
				<style>{`
					:host {
						animation: ${animationDuration}s infinite ${timingFunction} blink;
						-webkit-animation: ${animationDuration}s infinite ${timingFunction} blink;
					}
					
					@keyframes blink {
						0% {
							opacity: 0;
						}
						50% {
							opacity: 1;
						}
						100% {
							opacity: 0;
						}
					}
				`}</style>
			</>
		)

	}
}


Blink.defaultProps = {
	'blink-transition': '0',
	'blink-speed': '75',
	'blink-pulse': '50',
};

export default Blink