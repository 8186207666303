import { Component, createRef } from "preact";
import * as helpers from "@cargo/common/helpers";
import _ from 'lodash';
import { subscribe, unsubscribe, dispatch } from '../../../customEvents';

let resizeObserver;

if(!helpers.isServer) {
	resizeObserver = new ResizeObserver(function(entries){
		entries.forEach(function(entry){
			dispatch(entry.target, 'reference-resize',
				{
					target: entry.target,
					width: entry.contentRect.width,
				},
				{
					bubbles: false,
					cancelable: true,
					composed: true,
				}
			);
		});
	});
}

class ResizeCard extends Component {

	constructor(props){
		super(props);

		this.elementRef = createRef();
		this.elementRef.current = {};
		this.saveRef = key => r => { this.elementRef.current[key] = r }
	}

	render(){

		const {
			values = {elWidth:'100%'}
		} = this.props;

		return (
			<div 
				tabIndex="-1"
				data-nosnippet
				style={{
					height: 0,
					width: '100%',
					minWidth: '100%',
					overflow: 'hidden',
					display: 'block',
					alignSelf: 'flex-start',
					visibility: 'hidden',
					pointerEvents: 'none',
					boxSizing: 'border-box',
					zIndex: -1,
					...this.props.style			
				}}
			>
			{Object.keys(values).map((key)=>{

				// value can either be a single value, which will assign to width
				// or can be a separate style

				const elStyle = values[key];

				return <div
					tabIndex="-1"
					ref={this.saveRef(key)}
					style={typeof elStyle === 'object' ? elStyle : {
						width: elStyle
					}} />
			})}
			</div>
		)
	}

	componentDidUpdate(prevProps, prevState){

	}

	onResize =(data)=>{
		let resizeKey = Object.keys(this.elementRef.current).find(key=>{
			return this.elementRef.current[key] === data.target
		});

		if(this.props.onResize){
			this.props.onResize(resizeKey, data.width);
		}
	}

	componentDidMount(){
		Object.keys(this.elementRef.current).forEach(key=>{
			const el = this.elementRef.current[key];
			subscribe(el, 'reference-resize', this.onResize);
			resizeObserver?.observe(el);
		})
	}

	componentWillUnmount(){
		Object.keys(this.elementRef.current).forEach(key=>{
			const el = this.elementRef.current[key];
			resizeObserver?.unobserve(el);
			unsubscribe(el, 'reference-resize', this.onResize);
		})		
	}
}

ResizeCard.defaultProps = {
	values: {
		elWidth: '100%'
	}
}

export default ResizeCard
