import { collapseOptions } from "../../../helpers";
import _ from 'lodash';
import tinycolor from 'tinycolor2';

// {
//     "slices": 18,
//     "zoom": 217,
//     "baseRotation1": 61,
//     "rotationSpeed1": -8,
//     "baseRotation2": -148,
//     "rotationSpeed2": -8,
//     "rotationCenterX": 21,
//     "rotationCenterY": 66,
//     "mirrorSlices": true,
//     "pinch": false,
//     "hasMouseInteraction": true,
//     "image": "https://static.cargo.site/assets/backdrop/default_1024.jpg",
//     "mirrorImageX": true,
//     "mirrorImageY": true,
//     "hasMotionInteraction": true,
//     "image_size" : 1024,
//     "width" : 1500,		// This is the size of the default image
//     "height" : 1500		// This is the size of the default image
// }


const onChangeCustom = (prevSettings, newSettings, backdropView)=>{

	const changes = {};
	for (const [key, setting] of Object.entries(newSettings) ) {
		if(prevSettings[key] != setting){
			changes[key] = setting
		}
	}

	for (const [name, value] of Object.entries(changes) ) {

		let do_update = true;
		if(name === "image"){
			if(value !== undefined && value ) {
				backdropView.obj.loadImage( value );
			} else {
				do_update = false;
			}
		}

		if(name !== undefined && do_update === true){

			let newSettings = {};

			newSettings[name] = value;

			backdropView.Update(name, value)

			backdropView.obj.updateSettings(newSettings);

		}
		
	}
}

const settings = {
	name: 'kaleidoscope',
	options: [
		{
			labelName: "Image",
			name: "image",
			type: "image",
			value: '7e333180710e85119b159e6bf7711aed653bf5ee8339ed26c74cae1f031548bc'
		},
		{
			type: 'spacer',
		},
		{
			name: 'pageSplit',
			type: 'custom',
			value: null,
		},			
		{
			labelName: "Clip when scrolling",
			name: "clipScroll",
			type: "check-box",
			value: false,
		},
		{
			type: 'spacer'
		},	
		{
			labelName: "Slices",
			name: "slices",
			type: "range-input",
			value: 18,
			min: 2,
			max: 256,
			step: 1
		},
		{
			labelName: "Zoom",
			name: "zoom",
			type: "range-input",
			value: 217,
			suffix: '%',
			min: 1,
			max: 500,
			step: 1
		},
		{
			labelName: "Rotation",
			name: "baseRotation1",
			type: "range-input",
			value: 61,
			suffix: '°',
			min: -180,
			max: 180,
			step: 1
		},
		{
			labelName: "Rotation Speed",
			name: "rotationSpeed1",
			type: "range-input",
			value: -8,
			suffix: '°/sec',
			min: -100,
			max: 100,
			step: 1
		},
		{
			labelName: "Image Rotation",
			name: "baseRotation2",
			type: "range-input",
			value: -148,
			suffix: '°',
			min: -180,
			max: 180,
			step: 1
		},
		{
			labelName: "Image Rotation Speed",
			name: "rotationSpeed2",
			type: "range-input",
			value: -8,
			suffix: '°/sec',
			min: -100,
			max: 100,
			step: 1
		},
		{
			labelName: "Image Rotation Anchor X",
			name: "rotationCenterX",
			type: "range-input",
			value: 21,
			suffix: '°/sec',
			min: -300,
			max: 300,
			step: 1
		},
		{
			labelName: "Image Rotation Anchor Y",
			name: "rotationCenterY",
			type: "range-input",
			value: 66,
			suffix: '°/sec',
			min: -300,
			max: 300,
			step: 1
		},
		{
			type: 'spacer'
		},
		{
			labelName: "Mirror Slices",
			name: "mirrorSlices",
			type: "check-box",
			value: true
		},
		{
			labelName: "Mouse Interaction",
			name: "hasMouseInteraction",
			type: "check-box",
			value: true
		},
	]
}

settings.transformChanges= (settings, changes)=>{

	if(changes.color) {
		const color = tinycolor(changes.color);

		changes['hex'] = color.toHexString();
		changes['alpha'] = color.getAlpha();
	}

	return changes;
}

settings.defaults = collapseOptions(settings.options);

// settings with no ui, used only for defaults
settings.defaults.image_url = "//static.cargo.site/assets/backdrop/default_1024.jpg";

export default settings;

export {onChangeCustom}
