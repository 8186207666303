import cargoLoader from './loaders/cargo';
import googleLoader from './loaders/google';

export default config => {

	if(!config) {
		throw 'No config given';
	}

	// defaults
	config.context = config.context || window;
	config.active = config.active || function(){};

	const loaderPromises = [];

	loaderPromises.push(
		googleLoader(config, config.families?.reduce?.((families, font) => {
			if(font.provider === 'google') {
				families.push(font.family)
			}
			return families;
		}, []))
	);

	loaderPromises.push(
		cargoLoader(config, config.families?.reduce?.((families, font) => {
			if(font.provider === 'cargo') {
				families.push(font.family)
			}
			return families;
		}, []))
	);

	return Promise.all(loaderPromises)
		.catch((e) => {
			console.log('something went wrong while loading the site\'s fonts...', e);
		})
		.finally((result) => {
			// run active callback rain or shine for now
			config.active?.(result);
		});

}