import site from "./site";
import pages, { rawReducers as rawPageReducers } from "./pages";
import sets, { rawReducers as rawSetReducers } from "./sets";
import commerce, { rawReducers as rawCommerceReducers } from "./commerce";
import media from "./media";
import css from "./css";
import structure from "./structure";
import auth from "./auth";
import siteDesign from "./site-design";
import frontendState from "./frontend-state";

import { actionTypes } from "../actions";

export default {
	structure,
	site,
	pages,
	sets,
	media,
	css,
	auth,
	siteDesign,
	frontendState,
	commerce
}

/*
 * These raw reducer exports are for admin use. We sometimes need to wrap
 * individual reducer slices.
 */

export const rawReducers = {
	pages: rawPageReducers,
	sets: rawSetReducers,
	commerce: rawCommerceReducers
}