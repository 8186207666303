import { collapseOptions } from "../../../helpers";
import _ from 'lodash';
import tinycolor from 'tinycolor2';
// "hex":"#000000",
// "alpha":0,
// "mouse_interaction":true,
// "mouse_sensitivity":6,
// "image_url": "/_jsapps/backdrop/default_1024.jpeg",
// "image":'',
// "image_size":1024,
// "requires_webgl":"true",
// "rows":64,
// "columns":64,
// "zoom":100,
// "horiz_stretch":100,
// "vert_stretch":97,
// "layout_mode":"grid_mode",
// "grid_size":44,
// "flip_it":false,
// "elasticity":35,
// "stir_freq":74,
// "stir_strength":4,
// "stir_disorder":76,
// "tolerance":86,
// "mouse_zoom":0,
// "stir_grid":true,
// "posterize":false,
// "plugin_id":23,
// "size":"cover"

const onChangeCustom = (prevSettings, newSettings, backdropView)=>{

	const changes = {};
	for (const [key, setting] of Object.entries(newSettings) ) {
		if(prevSettings[key] != setting){
			changes[key] = setting
		}
	}

	for (const [name, value] of Object.entries(changes) ) {

		backdropView.Update(name, value);	
		
	}
}

const settings = {
	name: 'pixelation',
	options: [
		{
			labelName: "Image",
			name: "image",
			type: "image",
			value: '7e333180710e85119b159e6bf7711aed653bf5ee8339ed26c74cae1f031548bc'
		},
		{
			type: 'spacer',
		},
		{
			name: 'pageSplit',
			type: 'custom',
			value: null,
		},			
		{
			labelName: "Clip when scrolling",
			name: "clipScroll",
			type: "check-box",
			value: false,
		},
		{
			type: 'spacer'
		},			
		{
			labelName: undefined,
			name: "layout_mode",
			type: "radio",
			value: "grid_mode",
			values: [
				{
					labelName: 'Grid',
					value: 'grid_mode'
				},
				{
					labelName: 'Rows',
					value: 'row_mode'
				},
				{
					labelName: 'Columns',
					value: 'column_mode'
				}
			],
		},
		{
			labelName: "Rows",
			name: "rows",
			type: "range-input",
			value: 44,
			min: 1,
			max: 64,
			step: 1,
			allowUnclampedTextEntry: false,
			requirements: [
				{
					option: 'layout_mode',
					shouldBe: 'row_mode'
				}
			]
		},
		{
			labelName: "Columns",
			name: "columns",
			type: "range-input",
			value: 44,
			min: 1,
			max: 64,
			step: 1,
			allowUnclampedTextEntry: false,
			requirements: [
				{
					option: 'layout_mode',
					shouldBe: 'column_mode'
				}
			]
		},
		{
			labelName: "Subdivisions",
			name: "grid_size",
			type: "range-input",
			value: 44,
			min: 1,
			max: 100,
			step: 1,
			allowUnclampedTextEntry: false,
			requirements: [
				{
					option: 'layout_mode',
					shouldBe: 'grid_mode'
				}
			]			
		},
		{
			labelName: "Cell Image Zoom",
			name: "zoom",
			type: "range-input",
			value: 100,
			suffix: '%',
			min: 1,
			max: 100,
			step: 1
		},
		{
			labelName: "Flip Alternate Cells",
			name: "flip_it",
			type: "check-box",
			value: false
		},
		{
			type: 'spacer'
		},
		{
			labelName: "Turbulence",
			name: "stir_grid",
			type: "check-box",
			value: true
		},
		{
			labelName: "Stir Frequency",
			name: "stir_freq",
			type: "range-input",
			value: 74,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1,
			requirements: [
				{
					option: 'stir_grid',
					shouldBe: true
				}
			]
		},
		{
			labelName: "Stir Strength",
			name: "stir_strength",
			type: "range-input",
			value: 4,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1,
			requirements: [
				{
					option: 'stir_grid',
					shouldBe: true
				}
			]
		},
		{
			labelName: "Disorder",
			name: "stir_disorder",
			type: "range-input",
			value: 4,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1,
			requirements: [
				{
					option: 'stir_grid',
					shouldBe: true
				}
			]
		},
		{
			labelName: "Motion Elasticity",
			name: "elasticity",
			type: "range-input",
			value: 35,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1
		},
		{
			type: 'spacer'
		},
		{
			labelName: "Overlay Color",
			name: "color",
			type: "color",
			value: '#ffffff00',
			excludeFromShuffle: true,
		},
		{
			type: 'spacer'
		},
		{
			labelName: "Mouse Interaction",
			name: "mouse_interaction",
			type: "check-box",
			value: true,
		},
		{
			labelName: "Mouse Movement Force",
			name: "mouse_sensitivity",
			type: "range-input",
			value: 6,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1,
			requirements: [
				{
					option: 'mouse_interaction',
					shouldBe: true
				}
			]
		},
		{
			labelName: "Mouse Radius",
			name: "tolerance",
			type: "range-input",
			value: 50,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1,
			requirements: [
				{
					option: 'mouse_interaction',
					shouldBe: true
				}
			]
		},
		{
			labelName: "Mouseover Zoom",
			name: "mouse_zoom",
			type: "range-input",
			value: 0,
			suffix: '%',
			min: 0,
			max: 100,
			step: 1,
			requirements: [
				{
					option: 'mouse_interaction',
					shouldBe: true
				}
			]
		},

	]
}

settings.defaults = collapseOptions(settings.options);

// settings with no ui, used only for defaults

settings.defaults.image_url = "//static.cargo.site/assets/backdrop/default_1024.jpg";

settings.defaults.rgb = {
	value: 'rgb(255,255,255)'
}
settings.transformChanges= (settings, changes)=>{

	if( changes.hasOwnProperty("color") && changes?.color === null ){
		changes.color = 'rgba(0,0,0,0)';
	}
	
	if(changes.color) {
		const color = tinycolor(changes.color);

		changes['hex'] = color.toHexString();
		changes['alpha'] = color.getAlpha();
	}

	return changes;
}
settings.customShuffle = (settings) =>{
	if ( Math.random() > 0.5){
		settings.zoom = 100;
	}
	return settings;
}

export default settings;

export {onChangeCustom}
