import { Component, createElement, toChildArray, createRef, Fragment } from "preact";
import { editorOverlayAPI } from "./editor-overlay-controller"
import _ from 'lodash'


const selectionQueue = [];
const checkSelection = _.debounce(()=>{

	if ( CargoEditor && Object.keys(CargoEditor).length > 0 ){

		var activeRange = CargoEditor.getActiveRange();
		var shadowDomRangeInfo = CargoEditor.helpers.normalizeRangeAroundShadowDom(activeRange);

		let toSelect = [];
		let toDeselect = [];

		if( activeRange && !activeRange.collapsed){
			var selectedElements = CargoEditor.helpers.getAllEffectivelyContainedNodes(activeRange);
			selectedElements = selectedElements.filter(el=>el.shadowRoot);

			selectionQueue.forEach((component)=>{
				if( selectedElements.includes(component.props.baseNode) ){
					toSelect.push(component);
				} else {
					toDeselect.push(component);
				}
			});
			
		} else {
			toDeselect = selectionQueue;			
		}

		toDeselect.forEach(component=>{
			component.setState({
				selected: false
			})			
		});
		toSelect.forEach(component=>{
			component.setState({
				selected: true
			});
			if( CargoEditor.plugins.shadowDom.shadowDomSelectedElements.indexOf(component.props.baseNode) == -1){
				CargoEditor.plugins.shadowDom.shadowDomSelectedElements.push(component.props.baseNode);
			}
		});

		if (shadowDomRangeInfo.shadowDomElementAdjacentToCollapsedRange ){
			const collapsedRangeComponent = selectionQueue.find(component=> component.props.baseNode ===shadowDomRangeInfo.shadowDomElementAdjacentToCollapsedRange );
			if( collapsedRangeComponent ){
				collapsedRangeComponent.setState({
					selected: true,
					collapseDirection: shadowDomRangeInfo.collapseDirection
				});
			}
		}

		selectionQueue.length = 0;


	}		
}, 30);


class SelectionStatus extends Component {

	constructor(props) {
		super(props);

		this.state = {
			resetAnimation: false,
			selected: false,
			collapseDirection: false,
			currentColor: 'black',
		}

		// selection elements that live in the editor overlay need a unique name
		this.uniqueClassName = this.props.overlayMode ? 'selection-status-'+_.uniqueId() : 'selection-status';

	}

	

	render(props, state){

		const collapsedClass = state.collapseDirection ? ' '+state.collapseDirection + ' collapsed-range' : ''

		return <Fragment key="selection-status-fragment">
		<style id="selection-status">{
		`
		${(this.state.selected && !this.props.onlyAllowCollapsedRange) ? `
		::slotted(*) {
			--selection-color: transparent;
		}
		`: ''}

		${!this.props.onlyAllowCollapsedRange ?
		`::selection{
			background-color:transparent;
		}
		:host(::selection) {
			background-color:transparent;
		}` : ''}

		.${this.uniqueClassName} {
			${this.props.overlayMode? `
				transform: translate3d(${this.props.overlayPosition.x}px, ${this.props.overlayPosition.y}px, 0);
				width: ${this.props.overlayPosition.width}px;
				height: ${this.props.overlayPosition.height}px;
				position: fixed;
				top: 0;
				left: 0;
			`: `
				inset: 0px;
				position: absolute;
			`}
			padding: 0;
			margin: 0;
			opacity: 1;
		    background-color: var(--selection-color);
			overflow:hidden;

			z-index: ${this.props.inFront ? '99999' : '-1'};
			pointer-events:none;
			display:none;
		}

		.${this.uniqueClassName}.selected {
			display:block;
		}

      
		@keyframes blink-animation {
			0%, 49.99% {
			  visibility: visible;
			}
			50%, 100% {
				visibility: hidden;
			}
		}
		@-webkit-keyframes blink-animation {
			0%, 49.99% {
			  visibility: visible;
			}
			50%, 100% {
				visibility: hidden;
			}			
		}		

		.${this.uniqueClassName}.collapsed-range {
			width: 1px;
		    mix-blend-mode: unset;
			background-color: ${this.props.overlayMode ? this.state.currentColor : 'currentColor'};

		    ${this.state.resetAnimation? `
		    visibility: visible;
		    -webkit-animation:none;
		    animation: none;`:
		    `-webkit-animation: blink-animation 1s steps(4, start) infinite;
	        animation: blink-animation 1s steps(4, start) infinite;`}
	        z-index: 9999;
		}

		.${this.uniqueClassName}.before.collapsed-range {
			${this.props.overlayMode? `
			transform: translate3d(${this.props.overlayPosition.x+-1}px, ${this.props.overlayPosition.y}px, 0);			
			`: `
			inset: 0 auto 0 -1px;
			`}
				
		}
		.${this.uniqueClassName}.after.collapsed-range {
			${this.props.overlayMode? `
			transform: translate3d(${this.props.overlayPosition.x + this.props.overlayPosition.width +1}px, ${this.props.overlayPosition.y}px, 0);			
			`: `
			inset: 0 -1px 0 auto;
			`}
		}		

		`}</style>
			<div
				className={`${this.uniqueClassName}${ this.state.selected ? ' selected':''}${collapsedClass}` }
			></div>
		</Fragment>

	}

	componentDidMount() {

		if( CargoEditor?.getActiveEditor?.() ){
			selectionQueue.push(this);
			checkSelection();
		}


		this.props.baseNode.__shadow_selection = true;	
		
		this.props.baseNode.addEventListener('selected-in-editor', this.select)
		this.props.baseNode.addEventListener('unselected-in-editor', this.unselect)

	}

	componentDidUpdate(prevProps, prevState){
		if( this.state.collapseDirection !== prevState.collapseDirection){
			this.setState({
				resetAnimation: true
			}, ()=>{
				this.resetTimeout = setTimeout(()=>{
					this.setState({
						resetAnimation: false
					});
				}, 100)

			})
		}
	}

	componentWillUnmount(){
		clearTimeout(this.resetTimeout);
		delete this.props.baseNode.__shadow_selection;
		this.props.baseNode.removeEventListener('unselected-in-editor', this.unselect)		
		this.props.baseNode.removeEventListener('selected-in-editor', this.select)		
	}


	select = (e)=>{
		if( e.target !== this.props.baseNode){
			return;
		}

		if( this.props.overlayMode && !this.state.selected){
			editorOverlayAPI.updateElementRect(this.props.baseNode)
			this.updateCurrentColor();
		}

		let collapsed = e?.detail?.collapsed || false;
		let selected = true;
		if( this.props.onlyAllowCollapsedRange ){
			selected = collapsed 
		}
		this.setState({
			selected: selected,
			collapseDirection: collapsed
		})

		if( this.props.onSelectionChange){
			if( !collapsed  ){
				this.props.onSelectionChange(true)				
			} else {
				this.props.onSelectionChange(false)				
			}

		}
	}

	unselect = ()=>{

		this.setState((prevState)=>{
			
			return {
				selected: false,
				collapseDirection: false,
			}
		})

		if( this.props.onSelectionChange ){
			this.props.onSelectionChange(false)
		}
	}

	updateCurrentColor = ()=>{
		if( this.props.baseNode.shadowRoot){
			const testSpan = document.createElement('span');
			testSpan.style.color = 'currentColor';
			testSpan.style.position = 'absolute';
			this.props.baseNode.shadowRoot.appendChild(testSpan);
			const style = window.getComputedStyle(testSpan);
			const color = style.getPropertyValue('color');
			testSpan.remove();
			this.setState({
				currentColor: color
			})
		}
	}

}

SelectionStatus.defaultProps = {
	inFront: true, // whether the 'selection' is rendered over the item (most items) or behind ( text icons )
	overlayMode: false, // elements with overflow:hidden need to render in the overlay instead. so they need additional positioning and need to sample currentColor
}

export default SelectionStatus;
