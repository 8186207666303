export * as wallpaper from './wallpaper/settings';
export * as gradient from './gradient/settings';
export * as badtv from './legacy/badtv/settings';
export * as parallax from './legacy/parallax/settings';
export * as refraction from './legacy/refraction/settings';
export * as ripple from './legacy/ripple/settings';
export * as slitscan from './legacy/slitscan/settings';
export * as pixelation from './legacy/pixelation/settings';
export * as kaleidoscope from './legacy/kaleidoscope/settings';
export * as polygon_engine from './legacy/polygon_engine/settings';
export * as morphovision from './legacy/morphovision/settings';
export * as wave from './legacy/wave/settings';
export * as halftone from './legacy/halftone/settings';
export * as video from './legacy/video/settings';