import _ from 'lodash';
import * as helpers from "@cargo/common/helpers";

import { actionTypes } from "../actions";

const defaultState = {
	authenticating: false,
	authenticated: false,
}

function authReducer(state = defaultState, action) {

	switch(action.type) {

		case actionTypes.AUTHENTICATE_USER_PENDING: 
			
			return {
				...state,
				authenticating: true
			}

		case actionTypes.AUTHENTICATE_USER_FULFILLED:

			if(action.payload?.data?.access_token) {

				if(!helpers.isServer) {
					try {
						localStorage.setItem('c3-auth', JSON.stringify(action.payload.data));
					} catch(e) { console.error(e); }
				}

				return {
					...state,
					authenticating: false,
					authenticated: true,
					data: action.payload.data
				}

			} else {

				if(!helpers.isServer) {
					try {
						localStorage.removeItem('c3-auth');
					} catch(e) { console.error(e); }
				}

				return {
					...state,
					authenticating: false,
					authenticated: false
				}

			}

		case actionTypes.AUTHENTICATE_USER_REJECTED: 

			if(!helpers.isServer) {
				try {
					localStorage.removeItem('c3-auth');
				} catch(e) { console.error(e); }
			}

			return {
				...state,
				authenticating: false,
				authenticated: false
			}

	}

	return state;
};

export default authReducer;