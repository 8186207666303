import { Component, createElement, toChildArray, createRef } from "preact";
import { connect } from 'react-redux';
import Image from './image';
import IframeVideo from './iframe-video';

import { getImageWithOptions} from "../../helpers";
import * as helpers from "@cargo/common/helpers";

import { forwardRef } from 'preact/compat';
import _ from 'lodash';

class Video extends Component {

	constructor(props) {

		super(props);

		this.nullPlayer = {
			play: ()=>{ return new Promise((resolve, reject)=>{ reject('null player play')}) },
			pause: ()=>{ return new Promise((resolve, reject)=>{ reject('null player pause')}) },
			stop: ()=>{ return new Promise((resolve, reject)=>{ reject('null player stop')}) },
			getDuration: ()=>{ return new Promise((resolve, reject)=>{ reject('null player getDuration')}) },
			getCurrentTime: ()=>{ return new Promise((resolve, reject)=>{ reject('null player getCurrentTime')}) },
			setCurrentTime: ()=>{ return new Promise((resolve, reject)=>{ reject('null player setCurrentTime')}) },
			getVolume: ()=>{ return new Promise((resolve, reject)=>{ reject('null player getVolume')}) },
			setVolume: ()=>{ return new Promise((resolve, reject)=>{ reject('null player setVolume')}) },
			setPlaybackRate: ()=>{ return new Promise((resolve, reject)=>{ reject('null player setPlaybackRate')}) },
			size: {
				width: 0,
				height: 0,
			},				
		}		

		this.state = {
			playing: false,
			paused: true,
			stopped: true,
			hasPlayedThrough: false,
			interacted: false,
			hasAttention: false,
			videoLoaded: false,
			posterLoaded: false,
			activePlayer: this.nullPlayer		
		}


		this.posterRef = createRef();
		this.videoRef= createRef();
		this.videoPlayerRef= createRef();
	}
	blankURI = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

	render(props){
		const {
			model,
			posterModel,
			adminMode,
			isZoomable,
			alt,
			hash,
			src,
			fileType,
			decoding,
			'browser-default': browserDefault,
			'dynamic-src': dynamicSrc,
		} = props;

		const width = props.width ?? model?.width ?? 1600;
		const height =  props.height ?? model?.height ?? 1600;

		const nodeSrc = dynamicSrc || src || '';
		let modelSrc = null;
		if( model ){
			if( model.is_url){
				modelSrc = model.url;
			} else {
				modelSrc = 'https://freight.cargo.site/i/' + model['hash'] + '/' + model['name'] ;
			}
		} 
		let usableSrc = '';

		if( model?.loading || !modelSrc ){
			usableSrc = nodeSrc || '';
		} else {
			usableSrc = modelSrc || '';
		}

		const isPlayingWithAudio = !this.props.muted && !this.props.autoplay && this.state.playing;


		if( fileType ==='video' ){
			usableSrc = (isPlayingWithAudio || this.props.isLazyLoadable) ? usableSrc : '';
		} 

		
		// poster can come from video model, or poster attribute
		// poster attribute can be hash or it can be an image url
		// posterModel is passed through by media-item
		
		let usablePosterSrc = null;

		if( posterModel && !posterModel.loading ){

			// video was somehow assigned a video model for its poster - push into the poster model
			// to find the desired image info
			if( posterModel.is_video && posterModel.poster ){

				usablePosterSrc = getImageWithOptions({
					w: this.props.renderWidth,
				}, posterModel.poster ).url || '';
			}else {
				usablePosterSrc = getImageWithOptions({
					w: this.props.renderWidth,
				}, posterModel ).url || '';				
			}

		} else if ( model && model.poster && !model.poster.loading ){
			usablePosterSrc = getImageWithOptions({
				w: this.props.renderWidth,
			}, model.poster ).url || ''; 
		} else if ( this.props['dynamic-poster'] || this.props.poster ){
			usablePosterSrc = this.props['dynamic-poster'] || this.props.poster || null;
			usablePosterSrc = usablePosterSrc.startsWith('https://') ? usablePosterSrc : '';
		}
		usablePosterSrc = this.props.isLazyLoadable ? usablePosterSrc : '';
		
		const statusPartAppendix = this.state.playing ? '-playing' : this.state.stopped ? '-stopped' : this.state.paused ? '-paused' : '';

		const showPosterOverPlayer = !browserDefault

		/*
			['browser-default'] will print the poster image inside its poster attribute
			otherwise we start printing an actual ui - now it's just the poster, but we should add playhead, buttons, etc
			making this separate from the actual video player lets us whitelabel vimeo with our own posters/ui
		*/
		return <div
			onPointerEnter={this.onPointerEnter}
			onPointerOut={this.onPointerOut}			
			part={'media video-player' + (statusPartAppendix ? ' video-player'+statusPartAppendix : '')} ref={this.props.mediaRef}
			className={`media${ isZoomable ? ' image-zoom' : ''}`}	
		>
			<div className="video-player-crop">
				{ showPosterOverPlayer ?
						<Image
							ref={this.posterRef}
							src={usablePosterSrc || this.blankURI}
							isZoomable={isZoomable}
							onLoad={this.onPosterLoad}
							isLazyLoadable={this.props.isLazyLoadable}
							renderWidth={this.props.renderWidth}
							renderHeight={this.props.renderHeight}
							width={width}
							height={height}
							style={{'transform': 'rotate(1deg)'}}
							part={'poster' + (statusPartAppendix ? ' poster'+statusPartAppendix : '')}
						/>

				 : null}
				{fileType === 'video' ? 
				<video
					ref={this.videoRef}			
					playsinline="true"
					autoplay={this.props.autoplay}
					loop={this.props.loop}
					muted={this.props.muted || this.props.autoplay}
					src={usableSrc}
					controls={browserDefault}
					onLoadedMetadata={this.onVideoLoad}
					preload={'metadata'}
					part={'video' + (statusPartAppendix ? ' video'+statusPartAppendix : '')}
					poster={browserDefault ? usablePosterSrc+'?cachebreak='+this.state.forceUpdate : null}
					onPlay={this.onPlay}
					onPause={this.onPause}
					onEnded={this.onEnded}
					width={width}
					height={height}
				></video> : <IframeVideo
					{...this.props}
					statusPartAppendix={statusPartAppendix}
				
					fileType={fileType}
					ref={this.videoRef}
					loop={this.props.loop}
					width={width}
					height={height}
					onPlay={this.onPlay}
					onPause={this.onPause}
					onEnded={this.onEnded}
					onNewPlayer={this.onNewPlayer}
					src={usableSrc}
				></IframeVideo>}		
			</div>
		</div>
	}

	componentDidMount() {

		this.props.baseNode.play = this.state.activePlayer.play;
		this.props.baseNode.pause = this.state.activePlayer.pause;

		this.props.baseNode.getCurrentTime = this.state.activePlayer.getCurrentTime;
		this.props.baseNode.setCurrentTime = this.state.activePlayer.setCurrentTime;	

		Object.defineProperty(this.props.baseNode, 'playing', {
			get: ()=> { 
				return this.state.playing
			},
			configurable: true
		});	
		Object.defineProperty(this.props.baseNode, 'paused', {
			get: ()=> { 
				return this.state.paused
			},
			configurable: true
		});
		Object.defineProperty(this.props.baseNode, 'stopped', {
			get: ()=> { 
				return this.state.stopped
			},
			configurable: true
		});


		// do a regular check on autoplay movies to ensure that they're playing while visible
		this.autoplayInterval = setInterval(()=>{
			if(
				(this.props.autoplay && !this.state.playing && this.props.visible && !this.state.interacted) &&
				(this.props.loop || !this.state.hasPlayedThrough)
			){
				this.state.activePlayer.setVolume(0).then(()=>{
					this.state.activePlayer.getVolume().then((vol)=>{
						this.play();
					});
				}).catch((e)=>{
					clearInterval(this.autoplayInterval)
				});
			}			
		}, 1200)

		this.configurePlayer();

		if(this.props.visible){
			this.enteredView();
		}

	}

	// some config can't be done in attribute and has to happen to loaded/configured player
	configurePlayer = ()=>{

		if( this.props.muted || this.props.autoplay ){
			this.state.activePlayer.setVolume(0).then(()=>{
				if(this.props.visible){
					this.enteredView();
				}				
			}).catch(e=>{

			});
		} else {
			this.state.activePlayer.setVolume(1).catch(e=>{
				
			});
		}

		if(this.props['playback-rate'] != 1 ){
			this.state.activePlayer.setPlaybackRate(this.props['playback-rate']).catch(e=>{
				
			});
		}
	}

	componentDidUpdate(prevProps, prevState){

		// set 'initial' values for a newly instated player
		if(
			prevState.activePlayer != this.state.activePlayer
		){
			this.configurePlayer();
			this.setState({
				interacted: false,
			})
		}

		// if coming into viewport
		if(
			this.props.visible != prevProps.visible ||
			prevState.activePlayer != this.state.activePlayer
		){
			
			if( this.props.visible){
				this.enteredView();
			} else {
				this.exitedView();
			}
		}

		// reset the video player to get the poster to display again when it's changed
		if(this.props.poster !== prevProps.poster){
			this.resetVideoPlayer();
		}

		// unload video when we're way out to free up memory - esp for mobile
		if( this.props.isLazyLoadable !== prevProps.isLazyLoadable){
			if( !this.props.isLazyLoadable){
				this.videoRef.current?.pause();
				this.videoRef.current?.removeAttribute('src');
				this.videoRef.current?.load();				
			}			
		}

		if( this.props.muted !== prevProps.muted){
			this.state.activePlayer.setVolume((this.props.muted || this.props.autoplay) ? 0 : 1);
		}

		if(
			this.props.fileType === 'video' &&
			(
				prevState.videoLoaded !== this.state.videoLoaded ||
				prevState.posterLoaded !== this.state.posterLoaded
			)
		){
			if (this.props.poster && this.state.posterLoaded && this.state.videoLoaded){
				this.initializeVideoElementPlayer();
			} else if ( this.state.videoLoaded){
				this.initializeVideoElementPlayer();
			}
		}



		if( prevProps.autoplay !== this.props.autoplay || prevProps['browser-default'] !== this.props['browser-default']){
			this.setState({
				interacted: false,
			})
			this.state.activePlayer.setCurrentTime(0);
			if( this.props.autoplay){
				this.play();
			} else {
				this.stop();
			}
		}

	}

	componentWillUnmount(){

		clearInterval(this.autoplayInterval);
		this.pause();
		this.exitedView.cancel();

		delete this.props.baseNode.playing;
		delete this.props.baseNode.paused;
		delete this.props.baseNode.stopped;
		delete this.props.baseNode.play;
		delete this.props.baseNode.pause;
		delete this.props.baseNode.getCurrentTime;
		delete this.props.baseNode.setCurrentTime;

	}



	play = ()=>{
		if( !this.state.activePlayer){
			return;
		}
		this.state.activePlayer.play().then(()=>{
			this.setState({
				playing: true,
				paused: false,
				stopped: false,
			})
			// first try
		}).catch(error=>{
			this.state.activePlayer.getVolume().then((vol)=>{
				if( vol !==0 ){
					this.state.activePlayer.setVolume(0).then(()=>{
						this.state.activePlayer.play().then(()=>{

						this.setState({
							playing: true,
							paused: false,
							stopped: false,
						})							
							// second try
						}).catch((e)=>{

						});							
					}).catch(e=>{

					});
	
				}
			}).catch(e=>{
				
			});
			
		})
	}

	pause = ()=>{
		if( !this.state.activePlayer){
			return;
		}
		this.state.activePlayer.pause().then(()=>{
			this.setState({
				playing: false,
				paused: true,
			})
		}).catch(error=>{

		})
	}

	stop = ()=>{
		if( !this.state.activePlayer){
			return;
		}
		this.pause();
		this.state.activePlayer.pause().then(()=>{
			this.setState({
				playing: false,
				paused: true,
				stopped: true,
			})
			this.state.activePlayer.setCurrentTime(0);

		}).catch(error=>{

		})		
	}

	// clicks on actual video elements don't register, so we can just merely guess
	onPointerEnter = (e) => {
		if( this.props['play-on'] ==='hover'){
			// if there's a poster in place, reset between plays
			if( this.props.poster ){
				this.state.activePlayer.setCurrentTime(0)
			}
			this.play();
		}
		this.setState({hasAttention: true})
	}

	onPointerOut = (e) => {
		if( this.props['play-on'] ==='hover'){
			this.pause();
		}		
		this.setState({hasAttention: false})
	}

	onPosterLoad = ()=>{
		this.setState({
			posterLoaded: true,
		})
	}

	onVideoLoad = ()=>{
		this.setState({
			videoLoaded: true,
		})

	}

	// Vimeo and Youtube players add themselves into the parent video player here
	onNewPlayer= (newPlayerObj)=>{

		if( !newPlayerObj){
			this.setState({
				playing: false,
				stopped: true,
				paused: true,
				hasPlayedThrough: false,
				activePlayer: this.nullPlayer
			},()=>{
				this.props.baseNode.play = this.state.activePlayer.play;
				this.props.baseNode.pause = this.state.activePlayer.pause;
				this.props.baseNode.getCurrentTime = this.state.activePlayer.getCurrentTime;
				this.props.baseNode.setCurrentTime = this.state.activePlayer.setCurrentTime;					
			});
			return;
		}


		this.setState({
			playing: false,
			stopped: true,
			paused: true,
			hasPlayedThrough: false,			
			activePlayer: newPlayerObj
		}, ()=>{


			this.props.baseNode.play = this.state.activePlayer.play;
			this.props.baseNode.pause = this.state.activePlayer.pause;
			this.props.baseNode.getCurrentTime = this.state.activePlayer.getCurrentTime;
			this.props.baseNode.setCurrentTime = this.state.activePlayer.setCurrentTime;

			if(this.props.onLoad){
				this.props.onLoad({
					width: newPlayerObj.size.width,
					height: newPlayerObj.size.height
				})
			}

			if(this.props.visible){
				this.enteredView();
			}	
		})

	}

	initializeVideoElementPlayer = ()=>{


		if( this.videoRef.current.paused && this.videoRef.current.currentTime!== 0 ){
			this.videoRef.current.currentTime = 0;
		}

		this.onNewPlayer({
			play: ()=>{ 
				return new Promise((resolve, reject)=>{
					this.videoRef.current.play().then(function(){
							resolve(true)
							
						}).catch(function(e){

							reject(e);
						})
					});
			},
			pause: ()=>{
				return new Promise((resolve, reject)=>{
					this.videoRef.current.pause();
					resolve(true);
				})
			},
			stop: ()=> {
				return new Promise((resolve, reject)=>{
					this.videoRef.current.pause();
					this.videoRef.current.currentTime = 0;
					resolve(true);
				})
				
			},
			getDuration: ()=>{
				return new Promise((resolve, reject)=>{
					resolve(this.videoRef.current.duration);
				})
			},
			getCurrentTime : ()=>{
				return new Promise((resolve, reject)=>{
					resolve(this.videoRef.current.currentTime);
				})
			},
			setCurrentTime: (val)=>{
				return new Promise((resolve, reject)=>{
					if( this.videoRef.current.currentTime === val){
						resolve(true);
					} else {
						this.videoRef.current.currentTime = val;
						this.videoRef.current.addEventListener('seeked', ()=>{
							resolve(true)
						}, {once: true})
					}

					
				})
			},
			getVolume: ()=>{
				return new Promise((resolve, reject)=>{
					resolve(this.videoRef.current.volume);
				})
			},
			setVolume: (val)=>{
				return new Promise((resolve, reject)=>{
					this.videoRef.current.volume = val;
					if( val == 0){
						this.videoRef.current.muted = true;
					}
					resolve(true);				
				})
			},			
			setPlaybackRate: (val)=>{
				return new Promise((resolve, reject)=>{
					this.videoRef.current.playbackRate = Math.max(0.1, Math.min(2, parseFloat(val)));
					if ('preservesPitch' in this.videoRef.current) {
						this.videoRef.current.preservesPitch = false;
					} else if ('mozPreservesPitch' in this.videoRef.current) { 
						this.videoRef.current.mozPreservesPitch = false;
					} else if ('webkitPreservesPitch' in this.videoRef.current) {
						this.videoRef.current.webkitPreservesPitch = false;
					}
					resolve(true);				
				})
			},			
			size: {
				width: this.videoRef.current.videoWidth || 320,
				height: this.videoRef.current.videoHeight || 240			
			},		

		})

	}
	
	onPlay = () => {
		this.setState((prevState)=>{

			const newState = {...prevState}
			if ( prevState.hasAttention ){
				newState.interacted = true;
			}
			newState.playing = true;
			newState.paused = false;
			newState.stopped = false;

			return newState
		})

	}

	onPause = () => {


		this.setState((prevState)=>{

			const newState = {...prevState}
			if ( prevState.hasAttention ){
				newState.interacted = true;
			}
			newState.playing = false;
			newState.paused = true;

			return newState
		})			

	}

	onEnded = ()=>{
		this.setState({
			hasPlayedThrough: true,
		})
	}

	resetVideoPlayer = ()=>{

		Promise.all([
			this.state.activePlayer.setCurrentTime(0),
			this.state.activePlayer.pause(),
		]).then(()=>{
			this.setState({
				hasPlayedThrough: false,
				paused: true,
				stopped: true,
			}, ()=>{
				// this is the way to get the poster to display again inside the native html video element
				if(
					this.props.poster &&
					this.videoRef.current &&
					this.props['browser-default']
				){

					// safari very stubbornly seems to stop rendering posters after exit
					// only thing that fixes so far is replacing the active video w/ a clone
					if( helpers.isSafari() && this.videoRef.current){

						const newVideo = this.videoRef.current.cloneNode();
						this.videoRef.current.replaceWith(newVideo);
						this.videoRef.current = newVideo;

					}					

					this.videoRef.current.load();



				}				
			})
		})		
	}


	enteredView() {

		this.exitedView.cancel();

		if (
			( this.props.autoplay == true || (this.props['play-on'] === 'hover' && this.state.hasAttention) ) &&
			!this.state.playing 
		){
			this.play();
		}

	}

	exitedView =_.debounce(()=>{

		const isPlayingWithAudio = !this.props.muted && !this.props.autoplay && this.state.playing;

		if( isPlayingWithAudio){
			return;
		}


		this.pause();

		// reset player state back to beginning if it exits window and it's at the end
		Promise.all([
			this.state.activePlayer.pause(),
			this.state.activePlayer.getCurrentTime(),
			this.state.activePlayer.getDuration()
		]).then(([paused,currentTime, duration=0])=>{

			let stopped = duration - currentTime < .1 && !this.props.loop;

			if( stopped || Number.isNaN(duration) || (currentTime == 0 && paused) ){

				this.resetVideoPlayer();
			}

		});		
	}, 500)

}


export default 	forwardRef((props, ref) => {
	return <Video {...props} mediaRef={ref} />;
})

